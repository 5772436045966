var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNavbar
    ? _c(
        "nav",
        { staticClass: "v-navbar" },
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "no-shadow",
              attrs: {
                color: "accent",
                app: "",
                "mini-variant": _vm.mini,
                permanent: "",
                width: 260,
                "mini-variant-width": "100",
                "hide-overlay": ""
              },
              scopedSlots: _vm._u(
                [
                  _vm.existDR
                    ? {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "v-list",
                              { staticClass: "v-left-menu" },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    class: "white--text text-caption",
                                    attrs: { link: "" },
                                    on: { click: _vm.openDataRequests }
                                  },
                                  [
                                    _vm.mini == true
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-list-item-icon",
                                                        _vm._g({}, on),
                                                        [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                              attrs: {
                                                                value:
                                                                  _vm.drProgress,
                                                                color: "white"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  parseInt(
                                                                    _vm.drProgress
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "white--text text-caption"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Data Requests"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1392123322
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Data Requests")
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.mini == false
                                      ? [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "white--text text-caption"
                                                },
                                                [
                                                  _vm._v(
                                                    "Data Requests - " +
                                                      _vm._s(
                                                        parseInt(_vm.drProgress)
                                                      ) +
                                                      "% "
                                                  )
                                                ]
                                              ),
                                              _c("v-progress-linear", {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  value: _vm.drProgress,
                                                  color: "white"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              ),
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-0 v-logo-box rounded-0",
                  attrs: { elevation: "3", color: "secondary" },
                  on: {
                    click: function($event) {
                      _vm.mini = !_vm.mini
                    }
                  }
                },
                [
                  _c("v-img", {
                    attrs: { src: require("../assets/images/small-logo.png") }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-menu",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.mini = !_vm.mini
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "white--text" }, [
                        _vm._v("mdi-chevron-left")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                { staticClass: "v-left-menu" },
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.title,
                      class:
                        item.path === _vm.$route.path
                          ? "active"
                          : "white--text text-caption",
                      attrs: {
                        link: "",
                        to: item.path,
                        "active-class": "active"
                      }
                    },
                    [
                      _vm.mini == true
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", color: "black" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-list-item-icon",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "white--text" },
                                              [_vm._v(_vm._s(item.icon))]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "white--text text-caption"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                          )
                        : _vm._e(),
                      _vm.mini == false
                        ? [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", { staticClass: "white--text" }, [
                                  _vm._v(_vm._s(item.icon))
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "white--text text-caption" },
                                  [_vm._v(_vm._s(item.title))]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                1
              ),
              _c("v-divider")
            ],
            1
          ),
          _c(
            "v-app-bar",
            {
              staticClass: "white lighten-4 text-center v-header",
              attrs: { app: "", flat: "" }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "primary",
                    disabled: _vm.syncing,
                    loading: _vm.syncing,
                    depressed: "",
                    dense: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.pullData()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.syncData")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "v-default-btn",
                  attrs: {
                    depressed: "",
                    color: "mr-2 primary",
                    elevation: "0",
                    to: "/CampaginView"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.createCampaign")) + " ")]
              ),
              _c(
                "v-menu",
                {
                  attrs: { bottom: "", left: "", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "no-ripple",
                                    attrs: { icon: "", width: "30px" }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "secondary--text" },
                                  [_vm._v("mdi-chevron-down")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-avatar",
                              { attrs: { color: "secondary", size: "40" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "white--text caption" },
                                  [_vm._v(" " + _vm._s(_vm.name) + " ")]
                                )
                              ]
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: { bottom: "", left: "", "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "no-ripple mr-2",
                                                  attrs: {
                                                    icon: "",
                                                    width: "80"
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("country-flag", {
                                                attrs: {
                                                  country:
                                                    _vm.selectedLocale.flag
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "black--text text-caption"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.selectedLocale.text
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "v-profile-menu style-2" },
                                  _vm._l(_vm.locales, function(locale, i) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        attrs: { link: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.switchLocale(locale)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("country-flag", {
                                              attrs: { country: locale.flag }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {}, [
                                              _vm._v(
                                                " " + _vm._s(locale.text) + " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    470966864
                  )
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "v-profile-menu" },
                    _vm._l(_vm.menuitems, function(item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { link: "" },
                          on: {
                            click: function($event) {
                              return _vm.menuClick(item.path)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { medium: "" } }, [
                                _vm._v(_vm._s(item.icon))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {}, [
                                _vm._v(_vm._s(item.title))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("DataRequests", { attrs: { openDR: _vm.triggerDR } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }