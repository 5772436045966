<template>
  <v-app class="grey lighten-4">
    <Navbar />

    <v-main>
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  components: { Navbar },
  name: 'App'
}
</script>

<!--<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/registration">Registration</router-link> |
      <router-link to="/login">Login</router-link>
    </div>
    <router-view />
  </div>
</template>-->


<style lang="scss">
  @import "assets/styles/main";
</style>
