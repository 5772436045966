import 'vue-loading-overlay/dist/vue-loading.css';

import * as Sentry from "@sentry/vue";
import * as messages from './locales';

import App from './App.vue';
import AxiosReqIntercetor from './interceptors/request.interceptor';
import CountryFlag from 'vue-country-flag'
import DatetimePicker from 'vuetify-datetime-picker'
import { Integrations } from "@sentry/tracing";
import Loading from 'vue-loading-overlay';
import { SENTRY_DSN } from '@config';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n';
import VueNotify from 'vuejs-notify';
import { apolloClient } from "./apollo/apollo-client";
import axios from 'axios'
import router from './router/index';
import vuetify from  './plugins/vuetify';

export const bus = new Vue();

Vue.component('country-flag', CountryFlag);

Vue.use(VueI18n);

Vue.use(DatetimePicker)

Vue.config.productionTip = false;

// init sentry logger
// Sentry.init({
//   Vue,
//   dsn: SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   logErrors: true,
//   release: '<release_version_here>',
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en', // set locale
  messages, // set locale messages
})

// HTTP interface.
Vue.use(VueAxios, axios);
AxiosReqIntercetor();

Vue.use(VueApollo);

Vue.use(Loading, {
  color: '#6cd581',
  loader: 'spinner',
  width: 64,
  height: 64,
});

Vue.use(VueNotify, {
  position: 'top center',
  presets: {
    success: {
      title: 'Success!',
      timeout: 3000
    },
    error: {
      title: 'Error!',
      timeout: 6000
    },
    warning: {
      title: 'Warning!'
    },
    info: {
      title: 'Info!'
    }
  },
  transition: 300,
  styles: { // these are binded to vue notification
    // Number values get 'px' postfix (except opacity)
    minWidth: null, // default 250
    maxWidth: 500,
    width: 480 // default null
  },
});

Vue.filter('readableNumber', function (value: number) {
  if (value === undefined || value === null ) return ''

  return Number(value).toLocaleString();
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

new Vue({
  router,
  vuetify,
  apolloProvider,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
