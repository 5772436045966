export enum AdsManagerTabs {
  ACCOUNTS = 'accounts_tab',
  APPS = 'apps_tab',
  CAMPAIGNS = 'campaigns_tab',
  AD_GROUPS = 'ad_groups_tab',
  KEYWORDS = 'keywords_tab',
  CREATIVE_SETS = 'creative_sets_tab',
  SEARCH_TERMS = 'search_terms_tab',
  NEG_KEYWORDS = 'neg_keywords_tab'
}

export enum AdsManagerFilters {
  ACCOUNT = 'account_filter',
  APPS = 'apps_filter',
  CAMPAIGN = 'campaign_filter',
  ADGROUP = 'adgroup_filter',
  KEYWORD = 'keyword_filter',
}

export enum BusEvents {
  DATA_PULLED = 'DATA_PULLED'
}

/**
 * enum for AppDownloaderCriteria https://developer.apple.com/documentation/apple_search_ads/appdownloadercriteria
 */

 export enum CustomerTypes {
  ALL = 0,
  NEW = 1,
  OTHER_APPS = 2,
  EXISITING = 3,
}

export enum Genders {
  ALL = 0,
  MALE = 'M',
  FEMALE = 'F',
}

export enum CampaignCreationSteps {
  APP_SELECTION = 1,
  COUNTRIES_REGIONS,
  AD_GROUP,
  SEARCH_MATCH,
  KEYWORDS,
  AUDIENCE,
  CREATIVE_SETS
}

export enum MaxCPTBidOptions {
  ADGROUP_DEFAULT = 1,
  CUSTOM = 2
}

export enum AdGroupSelectorContext {
  KEYWORD = 'keyword',
  CREATIVESET = 'creativeset'
}
