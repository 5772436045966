//Russian
export const ru = {
    pages: {
      overview: 'Обзор',
      adsManager: 'Менеджер по рекламе',
      kai: 'Анализ ключевых слов',
      keyOpt: 'Оптимизация ключевых слов',
      teamMgmt: 'Команда',
      integrations: 'Интеграции',
      automationRule: 'Автоматизация',
    },
    menu: {
      settings: 'Настройки',
      signOut: 'Выход'
    },
    buttons: {
      syncData: 'Синхронизировать последние данные',
      createCampaign: 'Создать кампанию',
      filter: 'Фильтр',
      reset: 'Сброс настроек',
      apply: 'Применять'
    },
    labels: {
      charts: 'Диаграммы',
      selectDs: 'Выберите наборы данных',
      totalSummary: 'Итоговая сводка',
      spend: 'Проводить'
    },
    dropdowns: {
      noData: 'Данные недоступны',
      daily: 'ЕЖЕДНЕВНО',
      weekly: 'ЕЖЕНЕДЕЛЬНО',
      monthly: 'ЕЖЕМЕСЯЧНО'
    },
    tabs: {
      accounts: 'учетные записи',
      apps: 'Программы',
      campaigns: 'Кампании',
      adgroups: 'Группы объявлений',
      keywords: 'Ключевые слова',
      creativeSets: 'Творческие наборы',
      searchTerms: 'Поисковые запросы',
      negKeywords: 'Минус-слова'
    },
    summary: {
      taps: 'Краны',
      impressions: 'Впечатления',
      ttr: 'TTR',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      installs: 'Устанавливает',
    },
    accountHeaders: {
      account: 'Счет',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки'
    },
    appsHeaders: {
      appName: 'Заявление',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstall: 'Attr Установить',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      goalsRoas: 'Цели ROAS',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      account: 'Счет',
      categoryRank: 'Категория Ранг',
      appId: 'ID приложения',
    },
    campaignHeaders: {
      campaign: 'Кампания',
      campaignStatus: 'Статус кампании',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRoas: 'Цели ROAS',
      storefronts: 'Витрины',
      account: 'Счет',
      displayStatus: 'Состояние дисплея',
      appName: 'Имя приложения',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      delete: 'Удалить'
    },
    campaignDraftHeaders: {
      campaignName: 'Название кампании',
      countries: 'Страны',
      budgetAmount: 'Сумма бюджета',
      resume: 'Резюме',
    },
    adGroupHeaders: {
      adgroup: 'Группа объявлений',
      adgroupStatus: 'Статус группы объявлений',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRoas: 'Цели ROAS',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      displayStatus: 'Состояние дисплея',
      account: 'Счет',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений'
    },
    keyHeaders: {
      keyword: 'Ключевое слово',
      keywordStatus: 'Статус ключевого слова',
      bidAmount: 'Сумма ставки',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      organicRank: 'Органический рейтинг',
      appName: 'Имя приложения',
      difficultyScore: 'Оценка сложности',
      popularity: 'Популярность',
      keywordId: 'Идентификатор ключевого слова',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений',
      suggestedMinBid: 'Рекомендуемая минимальная ставка ASA',
      suggestedMaxBid: 'Рекомендуемая максимальная ставка ASA',
      bidStrength: 'Сила ставки'
    },
    creativeHeaders: {
      creativeSet: 'Творческий набор',
      creativeSetStatus: 'Статус',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      account: 'Счет',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений'
    },
    searchTermsHeader: {
      searchTerm: 'Текст поискового запроса',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      keyword: 'Ключевое слово',
      keywordId: 'Идентификатор ключевого слова',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений'
    },
    negativeHeaders: {
      keyword: 'Ключевое слово',
      matchType: 'Тип соответствия',
      status: 'Статус',
      adgroupId: 'ID группы объявлений',
      campaignId: 'Идентификатор кампании',
      deleted: 'Удалено',
      keywordId: 'Идентификатор ключевого слова',
      modificationTime: 'Время модификации'
    },
    filter: {
      account: 'Выберите учетную запись',
      app: 'Выберите приложение',
      campaign: 'Выберите кампанию',
      adgroup: 'Выберите группу объявлений',
    },
    subTabs: {
      live: 'Жить',
      paused: 'Приостановлено',
      deleted: 'Удалено',
      draft: 'Черновой вариант',
      campaigns: 'Кампании',
      adgroups: 'Группы объявлений',
      keywords: 'Ключевые слова',
      creativeSet: 'Творческий набор',
      negKey: 'Минус-слова'
    },
    popups: {
      add: 'Добавлять',
      integration: 'Интеграция',
      adgroup: 'Группа объявлений',
      keywords: 'Ключевые слова',
      creativeSet: 'Творческий набор',
      negKey: 'Минус-слова'
    },
  
    //Team Management Page
    teamMgmt: {
      manageUsers: 'Управление пользователями',
      invite: 'Приглашайте членов команды и управляйте ими',
      add: 'Добавить участников',
      first: 'Имя',
      last: 'Фамилия',
      phone: 'Телефон',
      email: 'Электронное письмо',
      job: 'Название работы',
      allMembers: 'Все участники',
      processing: 'Обработка...',
      ok: 'ОК',
      inviteStatus: 'Статус приглашения',
      isDisabled: 'Выключен',
      company: 'название компании'
    },
  
    //Add Integration Page
    integration: {
      assPart: 'Ассоциированные партнеры',
      apple: 'Apple Search Ads',
      slack: 'Slack',
      itunes: 'Itunes',
      tenjin: 'Tenjin',
      kochava: 'Kochava',
      adjust: 'Adjust',
      appsFlyer: 'AppsFlyer',
      singular: 'Singular'
    },
  
    //Keyword Auction Insights Page
    kai: {
      addedKeywords: 'Добавлены ключевые слова',
      total: 'Общее',
      cancel: 'Отмена',
      runAds: 'Запуск рекламы',
      ok: 'ОК',
      totalPaidKey: 'Всего оплаченных ключевых слов',
      popularity: 'Популярность',
      numOfApps: 'Количество приложений',
      chance: 'Шанс',
      appsRunAds: 'Приложения с рекламой',
      maxPop: 'Максимальная популярность для вас',
      keyword: 'Ключевое слово',
      clear: 'ясно',
      clrAllFilter: 'Очистить все фильтры',
      keywords: 'Ключевые слова',
      organicRank: 'Органический рейтинг',
      totalApps: 'Всего приложений',
      app: 'Приложение',
      catName: 'Название категории',
      searchByApp: 'Поиск по приложению',
      searchByKey: 'Поиск по ключевым словам',
      addAllKey: 'Добавить все ключевые слова'
    },
  
    //Overview Page
    overview: {
      period: 'период',
      topKeywords: '10 самых популярных ключевых слов по',
      key: 'Ключевое слово',
      spend: 'Проводить',
      impressions: 'Впечатления',
      taps: 'Краны',
      goToKey: 'Перейти ко всем ключевым словам',
      topAdgroup: '10 самых популярных групп объявлений по',
      adgroup: 'AdGroup',
      goToAdgroup: 'Перейти ко всем группам объявлений',
      gender: 'Пол',
      device: 'Устройство',
      searchApp: 'Приложение для поиска',
      lastDay: 'Последний день',
      last7Days: 'Последние 7 дней',
      last30Days: 'Последние 30 дней',
      ttr: 'Скорость сквозного монтажа (TTR)',
      installs: 'Устанавливает',
      avgCPA: 'Средняя цена за конверсию',
      avgCPT: 'Средняя цена за клик',
      conversionRate: 'Коэффициент конверсии (CR)',
      latOnInstalls: 'LAT при установке',
      latOffInstalls: 'LAT Off Установки',
      reDownloads: 'Повторные загрузки',
      newDownloads: 'Новые загрузки',
      all: 'Все',
      tooltip: 'Показывает разницу в данных в процентах по сравнению с предыдущим периодом. Предыдущий период - это предыдущий выбранный диапазон дат'
    },
    
    //Keyword Optimization Page
   keyOpt: {
   keyBid: 'Оптимизация ставок для ключевых слов',
   keyToolTip: 'Вы можете оптимизировать размер ставки для ключевых слов, которые не входят в диапазон предлагаемой минимальной суммы ставки ASA и предлагаемой максимальной суммы ставки ASA.',
   optAll: 'Оптимизировать все',
   opt: 'Оптимизировать',
   optBidAmt: 'Оптимизировать сумму ставки',
   key: 'Ключевое слово',
   optBidForAll: 'Оптимизировать размер ставки для всех ключевых слов',
   campaign: 'Кампания',
   adgroup: 'Группа объявлений',
   bidAmt: 'Сумма ставки',
   asaMinBid: 'Рекомендуемая минимальная ставка ASA',
   asaMaxBid: 'Рекомендуемая максимальная ставка ASA',
   action: 'Действие'
   },
   
   //ARRules Page
   aRules: {
   createAR: 'Создать правило автоматизации',
   view: 'Вид',
   edit: 'Редактировать',
   delete: 'Удалить',
   disable: 'Запрещать',
   enable: 'Давать возможность',
   name: 'Имя',
   createdAt: 'Создано на',
   status: 'Статус',
   completed: 'Завершено в',
   actionFreq: 'Частота действия',
   rules: 'Правила',
   logs: 'Журналы',
   createdFor: 'Создан для',
   chckFreq: 'Проверить частоту',
   actions: 'Действия'
   },
  
   //Create Campaign
   //CampaignView
   createCampaign: {
   createCamp: 'Создать кампанию',
   h2: 'Выберите приложение, чтобы начать создание кампании',
   para: 'В кампаниях есть бюджеты и группы объявлений, посвященные общей теме или стратегии продвижения вашего приложения.',
   select: 'Выберите, где будут показываться объявления',
   searchResults: 'результаты поиска',
   srData: 'В кампаниях есть бюджеты и группы объявлений, посвященные общей теме или стратегии продвижения вашего приложения.',
   searchTab: 'Вкладка поиска',
   stData: 'Продвигайте свое приложение в верхней части списка предлагаемых приложений, когда пользователи переходят на вкладку «Поиск».',
   cancel: 'Отмена',
   continue: 'Продолжать',
   
   //Search Results and Search Tab Campaign
   countAndReg: 'Страны и регионы',
   adgroupSettings: 'Настройки группы объявлений',
   searchMatch: 'Поиск совпадения',
   key: 'Ключевое слово',
   audience: 'Аудитория',
   crSets: 'Творческие наборы',
   chooseCampGroup: 'Выберите группу кампании',
   chooseApp: 'Выберите приложение',
   next: 'Следующий',
   countPara: 'Ваше приложение будет продвигаться в App Store в подходящих странах и регионах, которые вы выберете из списка.',
   chooseCount: 'Выберите одну или несколько стран или регионов',
   campName: 'Название кампании',
   budget: 'Бюджет',
   dailyCap: 'Дневной лимит (необязательно)',
   adgroupPara: 'Группы объявлений содержат цели ценообразования и настройки, которые определяют, кому показывать вашу рекламу.',
   adgroupName: 'Название группы объявлений',
   devices: 'Устройства',
   startTime: 'Время начала',
   adScheduling: 'Планирование размещения рекламы',
   endTime: 'Время окончания',
   dayParting: 'Время суток (необязательно)',
   ok: 'OK',
   defaultMax: 'Макс. Ставка CPT по умолчанию',
   cpaGoal: 'Целевая цена за конверсию (необязательно)',
   saveDraft: 'Сохранить как черновик',
   appleSearchMatch: 'Apple Search Match',
   searchMatchPara: 'Apple Search Match - это самый простой способ запустить вашу рекламу. Поисковая реклама автоматически подберет вашу рекламу тем пользователям, которые ищут приложения, подобные вашему.',
   searchMatchPara1: 'Автоматически сопоставлять мое объявление с релевантными поисковыми запросами',
   keywords: 'Ключевые слова',
   keyPara: 'Ключевые слова - это релевантные слова или термины, которые клиенты могут использовать при поиске приложения, подобного вашему. Ключевые слова позволяют поисковикам быстро находить ваше приложение, что способствует увеличению количества его установок.',
   upload: 'Загрузить',
   suppFormat: 'поддерживаемые форматы: Excel, CSV',
   keyManually: 'Или добавьте ключевые слова вручную',
   targKey: 'Ключевые слова таргетинга',
   clear: 'ясно',
   adgroupNegKey: 'Минус-слова группы объявлений',
   optional: 'По желанию',
   negKey: 'Минус-слова',
   audPara: 'Уточните аудиторию для своей группы объявлений с помощью дополнительных дополнительных функций.',
   custType: 'Тип клиента',
   demo: 'Демография',
   gender: 'Пол',
   location: 'Место расположения',
   crSetPara: 'Объявления создаются автоматически с использованием метаданных, снимков экрана и превью приложений, представленных на странице продукта в App Store. Они также появляются в обычных результатах поиска в App Store. Показанные здесь объявления являются примерами объявлений по умолчанию.',
   crOptional: 'Набор объявлений (необязательно)',
   noCrSet: 'Приложение не соответствует минимальному набору объявлений.',
   campCreated: 'Кампания создана',
   campCreatedPara: 'Теперь вы можете просматривать свои кампании на панели управления поисковой рекламой.',
   close: 'Закрывать',
   matchType: 'Тип соответствия',
   amount: 'Количество',
   currency: 'Валюта',
   allUsers: 'Все пользователи',
   newUsers: 'Новые пользователи',
   appUsers: 'Пользователи других моих приложений',
   existingUsers: 'Существующие пользователи',
   all: 'Все',
   male: 'Мужчина',
   female: 'женский'
   },

   //Change Password
  changePass: {
    backSett: 'Вернуться к настройкам',
    chngPass: 'Измени пароль',
    newPass: 'Новый пароль',
    confirmPass: 'Подтвердить Пароль',
    submit: 'Представлять на рассмотрение',
    h4: 'Операционная система для маркетологов Кочавы ™',
    para:
      'Операционная система маркетологов (m / OS) легко интегрирует многоканальные маркетинговые решения для рекламодателей и издателей на одной операционной платформе.',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Забыл пароль',
    typeEmail: 'Введите адрес электронной почты',
    emailAdd: 'Адрес электронной почты',
    submit: 'Представлять на рассмотрение',
    chckEmail: 'Пожалуйста, проверьте свою электронную почту',
    para: 'Чтобы установить новый пароль, проверьте свою электронную почту',
    close: 'Закрывать',
    alreadyAcc: 'Уже есть аккаунт?',
    signIn: 'Войти',
  },

  //Onboard
  onboard: {
    welcome: 'Добро пожаловать в Кочаву',
    personal: 'Личное',
    start: 'Начинать',
    integration: 'Интеграция',
    next: 'Следующий',
    trial: 'Начать пробную версию',
    scheduleDemo: 'Или запланируйте демонстрацию у нас и узнайте вместе',
    addInt: 'Добавьте интеграцию.',
    signInApple:
      'Войдите в систему с Apple, чтобы добавить интеграцию с Apple Search Ads. Пожалуйста, обрати внимание:',
    step1:
      '1. Для предоставления доступа к учетной записи Apple Search Ads требуются права администратора.',
    step2:
      '2. Чтобы управлять своими кампаниями из SearchAdsNinja, выберите опцию «Чтение и запись» перед предоставлением доступа.',
    skip: 'Пропускать',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Добавить группу объявлений',
    para:
      'Вы еще не выбрали свою кампанию. Вы можете выбрать его из поля ниже.',
    camGroup: 'Группа Кампании',
    campaign: 'Кампания',
    adgroupSett: 'Настройки группы объявлений',
    para1:
      'Группы объявлений содержат цели ценообразования и настройки, которые определяют, кому показывать вашу рекламу.',
    adgroupName: 'Название группы объявлений',
    devices: 'Устройства',
    adScheduling: 'Планирование размещения рекламы',
    startTime: 'Время начала',
    endTime: 'Время окончания',
    dayparting: 'Время суток (необязательно)',
    defaultMax: 'Макс. Ставка CPT по умолчанию',
    cptTool:
      'Это максимальная сумма, которую вы готовы заплатить за нажатие на свое объявление. Эта ставка будет применяться ко всем ключевым словам в этой группе объявлений, если вы не примените индивидуальную ставку для ключевого слова.',
    cpaGoal: 'Целевая цена за конверсию (необязательно)',
    cpaTool:
      'Средняя сумма, которую вы платите за каждую загрузку. Он рассчитывается путем деления общей суммы на количество загрузок.',
    searchMatch: 'Поиск совпадения',
    searchTool:
      'Поисковое соответствие - это самый простой способ запустить вашу рекламу. Поисковая реклама автоматически подберет вашу рекламу тем пользователям, которые ищут приложения, подобные вашему.',
    searchPara: 'Автоматически сопоставлять мое объявление с релевантными поисковыми запросами',
    key: 'Ключевые слова',
    keyPara:
      'Ключевые слова - это релевантные слова или термины, которые клиенты могут использовать при поиске приложения, подобного вашему. Ключевые слова позволяют поисковикам быстро находить ваше приложение, что способствует увеличению количества его установок.',
    inputCpt: 'Введите максимальную ставку CPT по умолчанию, чтобы активировать раздел ключевых слов',
    addKeyPara:
      'Добавьте ключевые слова, относящиеся к вашему приложению и жанру. Мы поможем, поделившись дополнительными рекомендациями.',
    upload: 'Загрузить',
    addKeyManually: 'Или добавьте ключевые слова вручную',
    negKey: '"Минус-слова" группы объявлений (необязательно)',
    supp: 'поддерживаемые форматы: Excel, CSV',
    demAge: 'Демография Возраст, Пол',
    demPara: "Настройте таргетинг объявления на возраст или пол пользователя.",
    ageRange: 'Возрастной диапазон',
    cancel: 'Отмена',
    apply: 'Применять',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Изменить группу объявлений',
    adgroup: 'Adgroup',
    advance: 'Дополнительные настройки',
    viewEdit: 'Просмотр и редактирование',
    general: 'Общий',
    settings: 'Настройки',
    schedule: 'Расписание',
    custPara: 'Выберите клиентов приложения, которых вы хотите привлечь',
    selectAge: 'Выберите возрастной диапазон',
    save: 'Сохранить',
  },

  //Create AR
  createAR: {
    createAutoRule: 'Создать правило автоматизации',
    editAutoRule: 'Изменить правило автоматизации',
    choose: 'Выбирать',
    chooseAutoRule: 'Выберите уровень автоматизации и элементы.',
    conditions: 'Условия',
    actions: 'Действия',
    settings: 'Настройки',
    condition: 'Условие',
    addCondition: 'Добавить условие',
    andCondition:
      'Выберите, должны ли совпадать все вышеуказанные условия или должно соответствовать только одно из них.',
    checkCond: 'Проверить условия для',
    action: 'Действие',
    selectAll: 'Выбрать все',
    enterEmail: 'Пожалуйста, введите правильные идентификаторы электронной почты',
    addTo: 'Добавить в',
    selectAdgroups: 'Выберите AdGroups',
    selectCampaigns: 'Выберите кампании',
    cptBid: 'Ставка CPT',
    customBid: 'Специальная ставка',
    matchType: 'Тип соответствия',
    addAction: 'Добавить действие',
    chckFreq: 'Проверить частоту',
    ruleName: 'Имя правила',
    cancel: 'Отмена',
    step2: 'Шаг 2',
    step3: 'Шаг 3',
    andOrTypesText1: 'Все должны совпадать',
    andOrTypesText2: 'Только один должен соответствовать',
    addToModelListNegText1: 'Все выбранные группы объявлений',
    addToModelListNegText2: 'Все группы объявлений выбранных кампаний)',
    addToModelListNegText3: 'Все выбранные кампании',
    matchTypesText1: 'Широкое соответствие',
    matchTypesText2: 'Точное совпадение',
    cptBidItemsText1: 'Установить на заказ',
    cptBidItemsText2: 'Ставка по умолчанию для группы объявлений',
    actionFreqsText1: 'Ежечасно',
    actionFreqsText2: 'Каждые 6 часов',
    actionFreqsText3: 'Каждые 12 часовКаждые 12 часов',
    actionFreqsText4: 'Каждый день',
    actionFreqsText5: 'Пользовательские часы',
    limitActionFreqsText1: 'Каждые 2 часа',
    limitActionFreqsText2: 'Каждые 3 часа',
    limitActionFreqsText3: 'Каждые 2 дня',
    limitActionFreqsText4: 'Каждые 3 дня',
    limitActionFreqsText5: 'Каждую неделю',
    mon: 'понедельник',
    tue: 'понедельник',
    wed: 'среда',
    thu: 'Четверг',
    fri: 'Пятница',
    sat: 'Суббота',
    sun: 'Воскресенье',
    ciTimeRangesText1: 'Последний час',
    ciTimeRangesText2: 'Последний день',
    ciTimeRangesText3: 'Последние 7 дней',
    ciTimeRangesText4: 'Последние 30 дней',
    ciTimeRangesText5: 'Прошлый месяц',
    ciTimeRangesText6: 'Пользовательский час',
    allConOperationText1: 'Больше чем',
    allConOperationText2: 'Меньше, чем',
    allConOperationText3: 'Диапазон',
    allConOperationText4: 'Не в зоне',
    amount: 'Сумма $',
    budget: '% Процент бюджета',
    incBy: 'Уменьшить на',
    decBy: 'Увеличить на',
    setToCPA: 'Установить на среднюю цену за конверсию',
    setToCPT: 'Установить на среднюю цену за клик',
    value: '$ Значение',
    change: '% Изменять',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Выберите Аккаунт',
    cancel: 'Отмена',
    apply: 'Применять',
    clearAll: 'Очистить все',
    accounts: 'учетные записи',
    accountSelected: 'Аккаунт (ы) выбран',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'Выберите приложение',
    cancel: 'Отмена',
    apply: 'Применять',
    apps: 'Программы',
    appSelected: 'Выбрано приложение (я)',
    clearAll: 'Очистить все',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: 'Чтобы продолжить, оставьте выбранной только одну группу объявлений.',
    close: 'Закрывать',
    chooseKeywords: 'Выберите ключевые слова',
    cancel: 'Отмена',
    apply: 'Применять',
    campaigns: 'Кампании',
    expandAll: 'Расширить все',
    collapseAll: 'Свернуть все',
    adGroups: 'Группы объявлений',
    keywords: 'Ключевые слова',
    keywordSelected: 'выбрано ключевое слово (а)',
    clearAll: 'Очистить все',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Добавить набор объявлений',
    setup: 'Настраивать',
    para:
      'Выберите имя, которое описывает тему вашего творческого набора. Выберите язык снимков экрана и превью, которые вы хотите добавить, на странице продукта в App Store.',
    createSetName: 'Название набора объявлений ',
    localLang: 'Язык локализации',
    assetSelection: 'Выбор актива',
    assetSelectionPararaph1:
      'Наборы объявлений состоят из снимков экрана и предварительных просмотров приложений, которые отображаются на странице продукта в App Store.',
    assetSelectionPararaph2: 'Для каждого размера дисплея iPhone',
    assetSelectionPararaph3:
      'выберите хотя бы один пейзаж или три портрета.',
    assetSelectionPararaph4: 'Для каждого размера дисплея iPad',
    assetSelectionPararaph5:
      'выберите хотя бы один альбомный или два портретных объекта.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Пожалуйста, выберите не менее',
    screenshotsPara2: 'один пейзаж',
    screenshotsPara3: 'или же',
    screenshotsPara4: 'два портрета',
    screenshotsPara5: 'скриншоты!',
    screenshotsPara6: 'Пожалуйста, выберите не менее',
    screenshotsPara7: 'один пейзаж',
    screenshotsPara8: 'или же',
    screenshotsPara9: 'три портрета',
    screenshotsPara10: 'скриншоты!',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Удалить кампанию',
    para: 'Вы уверены, что хотите удалить кампанию?',
    cancel: 'Отмена',
    delete: 'Удалить',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Мастер редактирования кампании',
    campName: 'Название кампании',
    app: 'Приложение',
    countriesOrRegionsPara: 'Выберите одну или несколько стран или те же регионыВыберите одну или несколько стран или те же регионы',
    budget: 'Бюджет',
    dailyCap: 'Дневной лимит',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Выберите кампанию',
    noSelectedCamp: 'Кампания не выбрана',
    campaigns: 'Кампании',
    expandAll: 'Расширить все',
    collapseAll: 'Свернуть все',
    selectedCampaigns: 'Избранные кампании',
    clearAll: 'очистить все',
    cancel: 'Отмена',
    apply: 'Применять',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Изменить кредитную карту',
    cardNumber: 'Номер карты',
    expiryDate: 'Дата истечения срока',
    cvv: 'CVV',
    saveCard: 'Сохранить карту',
    updateExpiryPara: '* Вы можете обновить только дату истечения срока действия',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Изменить набор объявлений',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Настраивать',
    editCreativePara1:
      'Выберите имя, которое описывает тему вашего творческого набора. Выберите язык снимков экрана и превью, которые вы хотите добавить, на странице продукта в App Store.',
    creativeSetName: 'Название набора объявлений',
    localLang: 'Язык локализации',
    assetSelection: 'Выбор актива',
    assetSelectionPararaph1:
      'Наборы объявлений состоят из снимков экрана и предварительных просмотров приложений, которые отображаются на странице продукта в App Store.',
    assetSelectionPararaph2: 'Для каждого размера дисплея iPhone',
    assetSelectionPararaph3:
      'выберите хотя бы один пейзаж или же три портрета активов.select at least один пейзаж или же три портрета assets.',
    assetSelectionPararaph4: 'Для каждого размера дисплея iPad',
    assetSelectionPararaph5:
      'выберите хотя бы один альбомный или два портретных объекта.',
    viewDevice: 'Просмотр размеров дисплея устройства',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Отображать',
    default: 'По умолчанию',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Удалить интеграцию',
    cardText: 'Вы уверены, что хотите удалить интеграцию?',
    cancel: 'Отмена',
    delete: 'Удалить',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Добавить ключевые слова',
    keywordSuggestionsFor: 'Предлагаемые ключевые слова для',
    on: 'на',
    addedKeywords: 'Добавлены ключевые слова',
    total: 'Общее',
    add: 'Добавлять',
    cancel: 'Отмена',
    apply: 'Применять',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Добавить ключевые слова',
    selectTarget: 'Выбрать цель',
    h4Camp: 'Кампании:',
    selected: 'Выбрано',
    change: 'Изменять',
    h4Adgroups: 'Группы объявлений:',
    maxCPTBid: 'Макс. Ставка CPT:',
    keywordSuggestionsFor: 'Предлагаемые ключевые слова для',
    on: 'на',
    recommendedKeywords: 'Рекомендуемые ключевые слова',
    addedKeywords: 'Добавлены ключевые слова',
    add: 'Добавлять',
    total: 'Общее',
    cancel: 'Отмена',
    apply: 'Применять',
    ok: 'ОК',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Добавить ключевые слова',
    selectTarget: 'Выбрать цель',
    h4Camp: 'Кампании:',
    selected: 'Выбрано',
    change: 'Изменять',
    h4Adgroups: 'Группы объявлений:',
    maxCPTBid: 'Макс. Ставка CPT:',
    keywordSuggestionsFor: 'Предлагаемые ключевые слова для',
    on: 'на',
    recommendedKeywords: 'Рекомендуемые ключевые слова',
    addedKeywords: 'Добавлены ключевые слова',
    add: 'Добавлять',
    total: 'Общее',
    cancel: 'Отмена',
    apply: 'Применять',
    ok: 'ОК',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Добавить минус-слова',
    add: 'Добавлять',
    total: 'Общее',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Сумма ставки',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Чтобы продолжить, оставьте выбранной только одну группу объявлений.Please keep only one Ad-Group selected to continue.',
    close: 'Закрывать',
    chooseAdGroup: 'Выберите группу объявлений',
    cancel: 'Отмена',
    apply: 'Применять',
    campaigns : 'Кампании',
    expandAll: 'Расширить все',
    collapseAll: 'Свернуть все',
    adGroups : 'Группы объявлений',
    adGroupSelected : 'AdGroup (ы) выбраны',
    clearAll : 'Очистить все'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Добавить минус-слова',
    selectTarget : '1. Выбрать цель',
    addNegKeyword : 'Добавить минус-слово',
    paragraph1 : 'Минус-слова можно добавлять в кампании или группы объявлений. Пожалуйста, выберите один из них в поле ниже.',
    paragraph2 : 'В какую кампанию вы хотите загрузить ключевые слова?',
    selectCampaign : 'Выберите кампанию',
    paragraph3 : 'В какую группу объявлений вы хотите загрузить ключевые слова?',
    selectAdgroup : 'Выберите группу объявлений',
    cancel: 'Отмена',
    easywebinar : 'EasyWebinar', 
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Кампании:',
    selected : 'Выбрано',
    change: 'Изменять',
    paragraph4 : ' Минус-слова группы объявлений применяются только к этой группе объявлений. Если вы хотите, чтобы минус-слова применялись ко всем группам объявлений, установите их на уровне кампании.',
    paragraph5 : ' Подумайте о том, чтобы дать кампаниям время для запуска, прежде чем анализировать эффективность рекламы, чтобы узнать, какие ключевые слова могут вам не подойти. Добавление слишком большого количества минус-слов может ограничить показ вашей рекламы.',
    addedKeywords : 'Добавлены ключевые слова', 
    add: 'Добавлять',
    total: 'Общее',
    save: 'Сохранить', 
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Добавить канал Slack',
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Отмена',
ok : 'ОК',
  },

  //HelloWorld
  helloWorld : {
welcome : 'Добро пожаловать в Vuetify',
para1 : 'Для помощи и сотрудничества с другими разработчиками Vuetify,',
para2 : 'пожалуйста, присоединяйтесь к нашему онлайн',
discord : 'Сообщество Discord',
h2 : "Что дальше?",
impLinks : 'Важные ссылки',
ecosystem : 'Экосистема',
  },

  //InfoAlert
  infoAlert : {
ok : 'ОК',
  },
  }
  
  