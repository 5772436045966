import { render, staticRenderFns } from "./DataRequests.vue?vue&type=template&id=dd10f284&scoped=true&"
import script from "./DataRequests.vue?vue&type=script&lang=ts&"
export * from "./DataRequests.vue?vue&type=script&lang=ts&"
import style0 from "./DataRequests.vue?vue&type=style&index=0&id=dd10f284&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd10f284",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VDialog,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VProgressCircular,VProgressLinear,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/sam-backend/sam-backend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd10f284')) {
      api.createRecord('dd10f284', component.options)
    } else {
      api.reload('dd10f284', component.options)
    }
    module.hot.accept("./DataRequests.vue?vue&type=template&id=dd10f284&scoped=true&", function () {
      api.rerender('dd10f284', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DataRequests.vue"
export default component.exports