//French
export const fr = {
    pages: {
      overview: 'Aperçu',
      adsManager: `Gestionnaire d'annonces`,
      kai: `Analyse des mots clés`,
      keyOpt: 'Optimisation des mots clés',
      teamMgmt: `Équipe`,
      integrations: `Intégrations`,
      automationRule: `Automatisation`,
    },
    menu: {
      settings: `Paramètres`,
      signOut: 'Se déconnecter'
    },
    buttons: {
      syncData: 'Synchroniser les dernières données',
      createCampaign: 'Créer une campagne',
      filter: 'Filtre',
      reset: 'Réinitialiser',
      apply: 'Appliquer'
    },
    labels: {
      charts: 'Graphiques',
      selectDs: 'Sélectionner des ensembles de données',
      totalSummary: 'Résumé total',
      spend: 'Dépenser'
    },
    dropdowns: {
      noData: 'Pas de données disponibles',
      daily: 'DU QUOTIDIEN',
      weekly: 'HEBDOMADAIRE',
      monthly: 'MENSUEL'
    },
    tabs: {
      accounts: 'Comptes',
      apps: 'applications',
      campaigns: 'Campagnes',
      adgroups: `Groupes d'annonces`,
      keywords: 'Mots clés',
      creativeSets: 'Ensembles créatifs',
      searchTerms: 'Termes de recherche',
      negKeywords: 'Mots clés à exclure'
    },
    summary: {
      taps: 'Robinets',
      impressions: 'Impressions',
      ttr: 'TTR',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      installs: 'Installe',
    },
    accountHeaders: {
      account: 'Compte',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements'
    },
    appsHeaders: {
      appName: 'Application',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstall: 'Attr Installer',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      goalsRoas: 'Objectifs ROAS',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      account: 'Compte',
      categoryRank: 'Classement de la catégorie',
      appId: `Identifiant de l'application`,
    },
    campaignHeaders: {
      campaign: 'Campagne',
      campaignStatus: 'État de la campagne',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRoas: 'Objectifs ROAS',
      storefronts: 'Devantures',
      account: 'Compte',
      displayStatus: `Statut d'affichage`,
      appName: `Nom de l'application`,
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      delete: 'Effacer'
    },
    campaignDraftHeaders: {
      campaignName: 'Nom de la campagne',
      countries: 'Des pays',
      budgetAmount: 'Montant budgétaire',
      resume: 'Reprendre',
    },
    adGroupHeaders: {
      adgroup: `Groupe d'annonces`,
      adgroupStatus: `Statut du groupe d'annonces`,
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRoas: 'Objectifs ROAS',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      displayStatus: `Statut d'affichage`,
      account: 'Compte',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`
    },
    keyHeaders: {
      keyword: 'Mot-clé',
      keywordStatus: 'État du mot clé',
      bidAmount: `Montant de l'enchère`,
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      organicRank: 'Rang organique',
      appName: `Nom de l'application`,
      difficultyScore: 'Score de difficulté',
      popularity: 'Popularité',
      keywordId: 'Identifiant du mot clé',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`,
      suggestedMinBid: `Enchère minimum suggérée par l'ASA`,
      suggestedMaxBid: `Enchère maximale suggérée par l'ASA`,
      bidStrength: `Force de l'enchère`
    },
    creativeHeaders: {
      creativeSet: 'Ensemble créatif',
      creativeSetStatus: 'Statut',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      account: 'Compte',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`
    },
    searchTermsHeader: {
      searchTerm: 'Texte du terme de recherche',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      keyword: 'Mot-clé',
      keywordId: 'Identifiant du mot clé',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`
    },
    negativeHeaders: {
      keyword: 'Mot-clé',
      matchType: 'Type de match',
      status: 'Statut',
      adgroupId: `Identifiant du groupe d'annonces`,
      campaignId: 'Identifiant de la campagne',
      deleted: 'Supprimé',
      keywordId: 'Identifiant du mot clé',
      modificationTime: 'Heure de modification'
    },
    filter: {
      account: 'Sélectionnez un compte',
      app: `Sélectionnez l'application`,
      campaign: 'Sélectionnez la campagne',
      adgroup: `Sélectionnez le groupe d'annonces`,
    },
    subTabs: {
      live: 'Vivre',
      paused: 'En pause',
      deleted: 'Supprimé',
      draft: 'Brouillon',
      campaigns: 'Campagnes',
      adgroups: `Groupes d'annonces`,
      keywords: 'Mots clés',
      creativeSet: 'Ensemble créatif',
      negKey: 'Mots clés à exclure'
    },
    popups: {
      add: 'Ajouter',
      integration: `L'intégration`,
      adgroup: `Groupe d'annonces`,
      keywords: 'Mots clés',
      creativeSet: 'Ensemble créatif',
      negKey: 'Mots clés à exclure'
    },
  
    //Team Management Page
    teamMgmt: {
      manageUsers: 'gérer les utilisateurs',
      invite: `Inviter et gérer les membres de l'équipe`,
      add: 'Ajouter un membre',
      first: 'Prénom',
      last: 'Nom de famille',
      phone: 'Téléphoner',
      email: 'E-mail',
      job: `Titre d'emploi`,
      allMembers: 'Tous les membres',
      processing: 'Traitement...',
      ok: `D'accord`,
      inviteStatus: `Statut d'invitation`,
      isDisabled: 'est désactivé',
      company: 'Nom de la compagnie'
    },
  
    //Add Integration Page
    integration: {
      assPart: 'Partenaires associés',
      apple: 'Apple Search Ads',
      slack: 'Slack',
      itunes: 'Itunes',
      tenjin: 'Tenjin',
      kochava: 'Kochava',
      adjust: 'Adjust',
      appsFlyer: 'AppsFlyer',
      singular: 'Singular'
    },
  
    //Keyword Auction Insights Page
    kai: {
      addedKeywords: 'Mots-clés ajoutés',
      total: 'Le total',
      cancel: 'Annuler',
      runAds: 'Diffuser des annonces',
      ok: `D'accord`,
      totalPaidKey: 'Total des mots-clés payés',
      popularity: 'Popularité',
      numOfApps: `Nombres d'applications`,
      chance: 'Chance',
      appsRunAds: 'Applications diffusant des annonces',
      maxPop: 'Popularité maximale pour la vôtre',
      keyword: 'Mot-clé',
      clear: 'Dégager',
      clrAllFilter: 'Tout effacer Filtrer',
      keywords: 'Mots clés',
      organicRank: 'Rang organique',
      totalApps: `Nombre total d'applications`,
      app: 'Application',
      catName: 'Nom de catégorie',
      searchByApp: 'Recherche par application',
      searchByKey: 'recherche par mots-clés',
      addAllKey: 'Ajouter tous les mots clés'
    },
  
    //Overview Page
    overview: {
      period: 'période',
      topKeywords: 'Top 10 des mots clés par',
      key: 'Mot-clé',
      spend: 'Dépenser',
      impressions: 'Impressions',
      taps: 'Robinets',
      goToKey: 'Aller à tous les mots clés',
      topAdgroup: `Top 10 des groupes d'annonces par`,
      adgroup: `Groupe d'annonces`,
      goToAdgroup: `Accédez à tous les groupes d'annonces`,
      gender: 'Genre',
      device: 'Appareil',
      searchApp: 'Application de recherche',
      lastDay: 'Dernier jour',
      last7Days: 'Les 7 derniers jours',
      last30Days: 'Les 30 derniers jours',
      ttr: 'Taux de passage (TTR)',
      installs: 'Installe',
      avgCPA: 'CPA moyen',
      avgCPT: 'CPT moyen',
      conversionRate: 'Taux de conversion (CR)',
      latOnInstalls: 'LAT sur les installations',
      latOffInstalls: 'Installations hors LAT',
      reDownloads: 'Re-téléchargements',
      newDownloads: 'Nouveaux téléchargements',
      all: 'Tout',
      tooltip: 'Affiche la différence de données en pourcentage selon la période précédente. La période précédente est la plage de dates sélectionnée précédente'
    },
  
    //Keyword Optimization Page
   keyOpt: {
   keyBid: 'Optimisation des enchères par mot clé',
   keyToolTip: `Vous pouvez optimiser le montant de l\'enchère des mots-clés qui ne se situent pas dans la plage du montant de l'enchère minimale suggérée par l'ASA et du montant de l'enchère maximale suggérée par l'ASA.`,
   optAll: 'Optimiser tout',
   opt: 'Optimiser',
   optBidAmt: `Optimiser le montant de l'enchère`,
   key: 'Mot-clé',
   optBidForAll: `Optimiser le montant de l'enchère de tous les mots clés`,
   campaign: 'Campagne',
   adgroup: `Groupe d'annonces`,
   bidAmt: `Montant de l'enchère`,
   asaMinBid: `Enchère minimum suggérée par l'ASA`,
   asaMaxBid: `Enchère maximale suggérée par l'ASA`,
   action: 'action'
   },
   
   //ARRules Page
   aRules: {
   createAR: `Créer une règle d'automatisation`,
   view: 'Vue',
   edit: 'Éditer',
   delete: 'Effacer',
   disable: 'Désactiver',
   enable: 'Activer',
   name: 'Nom',
   createdAt: 'Créé à',
   status: 'Statut',
   completed: 'Terminé à',
   actionFreq: `Fréquence d'action`,
   rules: 'Des règles',
   logs: 'Journaux',
   createdFor: 'Créé pour',
   chckFreq: 'Vérifier la fréquence',
   actions: 'Actions'
   },
  
   //Create Campaign
   //CampaignView
   createCampaign: {
   createCamp: 'Créer une campagne',
   h2: 'Choisissez une application pour commencer à créer une campagne',
   para: `Les campagnes ont des budgets et des groupes d'annonces qui se concentrent sur un thème ou une stratégie commun pour la promotion de votre application.`,
   select: 'Sélectionnez où les annonces seront diffusées',
   searchResults: 'Résultats de recherche',
   srData: `Les campagnes ont des budgets et des groupes d'annonces qui se concentrent sur un thème ou une stratégie commun pour la promotion de votre application.`,
   searchTab: 'Onglet Rechercher',
   stData: `Faites la promotion de votre application en haut de la liste des applications suggérées lorsque les utilisateurs visitent l'onglet Rechercher.`,
   cancel: 'Annuler',
   continue: 'Continuer',
   
   //Search Results and Search Tab Campaign
   countAndReg: 'Pays et régions',
   adgroupSettings: `Paramètres du groupe d'annonces`,
   searchMatch: 'Recherche de correspondance',
   key: 'Mot-clé',
   audience: 'Public',
   crSets: 'Ensembles créatifs',
   chooseCampGroup: 'Choisissez un groupe de campagnes',
   chooseApp: 'Choisissez une application',
   next: 'Suivant',
   countPara: `Votre application sera promue sur l'App Store dans les pays et régions éligibles que vous choisissez dans la liste.`,
   chooseCount: 'Choisissez un ou plusieurs pays ou régions',
   campName: 'Nom de la campagne',
   budget: 'Budget',
   dailyCap: 'Plafond quotidien (facultatif)',
   adgroupPara: `Les groupes d'annonces contiennent des objectifs et des paramètres de tarification qui déterminent à qui diffuser votre annonce.`,
   adgroupName: `Nom du groupe d'annonces`,
   devices: 'Dispositifs',
   startTime: 'Heure de début',
   adScheduling: 'Planification des annonces',
   endTime: 'Heure de fin',
   dayParting: 'Heures de départ (facultatif)',
   ok: `d'accord`,
   defaultMax: 'Enchère CPT max. par défaut',
   cpaGoal: 'Objectif CPA (facultatif)',
   saveDraft: 'Enregistrer comme brouillon',
   appleSearchMatch: 'Correspondance de recherche Apple',
   searchMatchPara: 'Apple Search Match est le moyen le plus simple de diffuser vos annonces. Search Ads fera automatiquement correspondre votre annonce aux utilisateurs qui recherchent des applications comme la vôtre.',
   searchMatchPara1: 'Faire correspondre automatiquement mon annonce aux recherches pertinentes',
   keywords: 'Mots clés',
   keyPara: `Les mots clés sont des mots ou des termes pertinents que les clients peuvent utiliser lorsqu'ils recherchent une application comme la vôtre. Les mots clés permettent aux chercheurs de trouver rapidement votre application, ce qui contribue à générer des installations d'applications.`,
   upload: 'Télécharger',
   suppFormat: 'formats pris en charge : Excel, CSV',
   keyManually: 'Ou ajoutez des mots-clés manuellement',
   targKey: 'Ciblage par mots-clés',
   clear: 'Dégager',
   adgroupNegKey: `Groupe d'annonces Mots clés à exclure`,
   optional: 'Optionnel',
   negKey: 'Mots clés à exclure',
   audPara: ` Affinez l'audience de votre groupe d'annonces à l'aide de fonctionnalités avancées facultatives.`,
   custType: 'Type de client',
   demo: 'Démographie',
   gender: 'Genre',
   location: 'Emplacement',
   crSetPara: `Les annonces sont automatiquement créées à l'aide des métadonnées, des captures d'écran et des aperçus d'application fournis sur la page produit de l'App Store. Ceux-ci apparaissent également dans les résultats de recherche organiques sur l'App Store. Les annonces affichées ici sont des exemples d'annonces par défaut.`,
   crOptional: 'Ensemble de créations (facultatif)',
   noCrSet: `L'application ne remplit pas l'exigence d'un ensemble créatif minimum`,
   campCreated: 'Campagne créée',
   campCreatedPara: 'Désormais, vous pouvez voir vos campagnes sur le tableau de bord des annonces du Réseau de Recherche',
   close: 'Fermer',
   matchType: 'Type de match',
   amount: 'Montant',
   currency: 'Devise',
   allUsers: 'Tous les utilisateurs',
   newUsers: 'Nouveaux utilisateurs',
   appUsers: 'Utilisateurs de mes autres applications',
   existingUsers: 'Utilisateurs existants',
   all: 'Tout',
   male: 'Mâle',
   female: 'Femelle'
   },

   //Change Password
  changePass: {
    backSett: 'Retour aux paramètres',
    chngPass: 'Changer le mot de passe',
    newPass: 'nouveau mot de passe',
    confirmPass: 'Confirmez le mot de passe',
    submit: 'Soumettre',
    h4: `Le système d'exploitation Kochava Marketers™`,
    para:
      `Le Marketers Operating System (m/OS) intègre de manière transparente des solutions de marketing omnicanal pour les annonceurs et les éditeurs dans une plate-forme opérationnelle.`,
    ok: `d'accord`,
  },

  //Forgot Password
  forgotPass: {
    forPass: `Mot de passe oublié`,
    typeEmail: `Tapez l'adresse e-mail`,
    emailAdd: 'Adresse e-mail',
    submit: 'Soumettre',
    chckEmail: 'Merci de consulter vos emails',
    para: 'Pour définir un nouveau mot de passe, veuillez vérifier votre e-mail',
    close: 'Fermer',
    alreadyAcc: 'Vous avez déjà un compte?',
    signIn: `S'identifier`,
  },

  //Onboard
  onboard: {
    welcome: 'Bienvenue à Kochava',
    personal: 'Personnel',
    start: 'Début',
    integration: `L'intégration`,
    next: 'Prochain',
    trial: 'Commencez votre essai',
    scheduleDemo: 'Ou programmez une démo avec nous et découvrez ensemble',
    addInt: 'Ajouter une intégration.',
    signInApple:
      'Connectez-vous avec Apple pour ajouter une intégration Apple Search Ads. Veuillez noter:',
    step1:
      `1. Il est nécessaire d'avoir des droits d'administrateur pour accorder l'accès au compte Apple Search Ads.`,
    step2:
      `2. Afin de gérer vos campagnes à partir de SearchAdsNinja, veuillez sélectionner l'option Lire et écrire avant d'accorder l'accès.`,
    skip: 'Sauter',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: `Ajouter un groupe d'annonces`,
    para:
      `Vous n'avez pas encore sélectionné votre campagne. Vous pouvez le choisir dans la boîte ci-dessous.`,
    camGroup: 'Groupe de campagne',
    campaign: 'Campagne',
    adgroupSett: `Paramètres du groupe d'annonces`,
    para1:
      `Les groupes d'annonces contiennent des objectifs et des paramètres de tarification qui déterminent à qui diffuser votre annonce.`,
    adgroupName: `Nom du groupe d'annonces`,
    devices: 'Dispositifs',
    adScheduling: 'Planification des annonces',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    dayparting: 'Heures de départ (facultatif)',
    defaultMax: 'Enchère CPT max. par défaut',
    cptTool:
      `Il s'agit du montant maximum que vous êtes prêt à payer pour un clic sur votre annonce. Cette enchère s'appliquera à tous les mots clés de ce groupe d'annonces, sauf si vous appliquez une enchère de mot clé individuelle.`,
    cpaGoal: 'Objectif CPA (facultatif)',
    cpaTool:
      'La moyenne que vous avez payée pour chaque téléchargement. Ceci est calculé en divisant le total dépensé par le nombre de téléchargements.',
    searchMatch: 'Recherche de correspondance',
    searchTool:
      'Search Match est le moyen le plus simple de diffuser vos annonces. Search Ads fera automatiquement correspondre votre annonce aux utilisateurs qui recherchent des applications comme la vôtre.',
    searchPara: 'Faire correspondre automatiquement mon annonce aux recherches pertinentes',
    key: 'Mots clés',
    keyPara:
      `Les mots clés sont des mots ou des termes pertinents que les clients peuvent utiliser lorsqu'ils recherchent une application comme la vôtre. Les mots clés permettent aux chercheurs de trouver rapidement votre application, ce qui contribue à générer des installations d'applications.`,
    inputCpt: `Saisissez l'enchère CPT max. par défaut pour activer la section des mots-clés`,
    addKeyPara:
      'Ajoutez des mots-clés pertinents pour votre application et votre genre. Nous vous aiderons en partageant des recommandations supplémentaires.',
    upload: 'Télécharger',
    addKeyManually: 'Ou ajoutez des mots-clés manuellement',
    negKey: `Mots clés à exclure du groupe d'annonces (facultatif)`,
    supp: 'formats pris en charge : Excel, CSV',
    demAge: 'Démographie Âge, Sexe',
    demPara: "Ciblez votre annonce en fonction de l'âge ou du sexe de votre utilisateur",
    ageRange: `Tranche d'âge`,
    cancel: 'Annuler',
    apply: 'Appliquer',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: `Modifier le groupe d'annonces`,
    adgroup: `Groupe d'annonces`,
    advance: 'Paramètres avancés',
    viewEdit: 'Afficher et modifier',
    general: 'Général',
    settings: 'Paramètres',
    schedule: 'Programme',
    custPara: `Sélectionnez les clients de l'application que vous souhaitez atteindre`,
    selectAge: `Sélectionnez la tranche d'âge`,
    save: 'sauver',
  },

  //Create AR
  createAR: {
    createAutoRule: `Créer une règle d'automatisation`,
    editAutoRule: `Modifier la règle d'automatisation`,
    choose: 'Choisir',
    chooseAutoRule: `Veuillez choisir le niveau d'automatisation et les éléments.`,
    conditions: 'Conditions',
    actions: 'Actions',
    settings: 'Paramètres',
    condition: 'État',
    addCondition: 'Ajouter une condition',
    andCondition:
      `Choisissez si toutes les conditions ci-dessus doivent correspondre ou si une seule d'entre elles doit correspondre`,
    checkCond: 'Vérifiez les conditions pour',
    action: 'Action',
    selectAll: 'Tout sélectionner',
    enterEmail: 'Veuillez saisir les identifiants de messagerie appropriés',
    addTo: 'Ajouter à',
    selectAdgroups: `Sélectionnez les groupes d'annonces`,
    selectCampaigns: 'Sélectionnez des campagnes',
    cptBid: 'Enchère CPT',
    customBid: 'Enchère personnalisée',
    matchType: 'Type de match',
    addAction: 'Ajouter une action',
    chckFreq: 'Vérifier la fréquence',
    ruleName: 'Nom de la règle',
    cancel: 'Annuler',
    step2: 'Étape 2',
    step3: 'Étape 3',
    andOrTypesText1: 'Tout doit correspondre',
    andOrTypesText2: 'Un seul doit correspondre',
    addToModelListNegText1: `Tous les groupes d'annonces sélectionnés`,
    addToModelListNegText2: `Tous les groupes d'annonces des campagnes sélectionnées)`,
    addToModelListNegText3: 'Toutes les campagnes sélectionnées',
    matchTypesText1: 'Requête large',
    matchTypesText2: 'Correspondance exacte',
    cptBidItemsText1: 'Définir sur personnalisé',
    cptBidItemsText2: `Enchère par défaut du groupe d'annonces`,
    actionFreqsText1: 'Horaire',
    actionFreqsText2: 'Toutes les 6 heures',
    actionFreqsText3: 'Toutes les 12 heures',
    actionFreqsText4: 'Tous les jours',
    actionFreqsText5: 'Horaires personnalisés',
    limitActionFreqsText1: 'Toutes les 2 heures',
    limitActionFreqsText2: 'Toutes les 3 heures',
    limitActionFreqsText3: 'Tous les 2 jours',
    limitActionFreqsText4: 'Tous les 3 jours',
    limitActionFreqsText5: 'Toutes les semaines',
    mon: 'Lundi',
    tue: 'mardi',
    wed: 'mercredi',
    thu: 'jeudi',
    fri: 'Vendredi',
    sat: 'samedi',
    sun: 'dimanche',
    ciTimeRangesText1: 'Dernière 1 heure',
    ciTimeRangesText2: '1 dernier jour',
    ciTimeRangesText3: 'Les 7 derniers jours',
    ciTimeRangesText4: 'Les 30 derniers jours',
    ciTimeRangesText5: 'Le mois dernier',
    ciTimeRangesText6: 'Heure personnalisée',
    allConOperationText1: 'Plus grand que',
    allConOperationText2: 'Moins que',
    allConOperationText3: 'Varier',
    allConOperationText4: 'Pas à portée',
    amount: 'Montant en $',
    budget: '% Pourcentage du budget',
    incBy: 'Diminuer de',
    decBy: 'Augmenté de',
    setToCPA: 'Définir sur CPA moyen',
    setToCPT: 'Définir sur CPT moyen',
    value: 'Valeur en $',
    change: '% Changement',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Choisissez un compte',
    cancel: 'Annuler',
    apply: 'Appliquer',
    clearAll: 'Tout effacer',
    accounts: 'Comptes',
    accountSelected: 'Compte(s) sélectionné(s)',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: `Choisissez l'application`,
    cancel: 'Annuler',
    apply: 'Appliquer',
    apps: 'applications',
    appSelected: 'Application(s) sélectionnée(s)',
    clearAll: 'Tout effacer',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: `Veuillez ne conserver qu'un seul groupe d'annonces sélectionné pour continuer.`,
    close: 'Fermer',
    chooseKeywords: 'Choisissez des mots clés',
    cancel: 'Annuler',
    apply: 'Appliquer',
    campaigns: 'Campagnes',
    expandAll: 'Développer tout',
    collapseAll: 'Tout réduire',
    adGroups: `Groupes d'annonces`,
    keywords: 'Mots clés',
    keywordSelected: 'mot-clé(s) sélectionné(s)',
    clearAll: 'Tout effacer',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Ajouter un ensemble créatif',
    setup: 'Installer',
    para:
      `Choisissez un nom qui décrit le thème de votre Creative Set. Sélectionnez la langue des captures d'écran et des aperçus que vous souhaitez inclure à partir de votre page produit App Store.`,
    createSetName: `Nom de l'ensemble de créations `,
    localLang: 'Langue de localisation',
    assetSelection: `Sélection d'actifs`,
    assetSelectionPararaph1:
      `Les Creative Sets se composent de captures d'écran et d'aperçus d'applications qui apparaissent sur la page produit de l'App Store.`,
    assetSelectionPararaph2: `Pour chaque taille d'affichage iPhone,`,
    assetSelectionPararaph3:
      'sélectionnez au moins un paysage ou trois éléments de portrait.',
    assetSelectionPararaph4: `Pour chaque taille d'écran iPad,`,
    assetSelectionPararaph5:
      'sélectionnez au moins un paysage ou deux éléments de portrait.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Veuillez sélectionner au moins',
    screenshotsPara2: 'un paysage',
    screenshotsPara3: 'ou alors',
    screenshotsPara4: 'deux portraits',
    screenshotsPara5: `captures d'écran!`,
    screenshotsPara6: 'Veuillez sélectionner au moins',
    screenshotsPara7: 'un paysage',
    screenshotsPara8: 'ou alors',
    screenshotsPara9: 'trois portraits',
    screenshotsPara10: `captures d'écran!`,
    cancel: 'Annuler',
    save: 'sauver',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Supprimer la campagne',
    para: 'Voulez-vous vraiment supprimer la campagne ?',
    cancel: 'Annuler',
    delete: 'Supprimer',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Assistant de modification de campagne',
    campName: 'Nom de la campagne',
    app: 'Application',
    countriesOrRegionsPara: 'Choisissez un ou plusieurs pays ou régions',
    budget: 'Budget',
    dailyCap: 'Cap journalier',
    cancel: 'Annuler',
    save: 'sauver',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Choisissez la campagne',
    noSelectedCamp: 'Aucune campagne sélectionnée',
    campaigns: 'Campagnes',
    expandAll: 'Développer tout',
    collapseAll: 'Tout réduire',
    selectedCampaigns: 'Campagnes sélectionnées',
    clearAll: 'tout effacer',
    cancel: 'Annuler',
    apply: 'Appliquer',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Modifier la carte de crédit',
    cardNumber: 'Numéro de carte',
    expiryDate: `Date d'expiration`,
    cvv: 'CVV',
    saveCard: 'sauver Carte',
    updateExpiryPara: `* Vous pouvez uniquement mettre à jour la date d'expiration`,
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: `Modifier l'ensemble de créations`,
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Installer',
    editCreativePara1:
      `Choisissez un nom qui décrit le thème de votre Creative Set. Sélectionnez la langue des captures d'écran et des aperçus que vous souhaitez inclure à partir de votre page produit App Store.`,
    creativeSetName: `Nom de l'ensemble de créations`,
    localLang: 'Langue de localisation',
    assetSelection: `Sélection d'actifs`,
    assetSelectionPararaph1:
      `Les Creative Sets se composent de captures d'écran et d'aperçus d'applications qui apparaissent sur la page produit de l'App Store.`,
    assetSelectionPararaph2: `Pour chaque taille d'affichage iPhone,`,
    assetSelectionPararaph3:
      'sélectionnez au moins un paysage ou trois éléments de portrait.',
    assetSelectionPararaph4: `Pour chaque taille d'écran iPad,`,
    assetSelectionPararaph5:
      'sélectionnez au moins un paysage ou deux éléments de portrait.',
    viewDevice: `Afficher les tailles d'affichage de l'appareil`,
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Afficher',
    default: 'Défaut',
    cancel: 'Annuler',
    save: 'sauver',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: `Supprimer l'intégration`,
    cardText: `Voulez-vous vraiment supprimer l'intégration ?`,
    cancel: 'Annuler',
    delete: 'Supprimer',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Ajouter des mots clés',
    keywordSuggestionsFor: 'Suggestions de mots clés pour',
    on: 'sur',
    addedKeywords: 'Mots-clés ajoutés',
    total: 'Total',
    add: 'Ajouter',
    cancel: 'Annuler',
    apply: 'Appliquer',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Ajouter des mots clés',
    selectTarget: 'Sélectionnez la cible',
    h4Camp: 'Campagnes :',
    selected: 'Choisi',
    change: 'Changement',
    h4Adgroups: `Groupes d'annonces:`,
    maxCPTBid: 'Enchère CPT max :',
    keywordSuggestionsFor: 'Suggestions de mots clés pour',
    on: 'sur',
    recommendedKeywords: 'Mots-clés recommandés',
    addedKeywords: 'Mots-clés ajoutés',
    add: 'Ajouter',
    total: 'Total',
    cancel: 'Annuler',
    apply: 'Appliquer',
    ok: `D'accord`,
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Ajouter des mots clés',
    selectTarget: 'Sélectionnez la cible',
    h4Camp: 'Campagnes :',
    selected: 'Choisi',
    change: 'Changement',
    h4Adgroups: `Groupes d'annonces:`,
    maxCPTBid: 'Enchère CPT max :',
    keywordSuggestionsFor: 'Suggestions de mots clés pour',
    on: 'sur',
    recommendedKeywords: 'Mots-clés recommandés',
    addedKeywords: 'Mots-clés ajoutés',
    add: 'Ajouter',
    total: 'Total',
    cancel: 'Annuler',
    apply: 'Appliquer',
    ok: `D'accord`,
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Ajouter des mots clés à exclure',
    add: 'Ajouter',
    total: 'Total',
    cancel: 'Annuler',
    save: 'sauver',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: `Montant de l'enchère`,
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: `Veuillez ne conserver qu'un seul groupe d'annonces sélectionné pour continuer.`,
    close: 'Fermer',
    chooseAdGroup: `Choisir un groupe d'annonces`,
    cancel: 'Annuler',
    apply: 'Appliquer',
    campaigns : 'Campagnes',
    expandAll: 'Développer tout',
    collapseAll: 'Tout réduire',
    adGroups : `Groupes d'annonces`,
    adGroupSelected : `Groupe(s) d'annonces sélectionnés`,
    clearAll : 'Tout effacer'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Ajouter des mots clés à exclure',
    selectTarget : '1. Sélectionnez la cible',
    addNegKeyword : 'Ajouter un mot clé à exclure',
    paragraph1 : `Des mots-clés négatifs peuvent être ajoutés aux campagnes ou aux groupes d'annonces. Veuillez choisir l'un d'entre eux dans la case ci-dessous.`,
    paragraph2 : 'Quelle campagne souhaitez-vous importer des mots clés ?',
    selectCampaign : 'Sélectionnez la campagne',
    paragraph3 : `Quel groupe d'annonces souhaitez-vous importer des mots clés ?`,
    selectAdgroup : `Sélectionnez le groupe d'annonces`,
    cancel: 'Annuler',
    easywebinar : 'EasyWebinar', 
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Campagnes :',
    selected : 'Choisi',
    change: 'Changement',
    paragraph4 : `Les mots clés à exclure du groupe d'annonces s'appliquent uniquement à ce groupe d'annonces. Si vous souhaitez que les mots clés à exclure s'appliquent à tous les groupes d'annonces, définissez-les au niveau de la campagne.`,
    paragraph5 : `Pensez à laisser aux campagnes le temps de s'exécuter avant d'analyser les performances des annonces pour savoir quels mots clés peuvent ne pas fonctionner pour vous. L'ajout d'un trop grand nombre de mots clés à exclure peut limiter l'exposition de votre annonce.`,
    addedKeywords : 'Mots-clés ajoutés', 
    add: 'Ajouter',
    total: 'Total',
    save: 'sauver', 
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Ajouter une chaîne Slack',
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Annuler',
ok : `D'accord`,
  },

  //HelloWorld
  helloWorld : {
welcome : 'Bienvenue sur Vuetify',
para1 : `Pour obtenir de l'aide et collaborer avec d'autres développeurs Vuetify,`,
para2 : `s'il vous plaît rejoindre notre en ligne`,
discord : 'Communauté Discord',
h2 : "Et après?",
impLinks : 'Liens importants',
ecosystem : 'Écosystème',
  },

  //InfoAlert
  infoAlert : {
ok : `D'accord`,
  },
  };
  
  