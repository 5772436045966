//Chinese
export const zh = {
  pages: {
    overview: '概述',
    adsManager: '广告经理',
    kai: '关键词分析',
    keyOpt: '关键词优化',
    teamMgmt: '团队',
    integrations: '集成',
    automationRule: '自动化'
  },
  menu: {
    settings: '设置',
    signOut: '登出'
  },
  buttons: {
    syncData: '同步最新数据',
    createCampaign: '创建活动',
    filter: '筛选',
    reset: '重启',
    apply: '申请'
  },
  labels: {
    charts: '图表',
    selectDs: '选择数据集',
    totalSummary: '总摘要',
    spend: '花费'
  },
  dropdowns: {
    noData: '无可用数据',
    daily: '日常的',
    weekly: '每周',
    monthly: '每月'
  },
  tabs: {
    accounts: '帐户',
    apps: '应用',
    campaigns: '活动',
    adgroups: '广告组',
    keywords: '关键词',
    creativeSets: '创意集',
    searchTerms: '搜索词',
    negKeywords: '否定关键字'
  },
  summary: {
    taps: '水龙头',
    impressions: '印象',
    ttr: 'TTR',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    installs: '安装',
  },
  accountHeaders: {
    account: '帐户',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    ttr: 'TTR',
    conversionRate: '兑换率',
    newDownloads: '新下载',
    reDownloads: '重新下载'
  },
  appsHeaders: {
    appName: '应用',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstall: '属性安装',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    goalsRoas: '目标广告支出回报率',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    account: '帐户',
    categoryRank: '品类排名',
    appId: '应用程序 ID',
  },
  campaignHeaders: {
    campaign: '活动',
    campaignStatus: '活动状态',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRoas: '目标广告支出回报率',
    storefronts: '店面',
    account: '帐户',
    displayStatus: '显示状态',
    appName: '应用名称',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    delete: '删除'
  },
  campaignDraftHeaders: {
    campaignName: '活动名称',
    countries: '国家',
    budgetAmount: '预算额',
    resume: '恢复',
  },
  adGroupHeaders: {
    adgroup: '广告组',
    adgroupStatus: '广告组状态',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRoas: '目标广告支出回报率',
    storefronts: '店面',
    campaignStatus: '活动状态',
    displayStatus: '显示状态',
    account: '帐户',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID'
  },
  keyHeaders: {
    keyword: '关键词',
    keywordStatus: '关键字状态',
    bidAmount: '投标金额',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    organicRank: '有机排名',
    appName: '应用名称',
    difficultyScore: '难度分数',
    popularity: '人气',
    keywordId: '关键字 ID',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID',
    suggestedMinBid: 'ASA 建议最低出价',
    suggestedMaxBid: 'ASA 建议最高出价',
    bidStrength: '投标强度'
  },
  creativeHeaders: {
    creativeSet: '创意集',
    creativeSetStatus: '地位',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    account: '帐户',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID'
  },
  searchTermsHeader: {
    searchTerm: '搜索词文本',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '印象',
    taps: '水龙头',
    installs: '安装',
    ttr: 'TTR',
    conversionRate: '兑换率',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    keyword: '关键词',
    keywordId: '关键字 ID',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID'
  },
  negativeHeaders: {
    keyword: '关键词',
    matchType: '比赛类型',
    status: '地位',
    adgroupId: '广告组 ID',
    campaignId: '广告系列 ID',
    deleted: '已删除',
    keywordId: '关键字 ID',
    modificationTime: '修改时间'
  },
  filter: {
    account: '选择账户',
    app: '选择应用',
    campaign: '选择活动',
    adgroup: '选择广告组',
  },
  subTabs: {
    live: '居住',
    paused: '暂停',
    deleted: '已删除',
    draft: '草案',
    campaigns: '活动',
    adgroups: '广告组',
    keywords: '关键词',
    creativeSet: '创意集',
    negKey: '否定关键字'
  },
  popups: {
    add: '添加',
    integration: '一体化',
    adgroup: '广告组',
    keywords: '关键词',
    creativeSet: '创意集',
    negKey: '否定关键字'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: '管理用户',
    invite: '邀请和管理团队成员',
    add: '添加成员',
    first: '名',
    last: '姓',
    phone: '电话',
    email: '电子邮件',
    job: '职称',
    allMembers: '所有成员',
    processing: '加工...',
    ok: '好的',
    inviteStatus: '邀请状态',
    isDisabled: '被禁用',
    company: '公司名称'
  },

  //Add Integration Page
  integration: {
    assPart: '合作伙伴',
    apple: '苹果搜索广告',
    slack: '松弛',
    itunes: 'iTunes',
    tenjin: '天神',
    kochava: '科恰瓦',
    adjust: '调整',
    appsFlyer: '应用传单',
    singular: '单数'
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: '添加的关键字',
    total: '全部的',
    cancel: '取消',
    runAds: '投放广告',
    ok: '好的',
    totalPaidKey: '总付费关键字',
    popularity: '人气',
    numOfApps: '应用数量',
    chance: '机会',
    appsRunAds: '运行广告的应用',
    maxPop: '您的最大人气',
    keyword: '关键词',
    clear: '清除',
    clrAllFilter: '清除所有过滤器',
    keywords: '关键词',
    organicRank: '有机排名',
    totalApps: '应用程序总数',
    app: '应用程序',
    catName: '分类名称',
    searchByApp: '按应用搜索',
    searchByKey: '按关键字搜索',
    addAllKey: '添加所有关键字'
  },

  //Overview Page
  overview: {
    period: '时期',
    topKeywords: '前 10 个关键词',
    key: '关键词',
    spend: '花费',
    impressions: '印象',
    taps: '水龙头',
    goToKey: '转到所有关键字',
    topAdgroup: '排名前 10 的广告组',
    adgroup: '广告组',
    goToAdgroup: '转到所有广告组',
    gender: '性别',
    device: '设备',
    searchApp: '搜索应用',
    lastDay: '最后一天',
    last7Days: '过去 7 天',
    last30Days: '过去 30 天',
    ttr: '点击率 (TTR)',
    installs: '安装',
    avgCPA: '平均每次转化费用',
    avgCPT: '平均 CPT',
    conversionRate: '兑换率 (CR)',
    latOnInstalls: 'LAT 安装',
    latOffInstalls: 'LAT 关闭安装',
    reDownloads: '重新下载',
    newDownloads: '新下载',
    all: '全部',
    tooltip: '根据上一期间以百分比形式显示数据差异。 上一期间是上一个选定的日期范围'
  },

  //Keyword Optimization Page
 keyOpt: {
  keyBid: '关键字出价优化',
  keyToolTip: '您可以优化不在 ASA 建议的最低出价金额和 ASA 建议的最高出价金额范围内的关键字的出价金额。',
  optAll: '优化所有',
  opt: '优化',
  optBidAmt: '优化出价金额',
  key: '关键词',
  optBidForAll: '优化所有关键词的出价',
  campaign: '活动',
  adgroup: '广告组',
  bidAmt: '投标金额',
  asaMinBid: 'ASA 建议最低出价',
  asaMaxBid: 'ASA 建议最高出价',
  action: '行动'
  },
  
  //ARRules Page
  aRules: {
  createAR: '创建自动化规则',
  view: '看法',
  edit: '编辑',
  delete: '删除',
  disable: '禁用',
  enable: '使能够',
  name: '名称',
  createdAt: '创建于',
  status: '地位',
  completed: '完成时间',
  actionFreq: '动作频率',
  rules: '规则',
  logs: '日志',
  createdFor: '为',
  chckFreq: '检查频率',
  actions: '行动'
  },
 
  //Create Campaign
  //CampaignView
  createCampaign: {
  createCamp: '创建活动',
  h2: '选择一个应用程序开始制作广告系列',
  para: '广告系列的预算和广告组侧重于推广应用的共同主题或策略。',
  select: '选择广告的投放位置',
  searchResults: '搜索结果',
  srData: '广告系列的预算和广告组侧重于推广应用的共同主题或策略。',
  searchTab: '搜索标签',
  stData: '当用户访问“搜索”选项卡时，在建议的应用列表顶部宣传您的应用。',
  cancel: '取消',
  continue: '继续',
  
  //Search Results and Search Tab Campaign
  countAndReg: '国家和地区',
  adgroupSettings: '广告组设置',
  searchMatch: '搜索匹配',
  key: '关键词',
  audience: '观众',
  crSets: '创意集',
  chooseCampGroup: '选择一个活动组',
  chooseApp: '选择一个应用程序',
  next: '下一个',
  countPara: '您的 app 将在您从列表中选择的符合条件的国家和地区的 App Store 上推广。',
  chooseCount: '选择一个或多个国家或地区',
  campName: '活动名称',
  budget: '预算',
  dailyCap: '每日上限（可选）',
  adgroupPara: '广告组包含决定向谁展示您的广告的定价目标和设置。',
  adgroupName: '广告组名称',
  devices: '设备',
  startTime: '开始时间',
  adScheduling: 'Ad Scheduling',
  endTime: '时间结束',
  dayParting: '分时段（可选）',
  ok: '好的',
  defaultMax: '默认最高 CPT 出价',
  cpaGoal: '每次转化费用目标（可选）',
  saveDraft: '保存为草稿',
  appleSearchMatch: '苹果搜索匹配',
  searchMatchPara: 'Apple Search Match 是让您的广告投放和投放的最简单方法。 Search Ads 会自动将您的广告与正在搜索与您类似的应用的用户进行匹配。',
  searchMatchPara1: '自动将我的广告与相关搜索匹配',
  keywords: '关键词',
  keyPara: '关键字是客户在搜索像您这样的应用程序时可能使用的相关字词或术语。 关键字可让搜索者快速找到您的应用，有助于推动应用安装。',
  upload: '上传',
  suppFormat: '支持格式：Excel、CSV',
  keyManually: '或手动添加关键字',
  targKey: '定位关键字',
  clear: '清除',
  adgroupNegKey: '广告组否定关键字',
  optional: '可选的',
  negKey: '否定关键字',
  audPara: ' 使用可选的高级功能优化广告组的受众。',
  custType: '客户类型',
  demo: '人口统计',
  gender: '性别',
  location: '地点',
  crSetPara: '广告是使用 App Store 产品页面上提供的元数据、屏幕截图和应用程序预览自动创建的。 这些也出现在 App Store 的自然搜索结果中。 此处显示的广告是默认广告示例。',
  crOptional: '创意集（可选）',
  noCrSet: '应用程序不满足最低创意集的要求',
  campCreated: '广告系列已创建',
  campCreatedPara: '现在，您可以在 Search Ads 仪表板上看到您的广告系列',
  close: '关闭',
  matchType: '比赛类型',
  amount: '数量',
  currency: '货币',
  allUsers: '所有用户',
  newUsers: '新用户',
  appUsers: '我的其他应用的用户',
  existingUsers: '现有用户',
  all: '全部',
  male: '男性',
  female: '女性'
  },
 
  //Change Password
   changePass: {
     backSett: '返回设置',
     chngPass: '更改密码',
     newPass: '新密码',
     confirmPass: '确认密码',
     submit: '提交',
     h4: 'Kochava 营销人员操作系统™',
     para:
       '营销人员操作系统 (m/OS) 将面向广告商和发布商的全渠道营销解决方案无缝集成到一个运营平台中。',
     ok: '好的',
   },
 
   //Forgot Password
   forgotPass: {
     forPass: '忘记密码',
     typeEmail: '输入电子邮件地址',
     emailAdd: '电子邮件地址',
     submit: '提交',
     chckEmail: '请查看你的邮箱',
     para: '要设置新密码，请检查您的电子邮件',
     close: '关闭',
     alreadyAcc: '已经有账户？',
     signIn: '登入',
   },
 
   //Onboard
   onboard: {
     welcome: '欢迎来到科恰瓦',
     personal: '个人的',
     start: '开始',
     integration: '一体化',
     next: 'Next',
     trial: '开始试用',
     scheduleDemo: '或与我们一起安排演示并一起发现',
     addInt: '添加集成。',
     signInApple:
       '登录 Apple 以添加 Apple Search Ads 集成。 请注意：',
     step1:
       '1. 需要具有管理员权限才能授予对 Apple Search Ads 帐户的访问权限。',
     step2:
       '2. 为了从 SearchAdsNinja 管理您的广告系列，请在授予访问权限之前选择读取和写入选项。',
     skip: '跳过',
   },
 
   //AddAdgroup
   addAdgroup: {
     addAd: '添加广告组',
     para:
       '您尚未选择您的广告系列。 您可以从下面的框中选择它。',
     camGroup: '活动组',
     campaign: '活动',
     adgroupSett: '广告组设置',
     para1:
       '广告组包含决定向谁展示您的广告的定价目标和设置。',
     adgroupName: '广告组名称',
     devices: '设备',
     adScheduling: '广告排期',
     startTime: '开始时间',
     endTime: '时间结束',
     dayparting: '分时段（可选）',
     defaultMax: '默认最高 CPT 出价',
     cptTool:
       '这是您愿意为点击您的广告支付的最高金额。除非您应用单个关键字出价，否则此出价将应用于此广告组中的所有关键字。',
     cpaGoal: '每次转化费用目标（可选）',
     cpaTool:
       '您为每次下载支付的平均费用。 这是通过将总花费除以下载次数来计算的。',
     searchMatch: '搜索匹配',
     searchTool:
       '搜索匹配是让您的广告投放并投放的最简单方法。 Search Ads 会自动将您的广告与正在搜索与您类似的应用的用户进行匹配。',
     searchPara: '自动将我的广告与相关搜索匹配',
     key: '关键词',
     keyPara:
       '关键字是客户在搜索像您这样的应用程序时可能使用的相关字词或术语。 关键字可让搜索者快速找到您的应用，有助于推动应用安装。',
     inputCpt: '输入默认最高 CPT 出价以激活关键字部分',
     addKeyPara:
       '添加与您的应用和类型相关的关键字。 我们将通过分享其他建议来提供帮助。',
     upload: '上传',
     addKeyManually: '或手动添加关键字',
     negKey: '广告组否定关键字（可选）',
     supp: '支持格式：Excel、CSV',
     demAge: '人口统计年龄、性别',
     demPara: "根据用户的年龄或性别定位您的广告",
     ageRange: '年龄范围',
     cancel: '取消',
     apply: '申请',
   },
 
   //Edit Adgroup
   editAdgroups: {
     edit: '修改广告组',
     adgroup: '广告组',
     advance: '高级设置',
     viewEdit: '查看和编辑',
     general: '一般的',
     settings: '设置',
     schedule: '日程',
     custPara: '选择您想要接触的应用客户',
     selectAge: '选择年龄范围',
     save: '保存',
   },
 
   //Create AR
   createAR: {
     createAutoRule: '创建自动化规则',
     editAutoRule: '编辑自动化规则',
     choose: '选择',
     chooseAutoRule: '请选择自动化级别和项目。',
     conditions: '使适应',
     actions: '行动',
     settings: '设置',
     condition: '状况',
     addCondition: '添加条件',
     andCondition:
       '选择以上所有条件必须匹配还是仅匹配其中之一',
     checkCond: '检查条件',
     action: '行动',
     selectAll: '全选',
     enterEmail: '请输入正确的电子邮件 ID',
     addTo: '添加',
     selectAdgroups: '选择广告组',
     selectCampaigns: '选择广告系列',
     cptBid: 'CPT投标',
     customBid: '自定义投标',
     matchType: '比赛类型',
     addAction: '添加操作',
     chckFreq: '检查频率',
     ruleName: '规则名称',
     cancel: '取消',
     step2: '第2步',
     step3: '第 3 步',
     andOrTypesText1: '所有必须匹配',
     andOrTypesText2: '只有一个应该匹配',
     addToModelListNegText1: '所有选定的广告组',
     addToModelListNegText2: '所选广告系列的所有广告组）',
     addToModelListNegText3: '所有选定的广告系列',
     matchTypesText1: '广泛匹配',
     matchTypesText2: '完全符合',
     cptBidItemsText1: '设置为自定义',
     cptBidItemsText2: '广告组默认出价',
     actionFreqsText1: '每小时',
     actionFreqsText2: '每 6 小时',
     actionFreqsText3: '每 12 小时',
     actionFreqsText4: '每天',
     actionFreqsText5: '自定义时间',
     limitActionFreqsText1: '每 2 小时',
     limitActionFreqsText2: '每 3 小时',
     limitActionFreqsText3: '每 2 天',
     limitActionFreqsText4: '每 3 天',
     limitActionFreqsText5: '每周',
     mon: '周一',
     tue: '周二',
     wed: '周三',
     thu: '周四',
     fri: '星期五',
     sat: '周六',
     sun: '星期日',
     ciTimeRangesText1: '过去 1 小时',
     ciTimeRangesText2: '过去 1 天',
     ciTimeRangesText3: '过去 7 天',
     ciTimeRangesText4: '过去 30 天',
     ciTimeRangesText5: '上个月',
     ciTimeRangesText6: '自定义小时',
     allConOperationText1: '比...更棒',
     allConOperationText2: '少于',
     allConOperationText3: '范围',
     allConOperationText4: '不在范围内',
     amount: '金额',
     budget: '% 预算百分比',
     incBy: '减少',
     decBy: '通过增加',
     setToCPA: '设置为平均每次转化费用',
     setToCPT: '设置为平均 CPT',
     value: '美元价值',
     change: '％ 改变',
   },
 
   //select Account AR
   selectAccountAR: {
     chooseAcc: '选择账户',
     cancel: '取消',
     apply: '申请',
     clearAll: '清除所有',
     accounts: '帐户',
     accountSelected: '选择的帐户',
   },
 
   //select Apps AR
   selectAppsAR: {
     chooseApp: '选择应用',
     cancel: '取消',
     apply: '申请',
     apps: '应用',
     appSelected: '选择的应用程序',
     clearAll: '清除所有',
   },
 
   //select Keywords AR
   selectKeywordsAR: {
     keepOneAdGroup: '请仅选择一个广告组以继续。',
     close: '关闭',
     chooseKeywords: '选择关键字',
     cancel: '取消',
     apply: '申请',
     campaigns: '活动',
     expandAll: '展开全部',
     collapseAll: '全部收缩',
     adGroups: '广告组',
     keywords: '关键词',
     keywordSelected: '已选择关键字',
     clearAll: '清除所有',
   },
 
   //campaign
   //AddCreativeSet
   addCreativeSet: {
     addCreative: '添加创意集',
     setup: '设置',
     para:
       '选择一个描述创意集主题的名称。 从 App Store 产品页面中选择您想要包含的屏幕截图和预览的语言。',
     createSetName: '创意集名称 ',
     localLang: '本地化语言',
     assetSelection: '资产选择',
     assetSelectionPararaph1:
       'Creative Sets 包含出现在 App Store 产品页面上的屏幕截图和应用程序预览。',
     assetSelectionPararaph2: '对于每个 iPhone 显示尺寸，',
     assetSelectionPararaph3:
       '选择至少一种横向资产或三项纵向资产。',
     assetSelectionPararaph4: '对于每个 iPad 显示尺寸，',
     assetSelectionPararaph5:
       '选择至少一种横向或两种纵向资产。',
     title1: 'iPhone/iPod',
     screenshotsPara1: '请至少选择',
     screenshotsPara2: '一景',
     screenshotsPara3: '或者',
     screenshotsPara4: '两个人像',
     screenshotsPara5: '截图！',
     screenshotsPara6: '请至少选择',
     screenshotsPara7: '一景',
     screenshotsPara8: '或者',
     screenshotsPara9: '三人像',
     screenshotsPara10: '截图！',
     cancel: '取消',
     save: '保存',
   },
 
   //DeleteCampaign
   deleteCampaign: {
     deleteCamp: '删除广告系列',
     para: '您确定要删除广告系列吗？',
     cancel: '取消',
     delete: '删除',
   },
 
   //EditCampaign
   editCampaign: {
     editCampWizard: '活动编辑向导',
     campName: '活动名称',
     app: '应用程序',
     countriesOrRegionsPara: '选择一个或多个国家或地区',
     budget: '预算',
     dailyCap: '每日上限',
     cancel: '取消',
     save: '保存',
   },
 
   //SelectCampaign
   selectCamp: {
     chooseCamp: '选择广告系列',
     noSelectedCamp: '没有选定的活动',
     campaigns: '活动',
     expandAll: '展开全部',
     collapseAll: '全部收缩',
     selectedCampaigns: '选定的活动',
     clearAll: '清除所有',
     cancel: '取消',
     apply: '申请',
   },
 
   //chargebee
   //EditCard
   editCard: {
     editCreditCard: '编辑信用卡',
     cardNumber: '卡号',
     expiryDate: '到期日',
     cvv: '变异系数',
     saveCard: '存卡',
     updateExpiryPara: '* 您只能更新到期日',
   },
 
   //creativeset
   //EditCreativeSet
   editCreativeSet: {
     editCreative: '编辑创意集',
     easywebinar: '易网络研讨会',
     softobiz: 'Softobiz 技术私人有限公司',
     setup: '设置',
     editCreativePara1:
       '选择一个描述创意集主题的名称。 从 App Store 产品页面中选择您想要包含的屏幕截图和预览的语言。',
     creativeSetName: '创意集名称',
     localLang: '本地化语言',
     assetSelection: '资产选择',
     assetSelectionPararaph1:
       '创意集 包含出现在 应用商店 产品页面上的屏幕截图和应用程序预览。',
     assetSelectionPararaph2: '对于每个 iPhone 显示尺寸，',
     assetSelectionPararaph3:
       '选择至少一种横向资产或三项纵向资产。',
     assetSelectionPararaph4: '对于每个 iPad 显示尺寸，',
     assetSelectionPararaph5:
       '至少选择一景或两个人像资产。',
     viewDevice: '查看设备显示尺寸',
     title1: 'iPhone/iPod',
     ipad: 'iPad',
     display: '展示',
     default: '默认',
     cancel: '取消',
     save: '保存',
   },
 
   //integration
   //DeleteIntegration
   deleteIntegration: {
     deleteInt: '删除集成',
     cardText: '确定要删除集成吗？',
     cancel: '取消',
     delete: '删除',
   },
 
   //keywords
   //AddKeys
   addKeys: {
     addKeywords: '添加关键字',
     keywordSuggestionsFor: '关键字建议',
     on: '上',
     addedKeywords: '添加的关键字',
     total: '全部的',
     add: '添加',
     cancel: '取消',
     apply: '申请',
   },
 
   //AddKeysKai
   addKeysKai: {
     addKeywords: '添加关键字',
     selectTarget: '选择目标',
     h4Camp: '活动：',
     selected: '已选',
     change: '改变',
     h4Adgroups: '广告组:',
     maxCPTBid: '最高 CPT 出价：',
     keywordSuggestionsFor: '关键字建议',
     on: '上',
     recommendedKeywords: '推荐关键词',
     addedKeywords: '添加的关键字',
     add: '添加',
     total: '全部的',
     cancel: '取消',
     apply: '申请',
     ok: '好的',
   },
 
   //AddKeywords
   addKeywords: {
     h6AddKeywords: '添加关键字',
     selectTarget: '选择目标',
     h4Camp: '活动：',
     selected: '已选',
     change: '改变',
     h4Adgroups: '广告组:',
     maxCPTBid: '最高 CPT 出价：',
     keywordSuggestionsFor: '关键字建议',
     on: '上',
     recommendedKeywords: '推荐关键词',
     addedKeywords: '添加的关键字',
     add: '添加',
     total: '全部的',
     cancel: '取消',
     apply: '申请',
     ok: '好的',
   },
 
   //AddnKeys
   addnKeys: {
     addNegKeywords: '添加否定关键字',
     add: '添加',
     total: '全部的',
     cancel: '取消',
     save: '保存',
   },
 
   //EditBidAmount
   editBidAmount: {
     bidAmount: '投标金额',
   },
 
   //SelectAdGroup
   selectAdGroup: {
     snackbar: '请仅选择一个广告组以继续。',
     close: '关闭',
     chooseAdGroup: '选择广告组',
     cancel: '取消',
     apply: '申请',
     campaigns : '活动',
     expandAll: '展开全部',
     collapseAll: '全部收缩',
     adGroups : '广告组',
     adGroupSelected : '选择的广告组',
     clearAll : '清除所有'
   },
 
   //negativeKeywords
   //AddNegativeKeywords
   addNegativeKeywords : {
     addNegKeywords : '添加否定关键字',
     selectTarget : '1. 选择目标',
     addNegKeyword : '添加否定关键字',
     paragraph1 : '可以将否定关键字添加到广告系列或广告组。 请从下面的框中选择其中之一。',
     paragraph2 : '您要上传哪个广告系列的关键字？',
     selectCampaign : '选择活动',
     paragraph3 : '您要上传哪个广告组的关键字？',
     selectAdgroup : '选择广告组',
     cancel: '取消',
     easywebinar : '易网络研讨会', 
     softobiz : 'Softobiz 技术私人有限公司',
     campaigns : '活动：',
     selected : '已选',
     change: '改变',
     paragraph4 : ' 广告组否定关键字仅适用于该广告组。 如果您希望否定关键字应用于所有广告组，请在广告系列级别进行设置。',
     paragraph5 : ' 在分析广告效果以了解哪些关键字可能不适合您之前，请考虑让广告系列有时间运行。 添加过多否定关键字可能会限制您的广告曝光率。',
     addedKeywords : '添加的关键字', 
     add: '添加',
     total: '全部的',
     save: '保存', 
   },
 
   //oAuth
   //SigninSlackBtn
   signinSlackBtn : {
     addSlackChannel : '添加松弛通道',
   },
 
   //DecisionAlert
    decisionAlert : {
 cancel : '取消',
 ok : '好的',
   },
 
   //HelloWorld
   helloWorld : {
 welcome : '欢迎使用 Vuetify',
 para1 : '如需与其他 Vuetify 开发人员的帮助和协作，',
 para2 : '请加入我们的在线',
 discord : '不和谐社区',
 h2 : "下一步是什么？",
 impLinks : '重要链接',
 ecosystem : '生态系统',
   },
 
   //InfoAlert
   infoAlert : {
 ok : '好的',
   },
 
}

