import { SESSION_HEADER_NAME, TEAM_HEADER_NAME, TOKEN_HEADER_NAME, USER_HEADER_NAME } from '@shared/config'

import { LSS } from '@core/services'
import axios from 'axios';
import { decodeToken } from '@shared/utils/commom.util'

export default function setupInterceptor(): void {
  const decoded = decodeToken(LSS.token)
  axios.interceptors.request.use((config) => {
    const UserID = decoded.UserId;
    const TeamID = decoded.TeamID;
    const Token = LSS.token;
    const SessID = LSS.sessId;

    if (UserID) {
      config.headers = { [USER_HEADER_NAME]: UserID, ...config.headers };
    }

    if (TeamID) {
      config.headers = { [TEAM_HEADER_NAME]: TeamID, ...config.headers };
    }

    if (Token) {
      config.headers = { [TOKEN_HEADER_NAME]: Token, ...config.headers };
    }

    if (SessID) {
      config.headers = { [SESSION_HEADER_NAME]: SessID, ...config.headers };
    }

    return config;
  });
}
