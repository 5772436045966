//German
export const de = {
  pages: {
    overview: 'Überblick',
    adsManager: 'Anzeigenmanager',
    kai: 'Keyword-Analyse',
    keyOpt: 'Stichwort-Optimierung',
    teamMgmt: 'Team',
    integrations: 'Integrationen',
    automationRule: 'Automatisierung'
  },
  menu: {
    settings: 'die Einstellungen',
    signOut: 'Ausloggen'
  },
  buttons: {
    syncData: 'Neueste Daten Synchronisieren',
    createCampaign: 'Kampagne erstellen',
    filter: 'Filter',
    reset: 'Zurücksetzen',
    apply: 'Anwenden'
  },
  labels: {
    charts: 'Diagramme',
    selectDs: 'Datensätze auswählen',
    totalSummary: 'Gesamtzusammenfassung',
    spend: 'Verbringen'
  },
  dropdowns: {
    noData: 'Keine Daten verfügbar',
    daily: 'TÄGLICH',
    weekly: 'WÖCHENTLICH',
    monthly: 'MONATLICH'
  },
  tabs: {
    accounts: 'Konten',
    apps: 'Anwendungen',
    campaigns: 'Kampagnen',
    adgroups: 'Anzeigengruppen',
    keywords: 'Schlüsselwörter',
    creativeSets: 'Kreativsets',
    searchTerms: 'Suchbegriffe',
    negKeywords: 'Negative Schlüsselwörter'
  },
  summary: {
    taps: 'Wasserhähne',
    impressions: 'Impressionen',
    ttr: 'TTR',
    newDownloads: 'Neue Downloads',
    reDownloads: 'Re-Downloads',
    installs: 'Installiert',
  },
  accountHeaders: {
    account: 'Konto',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen'
  },
  appsHeaders: {
    appName: 'Anwendung',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstall: 'Attr Installieren',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    goalsRoas: 'Tore ROAS',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    account: 'Konto',
    categoryRank: 'Kategorie Rang',
    appId: 'Anwendung ICH WÜRDE',
  },
  campaignHeaders: {
    campaign: 'Kampagne',
    campaignStatus: 'Kampagne Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRoas: 'Tore ROAS',
    storefronts: 'Schaufenster',
    account: 'Konto',
    displayStatus: 'Anzeige Status',
    appName: 'Anwendung Name',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    delete: 'Löschen'
  },
  campaignDraftHeaders: {
    campaignName: 'Kampagne Name',
    countries: 'Länder',
    budgetAmount: 'Budget Menge',
    resume: 'Fortsetzen',
  },
  adGroupHeaders: {
    adgroup: 'Anzeigengruppe',
    adgroupStatus: 'Anzeigengruppe Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRoas: 'Tore ROAS',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    displayStatus: 'Anzeige Status',
    account: 'Konto',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE'
  },
  keyHeaders: {
    keyword: 'Stichwort',
    keywordStatus: 'Stichwort Status',
    bidAmount: 'Bieten Menge',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    organicRank: 'Organischer Rang',
    appName: 'Anwendung Name',
    difficultyScore: 'Schwierigkeit Ergebnis',
    popularity: 'Popularität',
    keywordId: 'Stichwort ICH WÜRDE',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    suggestedMinBid: 'ASA Empfohlen Minimum Bieten',
    suggestedMaxBid: 'ASA Empfohlen Maximal Bieten',
    bidStrength: 'Bieten Stärke'
  },
  creativeHeaders: {
    creativeSet: 'Kreativsets',
    creativeSetStatus: 'Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    account: 'Konto',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE'
  },
  searchTermsHeader: {
    searchTerm: 'Suchbegriff Text',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    keyword: 'Stichwort',
    keywordId: 'Stichwort ICH WÜRDE',
    appId: 'Anwendung ID',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE'
  },
  negativeHeaders: {
    keyword: 'Stichwort',
    matchType: 'Spiel Art',
    status: 'Status',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    deleted: 'Gelöscht',
    keywordId: 'Stichwort ICH WÜRDE',
    modificationTime: 'Änderung Zeit'
  },
  filter: {
    account: 'Wählen Konto',
    app: 'Wählen Anwendung',
    campaign: 'Wählen Kampagne',
    adgroup: 'Wählen Anzeigengruppe',
  },
  subTabs: {
    live: 'Wohnen',
    paused: 'Angehalten',
    deleted: 'Gelöscht',
    draft: 'Entwurf',
    campaigns: 'Kampagnen',
    adgroups: 'Anzeigengruppen',
    keywords: 'Schlüsselwörter',
    creativeSet: 'Kreativsets',
    negKey: 'Negativ Schlüsselwörter'
  },
  popups: {
    add: 'Hinzufügen',
    integration: 'Integration',
    adgroup: 'Anzeigengruppe',
    keywords: 'Schlüsselwörter',
    creativeSet: 'Kreativsets',
    negKey: 'Negativ Schlüsselwörter'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: 'Benutzer verwalten',
    invite: 'Einladen und Verwalten von Teammitgliedern',
    add: 'Mitglied hinzufügen',
    first: 'Vorname',
    last: 'Nachname',
    phone: 'Telefon',
    email: 'Email',
    job: 'Berufsbezeichnung',
    allMembers: 'Alle Mitglieder',
    processing: 'wird bearbeitet...',
    ok: 'OK',
    inviteStatus: 'Einladungsstatus',
    isDisabled: 'Ist behindert',
    company: 'Name der Firma'
  },

  //Add Integration Page
  integration: {
    assPart: 'Assoziierte Partner',
    apple: 'Apple Search Ads',
    slack: 'Slack',
    itunes: 'Itunes',
    tenjin: 'Tenjin',
    kochava: 'Kochava',
    adjust: 'Adjust',
    appsFlyer: 'AppsFlyer',
    singular: 'Singular'
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: 'Schlagwörter hinzugefügt',
    total: 'Gesamt',
    cancel: 'Stornieren',
    runAds: 'Anzeigen ausführen',
    ok: 'OK',
    totalPaidKey: 'Bezahlte Keywords insgesamt',
    popularity: 'Popularität',
    numOfApps: 'Anzahl der Bewerbungen',
    chance: 'Chance',
    appsRunAds: 'Anwendungen, die Anzeigen ausführen',
    maxPop: 'Maximale Popularität für dich',
    keyword: 'Stichwort',
    clear: 'klar',
    clrAllFilter: 'Alle Filter löschen',
    keywords: 'KeywoSchlüsselwörterrds',
    organicRank: 'Organischer Rang',
    totalApps: 'Gesamtzahl der Bewerbungen',
    app: 'Anwendung',
    catName: 'Kategoriename',
    searchByApp: 'Suche nach Anwendung',
    searchByKey: 'über Schlüsselwort suchen',
    addAllKey: 'Alle Keywords hinzufügen',
    searchForCompetes: 'Vorgeschlagene Wettbewerber',
    searchAdsRecommends: 'Empfehlungen für Suchanzeigen',
    categoryTop: 'Kategorie Top Apps',
    compRating: 'Bewertung',
    compVersion: 'Ausführung',
    compPrice: 'Preis',
    compGenres: 'Genres',
    monthlyDownloads: 'Monatliche Downloads',
    free: 'Kostenlos',
    paid: 'Bezahlt',
  },

  //Overview Page
  overview: {
    period: 'Zeitraum',
    topKeywords: 'oben 10 Schlüsselwörter von',
    key: 'Stichwort',
    spend: 'Verbringen',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    goToKey: 'Gehe zu Alle Schlüsselwörter',
    topAdgroup: 'oben 10 Anzeigengruppen von',
    adgroup: 'Anzeigengruppen',
    goToAdgroup: 'Gehe zu Alle Anzeigengruppen',
    gender: 'Geschlecht',
    device: 'Gerät',
    searchApp: 'Anwendung suchen',
    lastDay: 'Letzter Tag',
    last7Days: 'Letzten 7 Tage',
    last30Days: 'Letzten 30 Tage',
    ttr: 'Durchgriff Bewertung',
    installs: 'Installiert',
    avgCPA: 'Durchschnittlich CPA',
    avgCPT: 'Durchschnittlich CPT',
    conversionRate: 'Wechselkurs',
    latOnInstalls: 'LAT Bei Installationen',
    latOffInstalls: 'LAT Aus Installationen',
    reDownloads: 'Erneute Downloads',
    newDownloads: 'Neue Downloads',
    all: 'Alle',
    tooltip: 'Zeigt die Datendifferenz als Prozentsatz gemäß der Vorperiode an. Vorheriger Zeitraum ist der zuvor ausgewählte Zeitraum'
  },

  //Keyword Optimization Page
  keyOpt: {
    keyBid: 'Optimierung von Keyword-Geboten',
    keyToolTip: 'Sie können den Gebotsbetrag der Keywords optimieren, die nicht im Bereich des von ASA vorgeschlagenen Mindestgebotsbetrags und des von ASA vorgeschlagenen Höchstgebotsbetrags liegen.',
    optAll: 'Alles optimieren',
    opt: 'Optimieren',
    optBidAmt: 'Gebotsbetrag optimieren',
    key: 'Stichwort',
    optBidForAll: 'Gebotsbetrag aller Keywords optimieren',
    campaign: 'Kampagne',
    adgroup: 'Anzeigengruppe',
    bidAmt: 'Gebotsbetrag',
    asaMinBid: 'Von ASA empfohlenes Mindestgebot',
    asaMaxBid: 'Von ASA empfohlenes Höchstgebot',
    action: 'Aktion'
  },

  //ARRules Page
  aRules: {
    createAR: 'Automatisierungsregel erstellen',
    view: 'Aussicht',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    disable: 'Deaktivieren',
    enable: 'Aktivieren',
    name: 'Name',
    createdAt: 'Hergestellt in',
    status: 'Status',
    completed: 'Abgeschlossen At',
    actionFreq: 'Aktionshäufigkeit',
    rules: 'Regeln',
    logs: 'Protokolle',
    createdFor: 'Hergestellt für',
    chckFreq: 'Häufigkeit prüfen',
    actions: 'Aktionen'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
    createCamp: 'Kampagne erstellen',
    h2: 'Wählen Sie eine App aus, um eine Kampagne zu erstellen',
    para: 'Kampagnen haben Budgets und Anzeigengruppen, die sich auf ein gemeinsames Thema oder eine gemeinsame Strategie zur Bewerbung Ihrer App konzentrieren.',
    select: 'Wählen Sie aus, wo Anzeigen geschaltet werden sollen',
    searchResults: 'Suchergebnisse',
    srData: 'Kampagnen haben Budgets und Anzeigengruppen, die sich auf ein gemeinsames Thema oder eine gemeinsame Strategie zur Bewerbung Ihrer App konzentrieren.',
    searchTab: 'Registerkarte "Suchen"',
    stData: 'Bewerben Sie Ihre App ganz oben in der Liste der vorgeschlagenen Apps, wenn Nutzer den Tab "Suchen" aufrufen.',
    cancel: 'Stornieren',
    continue: 'Fortsetzen',

    //Search Results and Search Tab Campaign
    countAndReg: 'Länder und Regionen',
    adgroupSettings: 'Einstellungen für Anzeigengruppe',
    searchMatch: 'Suchtreffer',
    key: 'Stichwort',
    audience: 'Publikum',
    crSets: 'Kreativsets',
    chooseCampGroup: 'Wähle eine Kampagnengruppe',
    chooseApp: 'Wähle eine App',
    next: 'Nächster',
    countPara: 'Ihre App wird im App Store in den berechtigten Ländern und Regionen beworben, die Sie aus der Liste auswählen.',
    chooseCount: 'Wählen Sie ein oder mehrere Länder oder Regionen aus',
    campName: 'Kampagnenname',
    budget: 'Budget',
    dailyCap: 'Tagesobergrenze (optional)',
    adgroupPara: 'Anzeigengruppen enthalten Preisziele und Einstellungen, die bestimmen, für wen Ihre Anzeige geschaltet werden soll.',
    adgroupName: 'Anzeigengruppenname',
    devices: 'Geräte',
    startTime: 'Startzeit',
    adScheduling: 'Anzeigenplanung',
    endTime: 'Endzeit',
    dayParting: 'Tagesausflug (Optional)',
    ok: 'OK',
    defaultMax: 'Standardmäßiges maximales CPT-Gebot',
    cpaGoal: 'CPA-Ziel(Optional)',
    saveDraft: 'Als Entwurf speichern',
    appleSearchMatch: 'Apple-Suchabgleich',
    searchMatchPara: 'Apple Search Match ist der einfachste Weg, um Ihre Anzeigen zum Laufen zu bringen. Search Ads ordnet Ihre Anzeige automatisch Nutzern zu, die nach Apps wie Ihrer suchen.',
    searchMatchPara1: 'Meine Anzeige automatisch relevanten Suchanfragen zuordnen',
    keywords: 'Schlüsselwörter',
    keyPara: 'Keywords sind relevante Wörter oder Begriffe, die Kunden verwenden können, wenn sie nach einer App wie Ihrer suchen. Mithilfe von Schlüsselwörtern können Suchende Ihre App schnell finden und so die App-Installationen steigern.',
    upload: 'Hochladen',
    suppFormat: 'unterstützte Formate: Excel, CSV',
    keyManually: 'Oder fügen Sie Keywords manuell hinzu',
    targKey: 'Targeting-Keywords',
    clear: 'klar',
    adgroupNegKey: 'Ausschließende Keywords für Anzeigengruppe',
    optional: 'Optional',
    negKey: 'Ausschließende Keywords',
    audPara: ' Verfeinern Sie die Zielgruppe für Ihre Anzeigengruppe mit optionalen erweiterten Funktionen.',
    custType: 'Kundentyp',
    demo: 'Demografie',
    gender: 'Geschlecht',
    location: 'Ort',
    crSetPara: 'Anzeigen werden automatisch mit den Metadaten, Screenshots und App-Vorschauen erstellt, die auf der App Store-Produktseite bereitgestellt werden. Diese erscheinen auch in den organischen Suchergebnissen im App Store. Die hier gezeigten Anzeigen sind Beispiele für Standardanzeigen.',
    crOptional: 'Kreativset (Optional)',
    noCrSet: 'App erfüllt nicht die Anforderungen des minimalen Creative Sets',
    campCreated: 'Kampagne erstellt',
    campCreatedPara: 'Jetzt können Sie Ihre Kampagnen im Search Ads-Dashboard sehen',
    close: 'Schließen',
    matchType: 'Übereinstimmungstyp',
    amount: 'Menge',
    currency: 'Währung',
    allUsers: 'Alle Nutzer',
    newUsers: 'Neue Nutzer',
    appUsers: 'Nutzer meiner anderen Apps',
    existingUsers: 'Existierende Benutzer',
    all: 'Alle',
    male: 'Männlich',
    female: 'Weiblich'
  },

  //Change Password
  changePass: {
    backSett: 'Zurück zu den Einstellungen',
    chngPass: 'Kennwort ändern',
    newPass: 'Neues Kennwort',
    confirmPass: 'Kennwort bestätigen',
    submit: 'einreichen',
    h4: 'The Kochava Marketers Operating System™',
    para: 'The Kochava Marketers Operating System (m/OS) integriert nahtlos Omnichannel-Marketinglösungen für Werbetreibende und Publisher in einer operativen Plattform.',
    ok: 'OK'
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Passwort vergessen',
    typeEmail: 'Geben Sie die E-Mail-Adresse ein',
    emailAdd: 'E-Mail-Addresse',
    submit: 'einreichen',
    chckEmail: 'Bitte überprüfen Sie Ihre E-Mail',
    para: 'Um ein neues Passwort festzulegen, überprüfen Sie bitte Ihre E-Mail',
    close: 'Schließen',
    alreadyAcc: 'Sie haben bereits ein Konto?',
    signIn: 'Einloggen',
  },

  //Onboard
  onboard: {
    welcome: 'Willkommen in Kochava',
    personal: 'persönlich',
    start: 'Start',
    integration: 'Integration',
    next: 'Nächster',
    trial: 'Starten Sie Ihre Testversion',
    scheduleDemo: 'Oder vereinbaren Sie mit uns eine Demo und entdecken Sie gemeinsam',
    addInt: 'Fügen Sie eine Integration hinzu.',
    signInApple: 'Melden Sie sich bei Apple an, um eine Apple Search Ads-Integration hinzuzufügen. Bitte beachten Sie:',
    step1: '1. Es ist erforderlich, über Administratorrechte zu verfügen, um Zugriff auf das Apple Search Ads-Konto zu gewähren.',
    step2: '2. Um Ihre Kampagnen von SearchAdsNinja aus zu verwalten, wählen Sie bitte die Option Lesen & Schreiben, bevor Sie den Zugriff gewähren.',
    skip: 'Überspringen'
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Anzeigengruppe hinzufügen',
    para: 'Sie haben Ihre Kampagne noch nicht ausgewählt. Sie können es aus dem unteren Feld auswählen.',
    camGroup: 'Kampagnengruppe',
    campaign: 'Kampagne',
    adgroupSett: 'Einstellungen für Anzeigengruppe',
    para1: 'Anzeigengruppen enthalten Preisziele und Einstellungen, die bestimmen, für wen Ihre Anzeige geschaltet werden soll.',
    adgroupName: 'Anzeigengruppenname',
    devices: 'Geräte',
    adScheduling: 'Anzeigenplanung',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    dayparting: 'Tagesausflug (Optional)',
    defaultMax: 'Standardmäßiges maximales CPT-Gebot',
    cptTool: 'Dies ist der Höchstbetrag, den Sie für ein Tippen auf Ihre Anzeige zu zahlen bereit sind. Dieses Gebot gilt für alle Keywords in dieser Anzeigengruppe, es sei denn, Sie geben ein einzelnes Keyword-Gebot an.',
    cpaGoal: 'CPA-Ziel(Optional)',
    cpaTool: 'Der Durchschnitt, den Sie für jeden Download bezahlt haben. Dies wird berechnet, indem die Gesamtausgaben durch die Anzahl der Downloads geteilt werden.',
    searchMatch: 'Suchtreffer',
    searchTool: 'Search Match ist der einfachste Weg, um Ihre Anzeigen zu schalten. Search Ads ordnet Ihre Anzeige automatisch Nutzern zu, die nach Apps wie Ihrer suchen.',
    searchPara: 'Meine Anzeige automatisch relevanten Suchanfragen zuordnen',
    key: 'Schlüsselwörter',
    keyPara: 'Schlüsselwörter sind relevante Wörter oder Begriffe, die Kunden verwenden können, wenn sie nach einer App wie Ihrer suchen. Mithilfe von Schlüsselwörtern können Suchende Ihre App schnell finden und so die App-Installationen steigern',
    inputCpt: 'Geben Sie das standardmäßige maximale CPT-Gebot ein, um den Abschnitt mit den Keywords zu aktivieren',
    addKeyPara: 'Fügen Sie Keywords hinzu, die für Ihre App und Ihr Genre relevant sind. Wir helfen Ihnen, indem wir zusätzliche Empfehlungen teilen.',
    upload: 'Hochladen',
    addKeyManually: 'Oder fügen Sie Keywords manuell hinzu',
    negKey: 'Ausschließende Keywords der Anzeigengruppe (optional)',
    supp: 'unterstützte Formate: Excel, CSV',
    demAge: 'Demografie Alter, Geschlecht',
    demPara: 'Richten Sie Ihre Anzeige auf das Alter oder Geschlecht Ihres Nutzers aus',
    ageRange: 'Altersspanne',
    cancel: 'Stornieren',
    apply: 'Anwenden'
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Anzeigengruppe bearbeiten',
    adgroup: 'Anzeigengruppe',
    advance: 'Erweiterte Einstellungen',
    viewEdit: 'Anzeigen und Bearbeiten',
    general: 'Allgemeines',
    settings: 'die Einstellungen',
    schedule: 'Zeitplan',
    custPara: 'Wählen Sie die App-Kunden aus, die Sie erreichen möchten',
    selectAge: 'Altersgruppe auswählen',
    save: 'speichern'
  },

  //Create AR
  createAR: {
    createAutoRule: 'Automatisierungsregel erstellen',
    editAutoRule: 'Automatisierungsregel bearbeiten',
    choose: 'Wählen',
    chooseAutoRule: 'Bitte wählen Sie Automatisierungsstufe und Elemente aus.',
    conditions: 'Bedingungen',
    actions: 'Aktionen',
    settings: 'die Einstellungen',
    condition: 'Bedingung',
    addCondition: 'Bedingung hinzufügen',
    andCondition: 'Wählen Sie aus, ob alle oben genannten Bedingungen zutreffen müssen oder nur eine von ihnen zutreffen soll',
    checkCond: 'Bedingungen prüfen für conditions',
    action: 'Aktion',
    selectAll: 'Wählen Sie Alle',
    enterEmail: 'Bitte geben Sie die richtigen E-Mail-IDs ein',
    addTo: 'Ergänzen',
    selectAdgroups: 'Wählen Sie Anzeigengruppen',
    selectCampaigns: 'Kampagnen auswählen',
    cptBid: 'CPT-Gebot',
    customBid: 'Benutzerdefiniertes Gebot',
    matchType: 'Übereinstimmungstyp',
    addAction: 'Aktion hinzufügen',
    chckFreq: 'Häufigkeit prüfen',
    ruleName: 'Regelname',
    cancel: 'Stornieren',
    step2: 'Schritt 2',
    step3: 'Schritt 3',
    andOrTypesText1: 'Alle müssen übereinstimmen',
    andOrTypesText2: 'Nur einer sollte passen',
    addToModelListNegText1: 'Alle ausgewählten Anzeigengruppe(n)',
    addToModelListNegText2: 'Alle Anzeigengruppen der ausgewählten Kampagne(n)',
    addToModelListNegText3: 'Alle ausgewählten Kampagne(n)',
    matchTypesText1: 'Weite Übereinstimmung',
    matchTypesText2: 'Genaue Übereinstimmung',
    cptBidItemsText1: 'Auf Benutzerdefiniert einstellen',
    cptBidItemsText2: 'Standardgebot der Anzeigengruppe',
    actionFreqsText1: 'Stündlich',
    actionFreqsText2: 'Alle 6 Stunden',
    actionFreqsText3: 'Alle 12 Stunden',
    actionFreqsText4: 'Täglich',
    actionFreqsText5: 'Benutzerdefinierte Öffnungszeiten',
    limitActionFreqsText1: 'Alle 2 Stunden',
    limitActionFreqsText2: 'Alle 3 Stunden',
    limitActionFreqsText3: 'Alle 2 Tage',
    limitActionFreqsText4: 'Alle 3 Tage',
    limitActionFreqsText5: 'Jede Woche',
    mon: 'Montag',
    tue: 'Dienstag',
    wed: 'Mittwoch',
    thu: 'Donnerstag',
    fri: 'Freitag',
    sat: 'Samstag',
    sun: 'Sonntag',
    ciTimeRangesText1: 'Letzte 1 Stunde',
    ciTimeRangesText2: 'Letzter 1 Tag',
    ciTimeRangesText3: 'Letzten 7 Tage',
    ciTimeRangesText4: 'Letzte 30 Tage',
    ciTimeRangesText5: 'Letzten Monat',
    ciTimeRangesText6: 'Benutzerdefinierte Stunde',
    allConOperationText1: 'Größer als',
    allConOperationText2: 'Weniger als',
    allConOperationText3: 'Reichweite',
    allConOperationText4: 'Nicht in Reichweite',
    amount: '$ Menge',
    budget: '% Prozent des Budgets',
    incBy: 'Verringern um',
    decBy: 'Erhöhen um',
    setToCPA: 'Auf durchschnittlichen CPA einstellen',
    setToCPT: 'Auf durchschnittlichen CPT einstellen',
    value: '$ Wert',
    change: '% Veränderung',
  },

   //select Account AR
   selectAccountAR: {
    chooseAcc: 'Wählen Sie Konto',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    clearAll: 'Alles löschen',
    accounts: 'Konten',
    accountSelected: 'Account(s) ausgewählt',
  },

// select Apps AR
  selectAppsAR : {
    chooseApp : 'App auswählen',
    cancel : 'Stornieren',
    apply : 'Anwenden',
    apps : 'Apps',
    appSelected : 'App(s) ausgewählt',
    clearAll : 'Alles löschen',
  },

  //select Keywords AR
  selectKeywordsAR : {
    keepOneAdGroup : 'Bitte lassen Sie nur eine Anzeigengruppe ausgewählt, um fortzufahren.',
    close : 'Schließen',
    chooseKeywords : 'Schlüsselwörter auswählen',
    cancel : 'Stornieren',
    apply : 'Anwenden',
    campaigns : 'Kampagnen',
    expandAll : 'Alle erweitern',
    collapseAll : 'Alles ausblenden',
    adGroups : 'Anzeigengruppen',
    keywords : 'Schlüsselwörter',
    keywordSelected : 'Stichwort(e) ausgewählt',
    clearAll : 'Alles löschen',
  },

  //campaign
  //SelectCampaign
  selectCamp :{
    chooseCamp: 'Kampagne auswählen',
    noSelectedCamp : 'Keine ausgewählte Kampagne',
    campaigns : 'Kampagnen',
    expandAll : 'Alle erweitern',
    collapseAll : 'Alles ausblenden',
    selectedCampaigns : 'Ausgewählte Kampagnen',
    clearAll : 'alles löschen',
    cancel : 'Stornieren',
    apply : 'Anwenden',
      },

      //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Kreditkarte bearbeiten',
    cardNumber: 'Kartennummer',
    expiryDate: 'Verfallsdatum',
    cvv: 'CVV',
    saveCard: 'Karte speichern',
    updateExpiryPara: '* Sie können nur das Ablaufdatum aktualisieren',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Creative-Set bearbeiten',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Installieren',
    editCreativePara1:
      'Wählen Sie einen Namen, der das Thema Ihres Kreativsets beschreibt. Wählen Sie die Sprache der Screenshots und Vorschauen aus, die Sie auf Ihrer App Store-Produktseite hinzufügen möchten.',
    creativeSetName: 'Name des Creative-Sets',
    localLang: 'Lokalisierungssprache',
    assetSelection: 'Asset-Auswahl',
    assetSelectionPararaph1:
      'Kreativsets bestehen aus Screenshots und App-Vorschauen, die auf der App Store-Produktseite angezeigt werden.',
    assetSelectionPararaph2: 'Für jede iPhone-Displaygröße',
    assetSelectionPararaph3:
      'Wählen Sie mindestens ein Querformat oder drei Hochformat-Assets aus.',
    assetSelectionPararaph4: 'Für jede iPad-Displaygröße',
    assetSelectionPararaph5:
      'Wählen Sie mindestens ein Querformat oder zwei Hochformat-Assets aus.',
    viewDevice: 'Anzeigen von Gerätedisplaygrößen',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Anzeige',
    default: 'Standard',
    cancel: 'Stornieren',
    save: 'Speichern',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Integration löschen',
    cardText: 'Möchten Sie die Integration wirklich löschen?',
    cancel: 'Stornieren',
    delete: 'Löschen',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Schlagwörter hinzufügen',
    keywordSuggestionsFor: 'Keyword-Vorschläge für',
    on: 'an',
    addedKeywords: 'Schlagwörter hinzugefügt',
    total: 'Gesamt',
    add: 'Hinzufügen',
    cancel: 'Stornieren',
    apply: 'Anwenden',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Schlagwörter hinzufügen',
    selectTarget: 'Ziel auswählen',
    h4Camp: 'Kampagnen:',
    selected: 'Ausgewählt',
    change: 'Veränderung',
    h4Adgroups: 'Anzeigengruppen:',
    maxCPTBid: 'Max. CPT-Gebot:',
    keywordSuggestionsFor: 'Keyword-Vorschläge für',
    on: 'an',
    recommendedKeywords: 'Empfohlene Schlüsselwörter',
    addedKeywords: 'Schlagwörter hinzugefügt',
    add: 'Hinzufügen',
    total: 'Gesamt',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    ok: 'OK',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Schlagwörter hinzufügen',
    selectTarget: 'Ziel auswählen',
    h4Camp: 'Kampagnen:',
    selected: 'Ausgewählt',
    change: 'Veränderung',
    h4Adgroups: 'Anzeigengruppen:',
    maxCPTBid: 'Max. CPT-Gebot:',
    keywordSuggestionsFor: 'Keyword-Vorschläge für',
    on: 'an',
    recommendedKeywords: 'Empfohlene Schlüsselwörter',
    addedKeywords: 'Schlagwörter hinzugefügt',
    add: 'Hinzufügen',
    total: 'Gesamt',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    ok: 'OK',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Ausschließende Keywords hinzufügen',
    add: 'Hinzufügen',
    total: 'Gesamt',
    cancel: 'Stornieren',
    save: 'Speichern',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Gebotsbetrag',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Bitte lassen Sie nur eine Anzeigengruppe ausgewählt, um fortzufahren.',
    close: 'Schließen',
    chooseAdGroup: 'Anzeigengruppe auswählen',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    campaigns : 'Kampagnen',
    expandAll: 'Alle erweitern',
    collapseAll: 'Alles ausblenden',
    adGroups : 'Anzeigengruppen',
    adGroupSelected : 'Anzeigengruppe(n) ausgewählt',
    clearAll : 'Alles löschen'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Ausschließende Keywords hinzufügen',
    selectTarget : '1. Ziel auswählen',
    addNegKeyword : 'Ausschließendes Keyword hinzufügen',
    paragraph1 : 'Ausschließende Keywords können zu Kampagnen oder Anzeigengruppen hinzugefügt werden. Bitte wählen Sie eine davon aus dem unteren Feld aus.',
    paragraph2 : 'Für welche Kampagne möchten Sie Keywords hochladen?',
    selectCampaign : 'Kampagne auswählen',
    paragraph3 : 'Welche Anzeigengruppe möchten Sie Keywords hochladen?',
    selectAdgroup : 'Wählen Sie Anzeigengruppe',
    cancel: 'Stornieren',
    easywebinar : 'EasyWebinar', 
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Kampagnen:',
    selected : 'Ausgewählt',
    change: 'Veränderung',
    paragraph4 : ' Ausschließende Keywords für Anzeigengruppen gelten nur für diese Anzeigengruppe. Wenn ausschließende Keywords für alle Anzeigengruppen gelten sollen, legen Sie sie auf Kampagnenebene fest.',
    paragraph5 : ' Erwägen Sie, Kampagnen Zeit zu geben, bevor Sie die Anzeigenleistung analysieren, um herauszufinden, welche Keywords für Sie möglicherweise nicht funktionieren. Das Hinzufügen zu vieler ausschließender Keywords kann Ihre Anzeigenpräsenz einschränken.',
    addedKeywords : 'Schlagwörter hinzugefügt', 
    add: 'Hinzufügen',
    total: 'Gesamt',
    save: 'Speichern', 
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Slack-Channel hinzufügen',
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Stornieren',
ok : 'OK',
  },

  //HelloWorld
  helloWorld : {
welcome : 'Willkommen bei Vuetify',
para1 : 'Für Hilfe und Zusammenarbeit mit anderen Vuetify-Entwicklern,',
para2 : 'Bitte treten Sie unserem Online bei',
discord : 'Discord-Community',
h2 : "Was kommt als nächstes?",
impLinks : 'Wichtige Links',
ecosystem : 'Ökosystem',
  },

  //InfoAlert
  infoAlert : {
ok : 'OK',
  },
      
}
