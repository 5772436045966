























































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue'
import { GET_CHECKLIST } from '../utils/apptweakqueries'
import { decodeToken } from '@shared/utils/commom.util'
import { LSS } from '../core/services';
import io from 'socket.io-client';
import { API_WS_URL } from '@config'

export default Vue.extend({
  name: 'DataRequests',
  data() {
    return {
      open : false,
      socket: null,
      tasks: [],
      loadingCL: false,
      checkList: null,
      progress: 0
    }
  },
  props: {
    openDR: {
      type: Object,
      required: false,
    },
  },
  watch: {
    openDR: function (data) {
      this.open = true
    }
  },
  computed: {
    completedTasks () {
      return this.tasks.filter(task => task.done).length
    },
    // progress () {
    //   const p = this.completedTasks / this.tasks.length * 100
    //   this.$root.$emit('new-ds-progress', p)
    //   return p
    // },
    remainingTasks () {
      return this.tasks.length - this.completedTasks
    },
  },
  methods: {
    deleteTask(idx, done){
      if(!done) return
      this.tasks = this.tasks.filter((e,i) => i != idx)
      LSS.dataRequests = this.tasks
    },
    disableRest(n){
      const cnt = this.tasks.length
      for (let i = 0; i < n; i++) {
        this.tasks[i].disabled = false
      }
      for (let i = n; i < cnt; i++) {
        this.tasks[i].disabled = true
      }
    },
    taskClicked(task){
      this.open = false
      if(task.opId == 2){
        LSS.kaiQuery = {
          tab: 1,
          keyword: task.keyword
        }
        // this.$router.push({ name: 'keyword-auction-insights', path : task.path, query : { tab: task.query, keyword: task.keyword }})
      }
      else if(task.opId == 1){
        LSS.kaiQuery = {
          tab: 0,
          appId: task.appId,
        }
        // this.$router.push({ name: 'keyword-auction-insights', path : task.path, query : { tab: task.query, appid: task.appId, title: (task.text).substring(28)}})
      }
      else if(task.opId == 3){
        LSS.kaiQuery = {
          tab: 4,
          appId: task.appId,
        }
      }
      this.$router.push({path : task.path, query : { q : Date.now() }})
    },
    getCheckList(){
      this.loadingCL = true;
      this.$apollo
      .query({
        query: GET_CHECKLIST,
        variables: {
          userId: decodeToken(LSS.token).UserId
        }
      })
      .then(res => {
        console.log(res.data);
        this.loadingCL = false;
      })
      .catch(error => {
        this.loadingCL = false;
      });
    },
    calculateProgress(){
      let totalProgress = 0
      let cnt = 0
      this.tasks.map((x,i) => {
        if(!this.tasks[i].done) {
          totalProgress = totalProgress + this.tasks[i].progress
          cnt++
        }
      })
      if(cnt == 0) cnt = 1
      this.progress = totalProgress/cnt
      if(this.progress == 0 || this.progress === NaN) this.progress = 100
      const s = {
        progress: this.progress,
        existDR: true
      }
      //console.log(s)
      this.$root.$emit('new-ds-progress', s)
    },
  },
  mounted(){
    if(LSS.dataRequests){
      this.tasks = LSS.dataRequests
      this.$nextTick(() => {
        this.calculateProgress()
      })
    }

    //if(this.socket) this.socket.disconnect()
    if(!this.socket) this.socket = io(API_WS_URL)

    this.socket.on("connect", () => {
      console.log(this.socket.id);
    });

    this.socket.on("error", (error) => {
      if(this.socket) this.socket.disconnect()
      console.log('Socket error');
    });

    this.socket.on("disconnect", () => {
      //console.log('Client Disconnected');
    });

    this.socket.emit('msgToServer', 'Hello')

    this.socket.on('msgToClient', (message) => {
      console.log(message)
      let totalProgress = 0
      let cnt = 0
      this.tasks.map((x,i) => {
        //console.log(x)
        //console.log(this.tasks[i])
        if(x.clientId === message.clientId){
          if(message.opId === 2 && message.data !== 'over' && message.keyword == x.keyword){
            this.tasks[i].fetched = this.tasks[i].fetched + 5
            this.tasks[i].progress = this.tasks[i].progress + (500/this.tasks[i].total)
            if(this.tasks[i].fetched >= this.tasks[i].total){
              this.tasks[i].fetched = this.tasks[i].total
              this.tasks[i].done = true
              this.tasks[i].disabled = false
              this.tasks[i].progress = 100
              this.tasks[i].preText = 'Fetched'
            }
          }
          if(message.opId === 1 && message.data !== 'over' && message.appId == x.appId){
            this.tasks[i].fetched = this.tasks[i].fetched + 10
            this.tasks[i].progress = this.tasks[i].progress + (1000/this.tasks[i].total)
            if(this.tasks[i].fetched >= this.tasks[i].total){
              this.tasks[i].fetched = this.tasks[i].total
              this.tasks[i].done = true
              this.tasks[i].disabled = false
              this.tasks[i].progress = 100
              this.tasks[i].preText = 'Fetched'
            }
          }
          if(message.opId === 3 && message.data !== 'over' && message.appId == x.appId){
            this.tasks[i].fetched = this.tasks[i].fetched + 10
            this.tasks[i].progress = this.tasks[i].progress + (1000/this.tasks[i].total)
            if(this.tasks[i].fetched >= this.tasks[i].total){
              this.tasks[i].fetched = this.tasks[i].total
              this.tasks[i].done = true
              this.tasks[i].disabled = false
              this.tasks[i].progress = 100
              this.tasks[i].preText = 'Fetched'
            }
          }
        }
        if(message.data !== 'over' && !this.tasks[i].done) {
          totalProgress = totalProgress + this.tasks[i].progress
          cnt++
        }
      })
      if(cnt == 0) cnt = 1
      if(message.data !== 'over'){
        this.progress = totalProgress/cnt
        if(this.progress == 0) this.progress = 100
        const s = {
          progress: this.progress,
          existDR: true
        }
        this.$root.$emit('new-ds-progress', s)
      }
      LSS.dataRequests = this.tasks
    })

    this.$root.$on('addDataRequests', (adr) => {
      let exists = false
      // if(adr.opId == 2){
      //   exists = this.tasks.map(e => {
      //     if(e.keyword === adr.keyword) return true
      //   })
      // }
      if(!exists) this.tasks.unshift(adr)
      LSS.dataRequests = this.tasks
      this.calculateProgress()
    });
  }
});
