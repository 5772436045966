import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const options = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#017982', // dark blue
        secondary: '#d5b694', // black dark
        accent: '#302c4d', // purple
        error: '#FB3B3B', // red
        info: '#009AFF', // blue
        success: '#01CB63', // green
        warning: '#FF642E', // orange
        bluegrey: '#1F2532',
      },
    },
  },
})

export default new Vuetify(options)


