























































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { HttpService, LSS } from '@core/services';
import { SYNCSERVICE_URL } from '@config';
import { decodeToken } from '@shared/utils/commom.util';
import DataRequests from './DataRequests.vue';
import { LSS } from '../core/services';

import { bus } from '../main';
import { BusEvents } from '../utils/enums';

export default Vue.extend({
  name: 'navbar',
  components: {
    DataRequests
  },
  data() {
    return {
      existDR: false,
      triggerDR: null,
      drProgress: 0,
      syncing: false,
      isNavbarExpanded: false,
      drawer: true,
      mini: true,
      name: 'Ninja',
      locales: [
        { flag: 'us', text: 'EN', localeCode: 'en' },
        { flag: 'de', text: 'DE', localeCode: 'de' },
        { flag: 'es', text: 'ES', localeCode: 'es' },
        { flag: 'kp', text: 'KO', localeCode: 'ko' },
        { flag: 'cn', text: 'ZH', localeCode: 'zh' },
        { flag: 'jp', text: 'JA', localeCode: 'ja' },
      ],
      selectedLocale: { flag: 'us', text: 'EN', localeCode: 'en' },
    };
  },
  methods: {
    openDataRequests() {
      //console.log('openDataRequests')
      this.triggerDR = {
        now: Date.now(),
      };
    },
    toggled(ev) {
      this.isNavbarExpanded = !ev;
    },
    switchLocale(locale) {
      this.selectedLocale = locale;
      this.$root.$i18n.locale = locale.localeCode;
      localStorage.setItem('locale', locale.localeCode);
      this.$root.$emit('locale-changed', locale)
    },
    async pullData() {
      this.syncing = true;
      try {
        await HttpService.get(
          SYNCSERVICE_URL + decodeToken(LSS.token).TeamID + '/manual'
        );

        bus.$emit(BusEvents.DATA_PULLED);
      } catch (err) {
        console.log(err);
      }

      this.syncing = false;
    },
    menuClick(path) {
      this.$router.push({ path: path });
    },
    calculateProgress() {
      if(!LSS.token || !decodeToken(LSS.token).checkList){
        this.existDR = false
        return
      }else{
        this.existDR = true
      }
      if (LSS.token) {
        const s = decodeToken(LSS.token).checkList;
        const a = [
          'saIntegration',
          'kaiComp',
          'kaiRecom',
          'createCampaign',
          'adsManager',
          'overviewPage',
        ];
        let tCnt = 0;
        let fCnt = 0;
        Object.keys(s).map((x) => {
          if (a.includes(x)) {
            if (s[x]) tCnt++;
            else fCnt++;
          }
        });
        const p = (tCnt / a.length) * 100;
        this.drProgress = p;
      }
    },
    checkDR(){
      if(LSS.dataRequests){
        if(LSS.dataRequests.length > 0) this.existDR = true
      }
    }
  },

  computed: {
    isNavbar() {
      return ![
        'Login',
        'onboard-fork',
        'onboard',
        'signin',
        'signup',
        'logout',
        'verify',
        'forgot-password',
        'change-password',
        'sa-integration',
      ].includes(this.$route.name);
    },
    items: {
      cache: false,
      get() {
        const items = [
          {
            title: this.$t('pages.overview'),
            icon: 'mdi-apps',
            path: '/overview',
            tooltip: this.$t('pages.overview'),
          },
          {
            title: this.$t('pages.adsManager'),
            icon: 'mdi-view-dashboard',
            path: '/ads-manager',
            tooltip: this.$t('pages.adsManager'),
          },
          {
            title: this.$t('pages.kai'),
            icon: 'mdi-keyboard',
            path: '/keyword-auction-insights',
            tooltip: this.$t('pages.kai'),
          },
          {
            title: this.$t('pages.keyOpt'),
            icon: 'mdi-pencil-box-multiple',
            path: '/keyword-optimization',
            tooltip: this.$t('pages.keyOpt'),
          },
          {
            title: this.$t('pages.integrations'),
            icon: 'mdi-link-box-variant',
            path: '/integrations',
            tooltip: this.$t('pages.integrations'),
          },
          {
            title: this.$t('pages.automationRule'),
            icon: 'mdi-ballot',
            path: '/arrules',
            tooltip: this.$t('pages.automationRule'),
          },
          //TODO - Language
          {
            title: 'App Settings',
            icon: 'mdi-table-cog',
            path: '/app-settings',
            tooltip: 'App Settings',
          },
        ];

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'ADMIN') {
            items.push(...this.adminItems);
          }
        }

        return items;
      },
      set() {
        const s = 5;
      },
    },
    adminItems: {
      cache: false,
      get() {
        return [
          {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
          },
        ];
      },
    },
    menuitems: {
      cache: false,
      get() {
        return [
          {
            title: this.$t('menu.settings'),
            icon: 'mdi-account',
            path: '/settings',
          },
          {
            title: this.$t('menu.signOut'),
            icon: 'mdi-logout',
            path: '/logout',
          },
        ];
      },
    },
  },
  mounted() {
    if (decodeToken(LSS.token)) {
      const fname = decodeToken(LSS.token).FirstName || 'S';
      const lname = decodeToken(LSS.token).LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
    }
    if (decodeToken(LSS.token)) {
      if (decodeToken(LSS.token).Role == 'ADMIN') {
        this.items = [
          {
            title: this.$t('pages.overview'),
            icon: 'mdi-apps',
            path: '/overview',
            tooltip: this.$t('pages.overview'),
          },
          {
            title: this.$t('pages.adsManager'),
            icon: 'mdi-view-dashboard',
            path: '/ads-manager',
            tooltip: this.$t('pages.adsManager'),
          },
          {
            title: this.$t('pages.kai'),
            icon: 'mdi-eye',
            path: '/keyword-auction-insights',
            tooltip: this.$t('pages.kai'),
          },
          {
            title: this.$t('pages.keyOpt'),
            icon: 'mdi-pencil-box-multiple',
            path: '/keyword-optimization',
            tooltip: this.$t('pages.keyOpt'),
          },
          {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
          },
          {
            title: this.$t('pages.integrations'),
            icon: 'mdi-link-box-variant',
            path: '/integrations',
            tooltip: this.$t('pages.integrations'),
          },
          {
            title: this.$t('pages.automationRule'),
            icon: 'mdi-ballot',
            path: '/arrules',
            tooltip: this.$t('pages.automationRule'),
          },
          //TODO - Language
          {
            title: 'App Settings',
            icon: 'mdi-table-cog',
            path: '/app-settings',
            tooltip: 'App Settings',
          },
        ];
      }
    }
    this.$root.$on('userDetailsUpdated', (userDetails) => {
      const fname = userDetails.FirstName || 'S';
      const lname = userDetails.LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
    });
    this.$root.$on('userNameFetched', (userDetails) => {
      const fname = userDetails.FirstName || 'S';
      const lname = userDetails.LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      // this.calculateProgress()
    });
    // this.calculateProgress()
    this.$root.$on('new-ds-progress', (p) => {
      this.drProgress = p.progress
      this.existDR = p.existDR
    });

    if (localStorage.getItem('locale')) {
      for (const locale of this.locales) {
        if (locale.localeCode == localStorage.getItem('locale')) {
          this.selectedLocale = locale;
        }
      }
    }
  },
});
