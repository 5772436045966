//Japanese

export const ja = {
  pages: {
    overview: '概要概要',
    adsManager: 'アドマネージャー',
    kai: 'キーワード分析',
    keyOpt: 'キーワードの最適化',
    teamMgmt: 'チーム',
    integrations: '統合',
    automationRule: 'オートメーション'
  },
  menu: {
    settings: '設定',
    signOut: 'サインアウト'
  },
  buttons: {
    syncData: '最新のデータを同期する',
    createCampaign: 'キャンペーンを作成する',
    filter: 'フィルタ',
    reset: 'リセット',
    apply: '適用する'
  },
  labels: {
    charts: 'チャート',
    selectDs: 'データセットを選択',
    totalSummary: 'まとめ',
    spend: '費やす'
  },
  dropdowns: {
    noData: 'データなし',
    daily: '毎日',
    weekly: '毎週',
    monthly: '毎月'
  },
  tabs: {
    accounts: 'アカウント',
    apps: 'アプリ',
    campaigns: 'キャンペーン',
    adgroups: '広告グループ',
    keywords: 'キーワード',
    creativeSets: 'クリエイティブセット',
    searchTerms: '検索ワード',
    negKeywords: '除外キーワード'
  },
  summary: {
    taps: 'タップ',
    impressions: '印象',
    ttr: 'TTR',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    installs: 'インストール',
  },
  accountHeaders: {
    account: 'アカウント',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    ttr: 'TTR',
    conversionRate: '変換速度',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード'
  },
  appsHeaders: {
    appName: '応用',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstall: 'Attrインストール',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    goalsRoas: 'ゴールROAS',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    account: 'アカウント',
    categoryRank: 'カテゴリランク',
    appId: 'アプリID',
  },
  campaignHeaders: {
    campaign: '運動',
    campaignStatus: 'キャンペーンステータス',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRoas: 'ゴールROAS',
    storefronts: '店先',
    account: 'Account',
    displayStatus: 'ステータスの表示',
    appName: 'アプリ名',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    delete: '削除'
  },
  campaignDraftHeaders: {
    campaignName: 'キャンペーン名',
    countries: '国',
    budgetAmount: '予算額',
    resume: '履歴書',
  },
  adGroupHeaders: {
    adgroup: '広告グループ',
    adgroupStatus: '広告グループのステータス',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRoas: 'ゴールROAS',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    displayStatus: 'ステータスの表示',
    account: 'アカウント',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID'
  },
  keyHeaders: {
    keyword: 'キーワード',
    keywordStatus: 'キーワードステータス',
    bidAmount: '入札額',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    organicRank: 'オーガニックランク',
    appName: 'アプリ名',
    difficultyScore: '難易度スコア',
    popularity: '人気',
    keywordId: 'キーワードID',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID',
    suggestedMinBid: 'ASAが提案する最小入札額',
    suggestedMaxBid: 'ASAが推奨する最大入札額',
    bidStrength: '入札の強さ'
  },
  creativeHeaders: {
    creativeSet: 'クリエイティブセット',
    creativeSetStatus: '状態',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    account: 'アカウント',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID'
  },
  searchTermsHeader: {
    searchTerm: '検索用語テキスト',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    ttr: 'TTR',
    conversionRate: '変換速度',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    keyword: 'キーワード',
    keywordId: 'キーワードID',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID'
  },
  negativeHeaders: {
    keyword: 'キーワード',
    matchType: 'マッチタイプ',
    status: '状態',
    adgroupId: 'アドグループID',
    campaignId: 'キャンペーンID',
    deleted: '削除されました',
    keywordId: 'キーワードID',
    modificationTime: '変更時間'
  },
  filter: {
    account: 'アカウントを選択',
    app: 'アプリを選択',
    campaign: 'キャンペーンを選択',
    adgroup: '広告グループを選択',
  },
  subTabs: {
    live: '住む',
    paused: '一時停止',
    deleted: '削除されました',
    draft: 'ドラフト',
    campaigns: 'キャンペーン',
    adgroups: 'アドグループ',
    keywords: 'キーワード',
    creativeSet: 'クリエイティブセット',
    negKey: '除外キーワード'
  },
  popups: {
    add: '追加',
    integration: '統合',
    adgroup: '広告グループ',
    keywords: 'キーワード',
    creativeSet: 'クリエイティブセット',
    negKey: '除外キーワード'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: 'ユーザーの管理',
    invite: 'チームメンバーの招待と管理',
    add: 'メンバーを追加',
    first: 'ファーストネーム',
    last: '苗字',
    phone: '電話',
    email: 'Eメール',
    job: '職名',
    allMembers: '全員',
    processing: '処理...',
    ok: 'OK',
    inviteStatus: 'ステータスを招待',
    isDisabled: '無効になっています',
    company: '会社名'
  },

  //Add Integration Page
  integration: {
    assPart: '関連パートナー',
    apple: 'Apple検索広告',
    slack: 'スラック',
    itunes: 'iTunes',
    tenjin: '天神',
    kochava: 'コチャバ',
    adjust: '調整する',
    appsFlyer: 'AppsFlyer',
    singular: '特異な'
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: 'キーワードを追加',
    total: '合計',
    cancel: 'キャンセル',
    runAds: '広告を実行する',
    ok: 'OK',
    totalPaidKey: '総有料キーワード',
    popularity: '人気',
    numOfApps: 'アプリの数',
    chance: '機会',
    appsRunAds: '広告を実行しているアプリ',
    maxPop: 'あなたのための最大の人気',
    keyword: 'キーワード',
    clear: '晴れ',
    clrAllFilter: 'すべてのフィルターをクリア',
    keywords: 'キーワード',
    organicRank: 'オーガニックランク',
    totalApps: '総アプリ',
    app: 'アプリ',
    catName: '種別名',
    searchByApp: 'アプリで検索',
    searchByKey: 'キーワードで探す',
    addAllKey: 'すべてのキーワードを追加'
  },

  //Overview Page
  overview: {
    period: '限目',
    topKeywords: 'トップ10キーワード',
    key: 'キーワード',
    spend: '費やす',
    impressions: '印象',
    taps: 'タップ',
    goToKey: 'すべてのキーワードに移動',
    topAdgroup: '上位10のAdGroups',
    adgroup: 'AdGroup',
    goToAdgroup: 'すべての広告グループに移動',
    gender: '性別',
    device: '端末',
    searchApp: '検索アプリ',
    lastDay: '最終日',
    last7Days: '過去7日間',
    last30Days: '過去30日間',
    ttr: 'タップスルー率（TTR）',
    installs: 'インストール',
    avgCPA: '平均CPA',
    avgCPT: '平均CPT',
    conversionRate: '変換速度 (CR)',
    latOnInstalls: 'インストール時のLAT',
    latOffInstalls: 'LATオフインストール',
    reDownloads: '再ダウンロード',
    newDownloads: '新しいダウンロード',
    all: 'すべて',
    tooltip: '前の期間に応じたパーセンテージとしてデータの差異を表示します。 前の期間は前に選択した日付範囲です'
  },

  //Keyword Optimization Page
 keyOpt: {
  keyBid: 'キーワード入札の最適化',
  keyToolTip: 'ASAの推奨最小入札額とASAの推奨最大入札額の範囲外のキーワードの入札額を最適化できます。',
  optAll: 'すべてを最適化',
  opt: '最適化',
  optBidAmt: '入札額の最適化',
  key: 'キーワード',
  optBidForAll: 'すべてのキーワードの入札額を最適化する',
  campaign: '運動',
  adgroup: '広告グループ',
  bidAmt: '入札額',
  asaMinBid: 'ASAが提案する最小入札額',
  asaMaxBid: 'ASAが推奨する最大入札額',
  action: 'アクション'
  },
  
  //ARRules Page
  aRules: {
  createAR: '自動化ルールの作成',
  view: '見る',
  edit: '編集',
  delete: '削除',
  disable: '無効にする',
  enable: '有効にする',
  name: '名前',
  createdAt: '作成場所',
  status: '状態',
  completed: 'で完了',
  actionFreq: 'アクション頻度',
  rules: 'ルール',
  logs: 'ログ',
  createdFor: 'のために作成されました',
  chckFreq: '頻度を確認する',
  actions: '行動'
  },
 
  //Create Campaign
  //CampaignView
  createCampaign: {
  createCamp: 'キャンペーンを作成する',
  h2: 'キャンペーンの作成を開始するアプリを選択してください',
  para: 'キャンペーンには、アプリを宣伝するための共通のテーマや戦略に焦点を当てた予算と広告グループがあります。',
  select: '広告を掲載する場所を選択する',
  searchResults: 'の検索結果',
  srData: 'キャンペーンには、アプリを宣伝するための共通のテーマや戦略に焦点を当てた予算と広告グループがあります。',
  searchTab: '検索タブ',
  stData: 'ユーザーが[検索]タブにアクセスしたときに、おすすめのアプリリストの上部にアプリを宣伝します。',
  cancel: 'キャンセル',
  continue: '継続する',
  
  //Search Results and Search Tab Campaign
  countAndReg: '国と地域',
  adgroupSettings: '広告グループの設定',
  searchMatch: '検索一致',
  key: 'キーワード',
  audience: '聴衆',
  crSets: 'クリエイティブセット',
  chooseCampGroup: 'キャンペーングループを選択する',
  chooseApp: 'アプリを選択してください',
  next: '次',
  countPara: 'アプリは、リストから選択した対象の国と地域のAppStoreで宣伝されます。',
  chooseCount: '1つ以上の国または地域を選択してください',
  campName: 'キャンペーン名',
  budget: '予算',
  dailyCap: '1日あたりの上限（オプション）',
  adgroupPara: '広告グループには、広告を表示する相手を決定する価格設定の目標と設定が含まれています。',
  adgroupName: '広告グループ名',
  devices: 'デバイス',
  startTime: '始まる時間',
  adScheduling: '広告のスケジューリング',
  endTime: '終了時間',
  dayParting: '昼間（オプション）',
  ok: 'OK',
  defaultMax: 'デフォルトの最大CPT入札',
  cpaGoal: 'CPAの目標（オプション）',
  saveDraft: '下書きとして保存',
  appleSearchMatch: 'アップルサーチマッチ',
  searchMatchPara: 'Apple Search Matchは、広告を掲載するための最も簡単な方法です。 検索広告は、あなたのようなアプリを検索しているユーザーに広告を自動的に照合します。',
  searchMatchPara1: '広告を関連する検索に自動的に一致させる',
  keywords: 'キーワード',
  keyPara: 'キーワードは、顧客があなたのようなアプリを検索するときに使用する可能性のある関連する単語または用語です。 キーワードを使用すると、検索者はアプリをすばやく見つけることができ、アプリのインストールを促進できます。',
  upload: 'アップロード',
  suppFormat: 'サポートされている形式：Excel、CSV',
  keyManually: 'または手動でキーワードを追加します',
  targKey: 'ターゲティングキーワード',
  clear: '晴れ',
  adgroupNegKey: '広告グループの除外キーワード',
  optional: 'オプション',
  negKey: '除外キーワード',
  audPara: ' オプションの高度な機能を使用して、広告グループのオーディエンスを絞り込みます。',
  custType: '顧客タイプ',
  demo: '人口統計',
  gender: '性別',
  location: 'ロケーション',
  crSetPara: '広告は、App Storeの製品ページで提供されるメタデータ、スクリーンショット、アプリのプレビューを使用して自動的に作成されます。 これらは、AppStoreのオーガニック検索結果にも表示されます。 ここに表示される広告は、デフォルトの広告例です。',
  crOptional: 'クリエイティブセット（オプション）',
  noCrSet: 'アプリが最小クリエイティブセットの要件を満たしていません',
  campCreated: '作成されたキャンペーン',
  campCreatedPara: 'これで、検索広告ダッシュボードにキャンペーンを表示できます',
  close: '閉じる',
  matchType: 'マッチタイプ',
  amount: '量',
  currency: '通貨',
  allUsers: '全てのユーザー',
  newUsers: '新規ユーザー',
  appUsers: '他のアプリのユーザー',
  existingUsers: '既存のユーザー',
  all: 'すべて',
  male: '男性',
  female: '女性'
  },

  //Change Password
  changePass: {
    backSett: '設定に戻る',
    chngPass: 'パスワードを変更する',
    newPass: '新しいパスワード',
    confirmPass: 'パスワードを認証する',
    submit: '参加する',
    h4: 'KochavaMarketersオペレーティングシステム™',
    para:
      'マーケターオペレーティングシステム（m / OS）は、広告主とパブリッシャー向けのオムニチャネルマーケティングソリューションを1つの運用プラットフォームにシームレスに統合します。',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'パスワードをお忘れですか',
    typeEmail: 'メールアドレスを入力してください',
    emailAdd: '電子メールアドレス',
    submit: '参加する',
    chckEmail: 'メールを確認してください',
    para: '新しいパスワードを設定するには、メールアドレスを確認してください',
    close: '閉じる',
    alreadyAcc: 'すでにアカウントをお持ちですか？',
    signIn: 'サインイン',
  },

  //Onboard
  onboard: {
    welcome: 'コチャバへようこそ',
    personal: '個人',
    start: '開始',
    integration: '統合',
    next: '次',
    trial: 'トライアルを開始する',
    scheduleDemo: 'または、デモをスケジュールして一緒に発見してください',
    addInt: '統合を追加します。',
    signInApple:
      'Appleにサインインして、Apple SearchAds統合を追加します。 ご注意ください：',
    step1:
      '1. Apple Search Adsアカウントへのアクセスを許可するには、管理者権限が必要です。',
    step2:
      '2. SearchAdsNinjaからキャンペーンを管理するには、アクセスを許可する前に[読み取りと書き込み]オプションを選択してください。',
    skip: 'スキップ',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Adgroupを追加する',
    para:
      'キャンペーンをまだ選択していません。 下のボックスからお選びいただけます。',
    camGroup: 'キャンペーングループ',
    campaign: '運動',
    adgroupSett: '広告グループの設定',
    para1:
      '広告グループには、広告を表示する相手を決定する価格設定の目標と設定が含まれています。',
    adgroupName: '広告グループ名',
    devices: 'デバイス',
    adScheduling: '広告のスケジューリング',
    startTime: '始まる時間',
    endTime: '終了時間',
    dayparting: '昼間（オプション）',
    defaultMax: 'デフォルトの最大CPT入札',
    cptTool:
      'これは、追加をタップするために支払うことができる最大金額です。この入札は、個別のキーワード入札を適用しない限り、この広告グループのすべてのキーワードに適用されます。',
    cpaGoal: 'CPAの目標（オプション）',
    cpaTool:
      'ダウンロードごとに支払った平均。 これは、費やされた合計をダウンロード数で割ることによって計算されます。',
    searchMatch: '検索一致',
    searchTool:
      '検索一致は、広告を掲載するための最も簡単な方法です。 検索広告は、あなたのようなアプリを検索しているユーザーに広告を自動的に照合します。',
    searchPara: '広告を関連する検索に自動的に一致させる',
    key: 'キーワード',
    keyPara:
      'キーワードは、顧客があなたのようなアプリを検索するときに使用する可能性のある関連する単語または用語です。 キーワードを使用すると、検索者はアプリをすばやく見つけることができ、アプリのインストールを促進できます。',
    inputCpt: 'キーワードセクションを有効にするには、デフォルトの最大CPT入札単価を入力します',
    addKeyPara:
      'アプリとジャンルに関連するキーワードを追加します。 追加の推奨事項を共有することで支援します。',
    upload: 'アップロード',
    addKeyManually: 'または手動でキーワードを追加します',
    negKey: '広告グループの除外キーワード（オプション）',
    supp: 'サポートされている形式：Excel、CSV',
    demAge: '人口統計年齢、性別',
    demPara: "ユーザーの年齢や性別に基づいて広告をターゲティングする",
    ageRange: '年齢層',
    cancel: 'キャンセル',
    apply: '適用する',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: '広告グループを編集する',
    adgroup: 'アドグループ',
    advance: '詳細設定',
    viewEdit: '表示と編集',
    general: '一般',
    settings: '設定',
    schedule: 'スケジュール',
    custPara: 'リーチしたいアプリの顧客を選択します',
    selectAge: '年齢範囲を選択',
    save: 'セーブ',
  },

  //Create AR
  createAR: {
    createAutoRule: '自動化ルールの作成',
    editAutoRule: '自動化ルールの編集',
    choose: '選択',
    chooseAutoRule: '自動化レベルと項目を選択してください。',
    conditions: '条件',
    actions: '行動',
    settings: '設定',
    condition: '状態',
    addCondition: '条件を追加',
    andCondition:
      '上記のすべての条件が一致する必要があるか、そのうちの1つだけが一致する必要があるかを選択します',
    checkCond: 'の条件を確認してください',
    action: 'アクション',
    selectAll: 'すべて選択',
    enterEmail: '適切なメールIDを入力してください',
    addTo: '追加',
    selectAdgroups: 'AdGroupsを選択します',
    selectCampaigns: 'キャンペーンを選択',
    cptBid: 'CPT入札',
    customBid: 'カスタム入札',
    matchType: 'マッチタイプ',
    addAction: 'アクションを追加',
    chckFreq: '頻度を確認する',
    ruleName: 'ルール名',
    cancel: 'キャンセル',
    step2: 'ステップ2',
    step3: 'ステップ3',
    andOrTypesText1: 'すべて一致する必要があります',
    andOrTypesText2: '1つだけ一致する必要があります',
    addToModelListNegText1: '選択したすべての広告グループ',
    addToModelListNegText2: '選択したキャンペーンのすべての広告グループ）',
    addToModelListNegText3: '選択したすべてのキャンペーン',
    matchTypesText1: '部分一致',
    matchTypesText2: '完全に一致',
    cptBidItemsText1: 'カスタムに設定',
    cptBidItemsText2: 'AdGroupのデフォルトの入札',
    actionFreqsText1: '毎時',
    actionFreqsText2: '6時間ごと',
    actionFreqsText3: '12時間ごと',
    actionFreqsText4: '毎日',
    actionFreqsText5: 'カスタム時間',
    limitActionFreqsText1: '2時間ごと',
    limitActionFreqsText2: '3時間ごと',
    limitActionFreqsText3: '2日ごと',
    limitActionFreqsText4: '3日ごと',
    limitActionFreqsText5: '毎週',
    mon: '月曜',
    tue: '火曜日',
    wed: '水曜日',
    thu: '木曜日',
    fri: '金曜日',
    sat: '土曜日',
    sun: '日曜日',
    ciTimeRangesText1: '過去1時間',
    ciTimeRangesText2: '過去1日',
    ciTimeRangesText3: '過去7日間',
    ciTimeRangesText4: '過去30日間',
    ciTimeRangesText5: '先月',
    ciTimeRangesText6: 'カスタムアワー',
    allConOperationText1: 'より大きい',
    allConOperationText2: '未満',
    allConOperationText3: '範囲',
    allConOperationText4: '範囲外',
    amount: '$金額',
    budget: '予算の％パーセント',
    incBy: 'によって減少します',
    decBy: '増加する',
    setToCPA: '平均CPAに設定',
    setToCPT: '平均CPTに設定',
    value: '$値',
    change: '％ 変化する',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'アカウントを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    clearAll: 'すべてクリア',
    accounts: 'アカウント',
    accountSelected: '選択したアカウント',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'アプリを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    apps: 'アプリ',
    appSelected: '選択したアプリ',
    clearAll: 'すべてクリア',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: '続行するには、1つの広告グループのみを選択したままにしてください。',
    close: '閉じる',
    chooseKeywords: 'キーワードを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    campaigns: 'キャンペーン',
    expandAll: 'すべて展開',
    collapseAll: 'すべて折りたたむ',
    adGroups: '広告グループ',
    keywords: 'キーワード',
    keywordSelected: '選択したキーワード',
    clearAll: 'すべてクリア',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'クリエイティブセットを追加',
    setup: 'セットアップ',
    para:
      'クリエイティブセットのテーマを説明する名前を選択してください。 App Storeの製品ページから、含めるスクリーンショットとプレビューの言語を選択します。',
    createSetName: 'クリエイティブセット名 ',
    localLang: 'ローカリゼーション言語',
    assetSelection: '資産の選択',
    assetSelectionPararaph1:
      'クリエイティブセットは、AppStoreの製品ページに表示されるスクリーンショットとアプリのプレビューで構成されています。',
    assetSelectionPararaph2: 'iPhoneのディスプレイサイズごとに、',
    assetSelectionPararaph3:
      '少なくとも1つの横向きまたは3つの縦向きのアセットを選択します。',
    assetSelectionPararaph4: 'iPadのディスプレイサイズごとに、',
    assetSelectionPararaph5:
      '少なくとも1つの横向きまたは2つの縦向きのアセットを選択します。',
    title1: 'iPhone/iPod',
    screenshotsPara1: '少なくとも選択してください',
    screenshotsPara2: '1つの風景',
    screenshotsPara3: 'または',
    screenshotsPara4: '2つの肖像画',
    screenshotsPara5: 'スクリーンショット！',
    screenshotsPara6: '少なくとも選択してください',
    screenshotsPara7: '1つの風景',
    screenshotsPara8: 'または',
    screenshotsPara9: '3つの肖像画',
    screenshotsPara10: 'スクリーンショット！',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'キャンペーンを削除する',
    para: 'キャンペーンを削除してもよろしいですか？',
    cancel: 'キャンセル',
    delete: '削除',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'キャンペーン編集ウィザード',
    campName: 'キャンペーン名',
    app: 'アプリ',
    countriesOrRegionsPara: '1つ以上の国または地域を選択してください',
    budget: '予算',
    dailyCap: 'デイリーキャップ',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'キャンペーンを選択',
    noSelectedCamp: '選択されたキャンペーンはありません',
    campaigns: 'キャンペーン',
    expandAll: 'すべて展開',
    collapseAll: 'すべて折りたたむ',
    selectedCampaigns: '選択したキャンペーン',
    clearAll: 'すべてクリア',
    cancel: 'キャンセル',
    apply: '適用する',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'クレジットカードを編集する',
    cardNumber: 'カード番号',
    expiryDate: '有効期限',
    cvv: 'CVV',
    saveCard: 'セーブ カード',
    updateExpiryPara: '*有効期限のみ更新できます',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'クリエイティブセットの編集',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'セットアップ',
    editCreativePara1:
      'クリエイティブセットのテーマを説明する名前を選択してください。 App Storeの製品ページから、含めるスクリーンショットとプレビューの言語を選択します。',
    creativeSetName: 'クリエイティブセット名',
    localLang: 'ローカリゼーション言語',
    assetSelection: '資産の選択',
    assetSelectionPararaph1:
      'クリエイティブセットは、AppStoreの製品ページに表示されるスクリーンショットとアプリのプレビューで構成されています。',
    assetSelectionPararaph2: 'iPhoneのディスプレイサイズごとに、',
    assetSelectionPararaph3:
      '少なくとも1つの横向きまたは3つの縦向きのアセットを選択します。',
    assetSelectionPararaph4: 'iPadのディスプレイサイズごとに、',
    assetSelectionPararaph5:
      '少なくとも1つの横向きまたは2つの縦向きのアセットを選択します。',
    viewDevice: 'デバイスの表示サイズを表示する',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: '表示',
    default: 'デフォルト',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: '統合の削除',
    cardText: '統合を削除してもよろしいですか？',
    cancel: 'キャンセル',
    delete: '削除',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'キーワードを追加',
    keywordSuggestionsFor: 'のキーワード提案',
    on: 'オン',
    addedKeywords: 'キーワードを追加',
    total: '合計',
    add: '追加',
    cancel: 'キャンセル',
    apply: '適用する',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'キーワードを追加',
    selectTarget: 'ターゲットを選択',
    h4Camp: 'キャンペーン：',
    selected: '選択済み',
    change: '変化する',
    h4Adgroups: '広告グループ:',
    maxCPTBid: '最大CPT入札：',
    keywordSuggestionsFor: 'のキーワード提案',
    on: 'オン',
    recommendedKeywords: 'おすすめのキーワード',
    addedKeywords: 'キーワードを追加',
    add: '追加',
    total: '合計',
    cancel: 'キャンセル',
    apply: '適用する',
    ok: 'OK',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'キーワードを追加',
    selectTarget: 'ターゲットを選択',
    h4Camp: 'キャンペーン：',
    selected: '選択済み',
    change: '変化する',
    h4Adgroups: '広告グループ:',
    maxCPTBid: '最大CPT入札：',
    keywordSuggestionsFor: 'のキーワード提案',
    on: 'オン',
    recommendedKeywords: 'おすすめのキーワード',
    addedKeywords: 'キーワードを追加',
    add: '追加',
    total: '合計',
    cancel: 'キャンセル',
    apply: '適用する',
    ok: 'OK',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: '除外キーワードを追加する',
    add: '追加',
    total: '合計',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: '入札額',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: '続行するには、1つの広告グループのみを選択したままにしてください。',
    close: '閉じる',
    chooseAdGroup: '広告グループを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    campaigns : 'キャンペーン',
    expandAll: 'すべて展開',
    collapseAll: 'すべて折りたたむ',
    adGroups : '広告グループ',
    adGroupSelected : '選択されたAdGroup',
    clearAll : 'すべてクリア'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : '除外キーワードを追加する',
    selectTarget : '1. ターゲットを選択',
    addNegKeyword : '除外キーワードを追加する',
    paragraph1 : '除外キーワードは、キャンペーンまたは広告グループに追加できます。 下のボックスからお選びください。',
    paragraph2 : 'どのキャンペーンにキーワードをアップロードしますか？',
    selectCampaign : 'キャンペーンを選択',
    paragraph3 : 'どの広告グループにキーワードをアップロードしますか？',
    selectAdgroup : '広告グループを選択',
    cancel: 'キャンセル',
    easywebinar : 'EasyWebinar', 
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'キャンペーン：',
    selected : '選択済み',
    change: '変化する',
    paragraph4 : ' 広告グループの除外キーワードは、この広告グループにのみ適用されます。 除外キーワードをすべての広告グループに適用する場合は、キャンペーンレベルで設定します。',
    paragraph5 : ' 広告の掲載結果を分析する前にキャンペーンを実行する時間を与えて、どのキーワードが効果的でないかを確認することを検討してください。 追加する除外キーワードが多すぎると、広告の露出が制限される可能性があります。',
    addedKeywords : 'キーワードを追加', 
    add: '追加',
    total: '合計',
    save: 'セーブ', 
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Slackチャネルを追加する',
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'キャンセル',
ok : 'OK',
  },

  //HelloWorld
  helloWorld : {
welcome : 'Vuetifyへようこそ',
para1 : '他のVuetify開発者とのヘルプとコラボレーションについては、',
para2 : 'オンラインでご参加ください',
discord : '不和コミュニティ',
h2 : "次は何ですか？",
impLinks : '重要なリンク',
ecosystem : '生態系',
  },

  //InfoAlert
  infoAlert : {
ok : 'OK',
  },
}

