import gql from 'graphql-tag';

export const APPLICATION_INSIGHTS = gql`query getAppsForKeyword($keyword: String!, $startDate: String!, $endDate: String!,$location: String!,$language: String!,$device: String!){
  getApplications:   BiddingApps(
  inputs: {
      keyword : $keyword
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
    }){
    keyword
    datetime
    apps {
      application_id
      application_details {
        icon
        title
        genres
      }
    }
  }
}`;


export const KEYWORD_INFO = gql`query getInfoForKeyword($keyword: String!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!){
  getKeywordInfo:   KeywordStat(
    inputs: {
      keyword : $keyword
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
    }){
    keyword
    content
  }
}`;


export const KEYWORD_RANKINGS = gql`query getRankingsForKeyword($keyword: String!, $startDate: String!, $endDate: String!, $appIds: [Float!]!,$location: String!,$language: String!,$device: String!){
  getKeywordRankings:     KeywordRankings(
  inputs: {
      keyword : $keyword
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
    	applications: $appIds
    }){
    keyword
    appid
    rank
  }
}`;


export const APP_KEYWORDS = gql`query getKeywordsForApp($appIds: [Float!]!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!){
  getAppKeywords:   AppKeywords(
    inputs: {
      appId : $appIds
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
    }){
    appid
    count
  }
}`;


export const APP_KEYWORDS_FULL = gql`query getKeywordsForAppFull($appIds: [Float!]!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!){
  getAppKeywordsFULL:   AppKeywords(
    inputs: {
      appId : $appIds
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
    }){
    appid
    count
    keywords {
      ranking
      keyword
    }
  }
}`;


export const SEARCH_BY_APP= gql`query getSearchByApp($appId: Float!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!,$currPage: Float!,$itemsPerPage: Float!,$opId: Float!,$clientId: Float!){
  searchByApp:   searchByApp(
  inputs: {
      appId : $appId
      opId : $opId
      clientId : $clientId
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
      currPage : $currPage
    	itemsPerPage : $itemsPerPage
    }){
      details
      keywords{
        name
        popularity
        totalApps
        organicRank
      }
      currentPage
      nextPage
      prevPage
      count
      fromDB
    }
}`;


export const GET_RECOM_LIST= gql`query getRecommendationList($appId: Float!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!,$currPage: Float!,$itemsPerPage: Float!,$opId: Float!,$clientId: Float!){
  getRecommendationList:   getRecommendationList(
  inputs: {
      appId : $appId
      opId : $opId
      clientId : $clientId
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
      currPage : $currPage
    	itemsPerPage : $itemsPerPage
    }){
      details
      keywords{
        name
        popularity
        totalApps
        totalDownloads
      }
      currentPage
      nextPage
      prevPage
      count
      fromDB
    }
}`;


export const SEARCH_BY_KEYWORD= gql`query getSearchByKeyword($keyword: String!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!,$currPage: Float!,$itemsPerPage: Float!,$opId: Float!,$clientId: Float!){
  searchByKeyword:   searchByKeyword(
  inputs: {
      keyword : $keyword
      opId : $opId
      clientId : $clientId
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
      currPage : $currPage
    	itemsPerPage : $itemsPerPage
    }){
      details
      apps{
        appDetails
        organicRank
        category
        noOfKeywords
        appId
      }
      fromDB
    }
}`;


export const APP_METADATA = gql`query getMetadataForApp($appId: Float!,$location: String!,$language: String!,$device: String!,$startDate: String!,$endDate: String!){
  getAppMetadata:   AppMetaData(
  inputs: {
      appId : $appId
      language : $language
      location : $location
      device : $device
      startDate: $startDate
      endDate: $endDate
    }){
    content{
      icon
      title
      rating
      developer{
        name
      }
    }
  }
}`;


//charge bee queries

export const CB_Customers = gql`query getCBCustomers($id: String!){
  getAllCBCustomers: getCBCustomer(id:$id){
    list{
      customer
    }
  }
}`;

export const CB_Subs = gql`query getCBSubs($custId: String!){
  getSubsForCust: getCBSubs(custId:$custId){
    list{
      subscription
    }
  }
}`;

export const CB_ChangeSub = gql`query changeSubPlan($subId: String!, $usage: Float!){
  changePlanForSub:   changeSubForCust(subId:$subId,usage:$usage){
    subscription
  }
}`;

export const CB_SubOnboard = gql`query createSubPlan($plan_id: String!, $invoice_immediately: Boolean!, $first_name: String!, $last_name: String!, $customer_id: String!, $email: String!){
  createPlanForSub:   CreateCBSubPlan(
    plan_id : $plan_id,
    invoice_immediately : $invoice_immediately,
    first_name : $first_name,
    last_name : $last_name,
    customer_id : $customer_id
    email : $email
  ){
    subscription
    customer
  }
}`;

export const APP_OVERVIEW_ALONE = gql`query getOverviewAloneForApp(
    $period: Float!
    $gender: String!
    $location: String!
    $device: String!
    $currdate: String!
    $prevdate: String!
    $appId: Float!
    $change: String!
  ){
  currentOverviewAlone:   getOverviewAlone(
		inputs: {
      period : $period
      gender : $gender
      location : $location
      device : $device
      date : $currdate
      adamId : $appId
      change : $change
    }
  ){
		grandTotal
  }

  previousOverviewAlone:   getOverviewAlone(
		inputs: {
      period : $period
      gender : $gender
      location : $location
      device : $device
      date : $prevdate
      adamId : $appId
      change : $change
    }
  ){
		grandTotal
  }
}`;


export const APP_OVERVIEW_MAP = gql`query getOverviewMapForApp(
  $period: Float!
  $gender: String!
  $location: String!
  $device: String!
  $currdate: String!
  $appId: Float!
  $change: String!
){

  getMapOverview(
		inputs: {
      period : $period
      gender : $gender
      location : $location
      device : $device
      date : $currdate
      adamId : $appId
      change : $change
    }
  ){
		grandTotal
  }

}`;


export const APP_OVERVIEW = gql`query getOverviewForApp(
    $period: Float!
    $location: String!
    $device: String!
    $currdate: String!
    $prevdate: String!
    $appId: Float!
    $change: String!
  ){
  currentOverview:   getOverview(
    inputs: {
      period : $period
      gender : "MF"
      location : $location
      device : $device
      date : $currdate
      adamId : $appId
      change : $change
    }
  ){
    grandTotal
  }

  previousOverview:   getOverview(
    inputs: {
      period : $period
      gender : "MF"
      location : $location
      device : $device
      date : $prevdate
      adamId : $appId
      change : $change
    }
  ){
    grandTotal
  }
}`;


export const APP_OVERVIEW_DEVICE = gql`query getDeviceOverviewForApp(
    $period: Float!
    $location: String!
    $device: String!
    $currdate: String!
    $prevdate: String!
    $appId: Float!
    $change: String!
  ){
  currentOverview:   getDeviceOverview(
    inputs: {
      period : $period
      gender : "PH"
      location : $location
      device : $device
      date : $currdate
      adamId : $appId
      change : $change
    }
  ){
    grandTotal
  }

  previousOverview:   getDeviceOverview(
    inputs: {
      period : $period
      gender : "PH"
      location : $location
      device : $device
      date : $prevdate
      adamId : $appId
      change : $change
    }
  ){
    grandTotal
  }
}`;


export const OWN_APPS = gql`query getOwnApps($from: String!){
  getAllOwnApps: getOwnAppsFromDB (
    from : $from
   ) {
  	adamId
    appName
	}
}`;


export const TOP_ADS = gql`query getTopAds($param: String!, $appId: Float!, $period: Float!){
  getTopAds:   getTopAdGroups(appId:$appId, param:$param, period:$period) {
  	id
    name
    localSpend
    impressions
    taps
	}
}`;


export const TOP_KEYS= gql`query getTopKeys($param: String!, $appId: Float!, $period: Float!){
  getTopKeys:   getTopKeywords(appId:$appId, param:$param, period:$period) {
  	id
    name
    localSpend
    impressions
    taps
	}
}`;


export const ALL_CARDS= gql`query getAllCards($custId: String!, $limit: Float!, $type:String!){
  getAllCards:     getCBCustCard(
    custId: $custId
    limit : $limit
    type : $type
  ) {
    list {
      payment_source
    }
    next_offset
  }
}`;


export const ADD_CARD= gql`query addCard($custId: String!, $number: String!, $cvv: Float!, $year: Float!, $month: Float!, $primary:Boolean!){
  addCard:     addCBCustCard(inputs : {
    customer_id : $custId
    replace_primary_payment_source : $primary
    number : $number
    cvv : $cvv
    expiry_year : $year
    expiry_month : $month
  }) {
    customer
    payment_source
  }
}`;


export const UPDATE_CARD= gql`query updateCard($cardId: String!, $year: Float!, $month: Float!){
  updateCard:       updateCBCustCard(inputs : {
    cardId: $cardId
    expiry_year : $year
    expiry_month : $month
  })
  {
    customer
    payment_source
  }
}`;


export const DEL_CARD= gql`query deleteCard($cardId: String!){
  deleteCard: deleteCBCustCard(cardId : $cardId) {
    customer
    payment_source
  }
}`;


export const ALL_TRANS= gql`query allTrans($transId: String!, $custId: String!, $limit:Float!, $offset:String!){
  allTrans:   getCBTransaction(transId:$transId, custId:$custId, limit:$limit, offset:$offset){
    list{
      transaction
    }
  }
}`;


export const ALL_INVOICES= gql`query getAllInvoices($custId: String!, $limit:Float!){
  getAllInvoices:   getCBCustInvoices(
    custId:$custId,
    limit:$limit
  ){
    list {
      invoice
    }
  }
}`;


export const DL_INVOICE= gql`query dlInvoice($invoice_id: String!, $disposition_type:String!){
  dlInvoice:   getCBCustInvoicePDF(
    invoice_id:$invoice_id,
    disposition_type:$disposition_type
  ){
    download
  }
}`;


export const KEYWORD_RECOMENDS= gql`query KeywordRecomends($appid:Float!, $country: String!, $term:String!){
  KeywordRecomends:   KeywordRecomends(inputs:{
    appid: $appid,
    country: $country,
    term: $term
  }){
    content{
      volume
      keyword
    }
    country
    date
    datetime
    term
  }
}`;

export const SEARCH_FOR_APPS= gql`query searchForApps($term: String!,$location: String!,$language: String!,$device: String!){
  searchForApps:   searchForApps(
    inputs:{
    	term: $term
    	language: $language
    	location: $location
    	device: $device
    	num: 10
    }){
    title
    id
    icon
  }
}`;

export const SEARCH_FOR_COMPETES= gql`query getCompetitors($appId: Float!,$location: String!,$language: String!,$device: String!){
  getCompetitors:   getCompetitors(
    inputs:{
    	appId: $appId
    	language: $language
    	location: $location
    	device: $device
    }){
    title
    id
    icon
    version
    rating
    genres
    price
  }
}`;

export const GET_CATEGORY_TOP= gql`query getCategoryTop($catId: Float!,$location: String!,$language: String!,$device: String!,$type: String!,$now: Float!){
  getCategoryTop:   getCategoryTop(
    inputs:{
    	catId: $catId
      type: $type
      now: $now
    	language: $language
    	location: $location
    	device: $device
    }){
    title
    id
    icon
    rating
    genres
    price
    rating_count
    power
    in_apps
  }
}`;


export const UPDATE_CHECKLIST= gql`query updateCL($userId: String!,$clfield: CheckListFields!,$time: Float!){
  updateCL:   updateCL(
    req: {
      clfield: $clfield
      userId: $userId
      time: $time
    }
  ){
    successMessage
    code
  }
}`;


export const GET_CHECKLIST= gql`query getUserCL($userId: String!){
  getUserCL:getUserCL(userId:$userId){
    createCampaign
    saIntegration
    # slackIntegration
    overviewPage
    # kaiApp
    # kaiKeyword
    kaiRecom
    # kaiTop
    kaiComp
    # teamManagement
    addMember
    # automationRules
    createRule
    # checkLogs
    adsManager
    # optimization
    onboarding
    # buyPlan
  }
}`;


export const CHECK_APP_ATTR = gql`query getUserCL($appId: Float!){
  checkAppAttr: checkAppAttr(appId:$appId){
    successMessage
    code
    content
  }
}`;
