//English
export const en = {
  pages: {
    overview: 'Overview',
    adsManager: 'Ads Manager',
    kai: 'Keyword Analysis',
    keyOpt: 'Keyword Optimization',
    teamMgmt: 'Team',
    integrations: 'Integrations',
    automationRule: 'Automation',
  },
  menu: {
    settings: 'Settings',
    signOut: 'Sign Out',
  },
  buttons: {
    syncData: 'Sync Latest Data',
    createCampaign: 'Create Campaign',
    filter: 'Filter',
    reset: 'Reset',
    apply: 'Apply',
  },
  labels: {
    charts: 'Charts',
    selectDs: 'Select Datasets',
    totalSummary: 'Total Summary',
    spend: 'Spend',
  },
  dropdowns: {
    noData: 'No data available',
    daily: 'DAILY',
    weekly: 'WEEKLY',
    monthly: 'MONTHLY',
  },
  tabs: {
    accounts: 'Accounts',
    apps: 'Apps',
    campaigns: 'Campaigns',
    adgroups: 'Ad Groups',
    keywords: 'Keywords',
    creativeSets: 'Creative Sets',
    searchTerms: 'Search Terms',
    negKeywords: 'Negative Keywords',
  },
  summary: {
    taps: 'Taps',
    impressions: 'Impressions',
    ttr: 'TTR',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    installs: 'Installs',
  },
  accountHeaders: {
    account: 'Account',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
  },
  appsHeaders: {
    appName: 'Application',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstall: 'Attr Install',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goals Revenue',
    goalsRoas: 'Goals ROAS',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    account: 'Account',
    categoryRank: 'Category Rank',
    appId: 'App ID',
  },
  campaignHeaders: {
    campaign: 'Campaign',
    campaignStatus: 'Campaign Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRoas: 'Goals ROAS',
    storefronts: 'Storefronts',
    account: 'Account',
    displayStatus: 'Display Status',
    appName: 'App Name',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    delete: 'Delete',
  },
  campaignDraftHeaders: {
    campaignName: 'Campaign Name',
    countries: 'Countries',
    budgetAmount: 'Budget Amount',
    resume: 'Resume',
  },
  adGroupHeaders: {
    adgroup: 'Ad Group',
    adgroupStatus: 'Adgroup Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRoas: 'Goals ROAS',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    displayStatus: 'Display Status',
    account: 'Account',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Adgroup ID',
  },
  keyHeaders: {
    keyword: 'Keyword',
    keywordStatus: 'Keyword Status',
    bidAmount: 'Bid Amount',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goals Revenue',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Adgroup Status',
    organicRank: 'Organic Rank',
    appName: 'App Name',
    difficultyScore: 'Difficulty Score',
    popularity: 'Popularity',
    keywordId: 'Keyword Id',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Adgroup ID',
    suggestedMinBid: 'ASA Suggested Min Bid',
    suggestedMaxBid: 'ASA Suggested Max Bid',
    bidStrength: 'Bid Strength',
  },
  creativeHeaders: {
    creativeSet: 'Creative Set',
    creativeSetStatus: 'Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goals Revenue',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Adgroup Status',
    account: 'Account',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Adgroup ID',
  },
  searchTermsHeader: {
    searchTerm: 'Search Term Text',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Adgroup Status',
    keyword: 'Keyword',
    keywordId: 'Keyword Id',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Adgroup ID',
  },
  negativeHeaders: {
    keyword: 'Keyword',
    matchType: 'Match Type',
    status: 'Status',
    adgroupId: 'Adgroup ID',
    campaignId: 'Campaign ID',
    deleted: 'Deleted',
    keywordId: 'Keyword Id',
    modificationTime: 'Modification Time',
  },
  filter: {
    account: 'Select Account',
    app: 'Select App',
    campaign: 'Select Campaign',
    adgroup: 'Select Adgroup',
  },
  subTabs: {
    live: 'Live',
    paused: 'Paused',
    deleted: 'Deleted',
    draft: 'Draft',
    campaigns: 'Campaigns',
    adgroups: 'Adgroups',
    keywords: 'Keywords',
    creativeSet: 'Creative Set',
    negKey: 'Negative Keywords',
  },
  popups: {
    add: 'Add',
    integration: 'Integration',
    adgroup: 'Ad Group',
    keywords: 'Keywords',
    creativeSet: 'Creative Set',
    negKey: 'Negative Keywords',
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: 'Manage Users',
    invite: 'Invite and Manage Team Members',
    add: 'Add Member',
    first: 'First Name',
    last: 'Last Name',
    phone: 'Phone',
    email: 'Email',
    job: 'Job Title',
    allMembers: 'All Members',
    processing: 'Processing...',
    ok: 'Ok',
    inviteStatus: 'Invite Status',
    isDisabled: 'IsDisabled',
    company: 'Company Name',
  },

  //Add Integration Page
  integration: {
    assPart: 'Associated Partners',
    apple: 'Apple Search Ads',
    slack: 'Slack',
    itunes: 'Itunes',
    tenjin: 'Tenjin',
    kochava: 'Kochava',
    adjust: 'Adjust',
    appsFlyer: 'AppsFlyer',
    singular: 'Singular',
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: 'Added Keywords',
    total: 'Total',
    cancel: 'Cancel',
    runAds: 'Run Ads',
    ok: 'Ok',
    totalPaidKey: 'Total Paid Keywords',
    popularity: 'Popularity',
    numOfApps: 'Numbers of App',
    chance: 'Chance',
    appsRunAds: 'Apps Running Ads',
    maxPop: 'Max popularity for yours',
    keyword: 'Keyword',
    clear: 'Clear',
    clrAllFilter: 'Clear All Filter',
    keywords: 'Keywords',
    organicRank: 'Organic Rank',
    totalApps: 'Total Apps',
    app: 'App',
    catName: 'Category Name',
    searchByApp: 'Search by App',
    searchByKey: 'Search by Keyword',
    // TODO also in other languages, remove todo when done
    addAllKey: 'Quick Add Keywords',
    searchForCompetes: 'Suggested Competitors',
    searchAdsRecommends: 'Search Ads Recommendations',
    categoryTop: 'Category Top Apps ',
    compRating: 'Rating',
    compVersion: 'Version',
    compPrice: 'Price',
    compGenres: 'Genres',
    monthlyDownloads: 'Monthly Downloads',
    free: 'Free',
    paid: 'Paid',
  },

  //Overview Page
  overview: {
    period: 'period',
    topKeywords: 'Top 10 Keywords By',
    key: 'Keyword',
    spend: 'Spend',
    impressions: 'Impressions',
    taps: 'Taps',
    goToKey: 'Go to All Keywords',
    topAdgroup: 'Top 10 AdGroups By',
    adgroup: 'AdGroup',
    goToAdgroup: 'Go to All AdGroups',
    gender: 'Gender',
    device: 'Device',
    searchApp: 'Search App',
    lastDay: 'Last Day',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    ttr: 'Tap-through rate (TTR)',
    installs: 'Installs',
    avgCPA: 'Average CPA',
    avgCPT: 'Average CPT',
    conversionRate: 'Conversion Rate (CR)',
    latOnInstalls: 'LAT On Installs',
    latOffInstalls: 'LAT Off Installs',
    reDownloads: 'Re-Downloads',
    newDownloads: 'New Downloads',
    all: 'All',
    tooltip:
      'Shows the data difference as percentage according to previous period. Previous period is previous selected date range',
    revenue: 'Revenue',
    attrInstalls: 'Attributed Installs',
    unAttrInstalls: 'UnAttributed Installs',
    attrInstallRate: 'Attributed Install Rate',
    events: 'Events Count',
    clicks: 'Clicks Count',
    cpi: 'Cost Per Install',
    cpge: 'Cost Per Event',
    roas: 'ROAS',
    roi: 'ROI',
    rpi: 'RPI',
  },

  //Keyword Optimization Page
  keyOpt: {
    keyBid: 'Keyword Bid Optimization',
    keyToolTip:
      'You can optimize bid amount of the keywords which are not in range of ASA suggested min bid amount and ASA suggested max bid amount.',
    optAll: 'Optimize All',
    opt: 'Optimize',
    optBidAmt: 'Optimize Bid Amount',
    key: 'Keyword',
    optBidForAll: 'Optimize Bid Amount of All Keywords',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
    bidAmt: 'Bid Amount',
    asaMinBid: 'ASA Suggested Min Bid',
    asaMaxBid: 'ASA Suggested Max Bid',
    action: 'Action',
  },

  //ARRules Page
  aRules: {
    createAR: 'Create Automation Rule',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    disable: 'Disable',
    enable: 'Enable',
    name: 'Name',
    createdAt: 'Created At',
    status: 'Status',
    completed: 'Completed At',
    actionFreq: 'Action Frequency',
    rules: 'Rules',
    logs: 'Logs',
    createdFor: 'Created for',
    chckFreq: 'Check Frequency',
    actions: 'Actions',
    enableDisable: 'Enable/Disable'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
    createCamp: 'Create Campaign',
    h2: 'Choose an app to start creating campaign',
    para:
      'Campaigns have budgets and ad groups that focus on a common theme or strategy for promoting your app.',
    select: 'Select where ads will run',
    searchResults: 'Search Results',
    srData:
      'Campaigns have budgets and ad groups that focus on a common theme or strategy for promoting your app.',
    searchTab: 'Search Tab',
    stData:
      'Promote your app at the top of the suggested apps list when users visit the Search tab.',
    cancel: 'Cancel',
    continue: 'Continue',

    //Search Results and Search Tab Campaign
    countAndReg: 'Countries and Regions',
    adgroupSettings: 'Ad Group Settings',
    searchMatch: 'Search Match',
    key: 'Keyword',
    audience: 'Audience',
    crSets: 'Creative Sets',
    chooseCampGroup: 'Choose a Campaign Group',
    chooseApp: 'Choose an App',
    next: 'Next',
    countPara:
      'Your app will be promoted on the App Store in the eligible countries and regions you choose from the list.',
    chooseCount: 'Choose one or more countries or regions',
    campName: 'Campaign Name',
    budget: 'Budget',
    dailyCap: 'Daily Cap (Optional)',
    adgroupPara:
      'Ad Groups contain pricing goals and settings that determine who to show your ad to.',
    adgroupName: 'Ad Group Name',
    devices: 'Devices',
    startTime: 'Start Time',
    adScheduling: 'Ad Scheduling',
    endTime: 'End Time',
    dayParting: 'Dayparting (Optional)',
    ok: 'OK',
    defaultMax: 'Default Max CPT Bid',
    cpaGoal: 'CPA Goal(Optional)',
    saveDraft: 'Save as draft',
    appleSearchMatch: 'Apple Search Match',
    searchMatchPara:
      'Apple Search Match is the easiest way to get your ads up and running. Search Ads will automatically match your ad to users who are searching for apps like yours.',
    searchMatchPara1: 'Automatically match my ad to relevant searches',
    keywords: 'Keywords',
    keyPara:
      'Keywords are relevant words or terms customers may use when searching for an app like yours. Keywords allow searchers to quickly find your app, helping to drive app installs.',
    upload: 'Upload',
    suppFormat: 'supported formats: Excel, CSV',
    keyManually: 'Or add keywords manually',
    targKey: 'Targeting Keywords',
    clear: 'Clear',
    adgroupNegKey: 'Ad Group Negative Keywords',
    optional: 'Optional',
    negKey: 'Negative Keywords',
    audPara:
      ' Refine the audience for your ad group using optional advanced features.',
    custType: 'Customer Type',
    demo: 'Demographics',
    gender: 'Gender',
    location: 'Location',
    crSetPara:
      'Ads are automatically created using the metadata, screenshots and app previews provided on the App Store product page. These also appear in organic search results on the App Store. The ads shown here are default ad examples.',
    crOptional: 'Creative Set (Optional)',
    noCrSet: 'App does not fulfill the requirement of minimum Creative Set',
    campCreated: 'Campaign Created',
    campCreatedPara: 'Now, you can see your campaigns on Search Ads dashboard',
    close: 'Close',
    matchType: 'Match Type',
    amount: 'Amount',
    currency: 'Currency',
    allUsers: 'All users',
    newUsers: 'New Users',
    appUsers: 'Users of my other apps',
    existingUsers: 'Existing users',
    all: 'All',
    male: 'Male',
    female: 'Female',
  },

  //Change Password
  changePass: {
    backSett: 'Back To Settings',
    chngPass: 'Change Password',
    newPass: 'New Password',
    confirmPass: 'Confirm Password',
    submit: 'Submit',
    h4: 'The Kochava Marketers Operating System™',
    para:
      'The Marketers Operating System (m/OS) seamlessly integrates omni-channel marketing solutions for advertisers and publishers in one operational platform.',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Forgot Password',
    typeEmail: 'Type the Email Address',
    emailAdd: 'Email Address',
    submit: 'Submit',
    chckEmail: 'Please Check Your Email',
    para: 'To set a new password please check your email',
    close: 'Close',
    alreadyAcc: 'Already have an account?',
    signIn: 'Sign In',
  },

  //Onboard
  onboard: {
    welcome: 'Welcome to Kochava',
    personal: 'Personal',
    start: 'Start',
    integration: 'Integration',
    next: 'Next',
    trial: 'Start Your Trial',
    scheduleDemo: 'Or schedule demo with us and discover together',
    addInt: 'Add an integration.',
    signInApple:
      'Sign in with Apple to add an Apple Search Ads integration. Please note:',
    step1:
      '1. It is required to have admin rights to grant access to Apple Search Ads account.',
    step2:
      '2. In order to manage your campaigns from SearchAdsNinja, please select Read & Write option before granting access.',
    skip: 'Skip',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Add Adgroup',
    para:
      'You have not selected your campaign yet. You can choose it from below box.',
    camGroup: 'Campaign Group',
    campaign: 'Campaign',
    adgroupSett: 'Ad Group Settings',
    para1:
      'Ad Groups contain pricing goals and settings that determine who to show your ad to.',
    adgroupName: 'Ad Group Name',
    devices: 'Devices',
    adScheduling: 'Ad Scheduling',
    startTime: 'Start Time',
    endTime: 'End Time',
    dayparting: 'Dayparting (Optional)',
    defaultMax: 'Default Max CPT Bid',
    cptTool:
      'This is the maximum amount you willing to pay for a tap on your add.This bid will apply to all keywords in this ad group unless you apply an individual keyword bid.',
    cpaGoal: 'CPA Goal(Optional)',
    cpaTool:
      'The average you have paid for each download. This is calculated by dividing the total spent to the number of downloads.',
    searchMatch: 'Search Match',
    searchTool:
      'Search Match is the easiest way to get your ads up and running. Search Ads will automatically match your ad to users who are searching for apps like yours.',
    searchPara: 'Automatically match my ad to relevant searches',
    key: 'Keywords',
    keyPara:
      'Keywords are relevant words or terms customers may use when searching for an app like yours. Keywords allow searchers to quickly find your app, helping to drive app installs.',
    inputCpt: 'Input Default Max CPT Bid to activate keywords section',
    addKeyPara:
      'Add keywords relevant to your app and genre. We’ll help by sharing additional recommendations.',
    upload: 'Upload',
    addKeyManually: 'Or add keywords manually',
    negKey: 'Ad Group Negative Keywords(Optional)',
    supp: 'supported formats: Excel, CSV',
    demAge: 'Demographics Age, Gender',
    demPara: "Target your ad based upon your user's age or gender",
    ageRange: 'Age Range',
    cancel: 'Cancel',
    apply: 'Apply',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Edit Adgroup',
    adgroup: 'Adgroup',
    advance: 'Advance Settings',
    viewEdit: 'View and Edit',
    general: 'General',
    settings: 'Settings',
    schedule: 'Schedule',
    custPara: 'Select the app customers you want to reach',
    selectAge: 'Select Age Range',
    save: 'Save',
  },

  //Create AR
  createAR: {
    createAutoRule: 'Create Automation Rule',
    editAutoRule: 'Edit Automation Rule',
    choose: 'Choose',
    chooseAutoRule: 'Please choose automation level and items.',
    conditions: 'Conditions',
    actions: 'Actions',
    settings: 'Settings',
    condition: 'Condition',
    addCondition: 'Add Condition',
    andCondition:
      'Choose whether all above conditions must match or only one of them should match',
    checkCond: 'Check conditions for',
    action: 'Action',
    selectAll: 'Select All',
    enterEmail: 'Please enter proper email ids',
    addTo: 'Add To',
    selectAdgroups: 'Select AdGroups',
    selectCampaigns: 'Select Campaigns',
    cptBid: 'CPT Bid',
    customBid: 'Custom Bid',
    matchType: 'Match Type',
    addAction: 'Add Action',
    chckFreq: 'Check Frequency',
    ruleName: 'Rule Name',
    cancel: 'Cancel',
    step2: 'Step 2',
    step3: 'Step 3',
    andOrTypesText1: 'All must match',
    andOrTypesText2: 'Only one should match',
    addToModelListNegText1: 'All Selected AdGroup(s)',
    addToModelListNegText2: 'All AdGroups of Selected Campaign(s)',
    addToModelListNegText3: 'All Selected Campaign(s)',
    matchTypesText1: 'Broad Match',
    matchTypesText2: 'Exact Match',
    cptBidItemsText1: 'Set To Custom',
    cptBidItemsText2: 'AdGroup Default Bid',
    actionFreqsText1: 'Hourly',
    actionFreqsText2: 'Every 6 hours',
    actionFreqsText3: 'Every 12 hours',
    actionFreqsText4: 'Every day',
    actionFreqsText5: 'Custom hours',
    limitActionFreqsText1: 'Every 2 hours',
    limitActionFreqsText2: 'Every 3 hours',
    limitActionFreqsText3: 'Every 2 days',
    limitActionFreqsText4: 'Every 3 days',
    limitActionFreqsText5: 'Every week',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    ciTimeRangesText1: 'Last 1 hour',
    ciTimeRangesText2: 'Last 1 day',
    ciTimeRangesText3: 'Last 7 days',
    ciTimeRangesText4: 'Last 30 days',
    ciTimeRangesText5: 'Last month',
    ciTimeRangesText6: 'Custom hour',
    allConOperationText1: 'Greater Than',
    allConOperationText2: 'Less Than',
    allConOperationText3: 'Range',
    allConOperationText4: 'Not In Range',
    amount: '$ Amount',
    budget: '% Percent of Budget',
    incBy: 'Decrease by',
    decBy: 'Increase by',
    setToCPA: 'Set to Average CPA',
    setToCPT: 'Set to Average CPT',
    value: '$ Value',
    change: '% Change',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Choose Account',
    cancel: 'Cancel',
    apply: 'Apply',
    clearAll: 'Clear All',
    accounts: 'Accounts',
    accountSelected: 'Account(s) selected',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'Choose App',
    cancel: 'Cancel',
    apply: 'Apply',
    apps: 'Apps',
    appSelected: 'App(s) selected',
    clearAll: 'Clear All',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: 'Please keep only one Ad-Group selected to continue.',
    close: 'Close',
    chooseKeywords: 'Choose Keywords',
    cancel: 'Cancel',
    apply: 'Apply',
    campaigns: 'Campaigns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    adGroups: 'Ad Groups',
    keywords: 'Keywords',
    keywordSelected: 'keyword(s) selected',
    clearAll: 'Clear All',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Add Creative Set',
    setup: 'Setup',
    para:
      'Choose a name that describes the theme of your Creative Set. Select the language of the screenshots and previews you’d like to include from your App Store product page.',
    createSetName: 'Creative Set Name ',
    localLang: 'Localization Language',
    assetSelection: 'Asset Selection',
    assetSelectionPararaph1:
      'Creative Sets consist of screenshots and app previews that appear on the App Store product page.',
    assetSelectionPararaph2: 'For each iPhone display size,',
    assetSelectionPararaph3:
      'select at least one landscape or three portrait assets.',
    assetSelectionPararaph4: 'For each iPad display size,',
    assetSelectionPararaph5:
      'select at least one landscape or two portrait assets.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Please select at least',
    screenshotsPara2: 'one landscape',
    screenshotsPara3: 'or',
    screenshotsPara4: 'two portrait',
    screenshotsPara5: 'screenshots!',
    screenshotsPara6: 'Please select at least',
    screenshotsPara7: 'one landscape',
    screenshotsPara8: 'or',
    screenshotsPara9: 'three portrait',
    screenshotsPara10: 'screenshots!',
    cancel: 'Cancel',
    save: 'Save',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Delete campaign',
    para: 'Are you sure you want to delete the campaign?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Campaign Edit Wizard',
    campName: 'Campaign Name',
    app: 'App',
    countriesOrRegionsPara: 'Choose one or more countries or regions',
    budget: 'Budget',
    dailyCap: 'Daily Cap',
    cancel: 'Cancel',
    save: 'Save',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Choose Campaign',
    noSelectedCamp: 'No Selected Camapign',
    campaigns: 'Campaigns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    selectedCampaigns: 'Selected Campaigns',
    clearAll: 'clear All',
    cancel: 'Cancel',
    apply: 'Apply',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Edit credit Card',
    cardNumber: 'Card Number',
    expiryDate: 'Expiry Date',
    cvv: 'CVV',
    saveCard: 'Save Card',
    updateExpiryPara: '* You can only update Expiry Date',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Edit Creative Set',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Setup',
    editCreativePara1:
      'Choose a name that describes the theme of your Creative Set. Select the language of the screenshots and previews you’d like to include from your App Store product page.',
    creativeSetName: 'Creative Set Name',
    localLang: 'Localization Language',
    assetSelection: 'Asset Selection',
    assetSelectionPararaph1:
      'Creative Sets consist of screenshots and app previews that appear on the App Store product page.',
    assetSelectionPararaph2: 'For each iPhone display size,',
    assetSelectionPararaph3:
      'select at least one landscape or three portrait assets.',
    assetSelectionPararaph4: 'For each iPad display size,',
    assetSelectionPararaph5:
      'select at least one landscape or two portrait assets.',
    viewDevice: 'View Device Display Sizes',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Display',
    default: 'Default',
    cancel: 'Cancel',
    save: 'Save',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Delete Integration',
    cardText: 'Are you sure you want to delete the integration?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Add Keywords',
    keywordSuggestionsFor: 'Keyword Suggestions for',
    on: 'on',
    addedKeywords: 'Added Keywords',
    total: 'Total',
    add: 'Add',
    cancel: 'Cancel',
    apply: 'Apply',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Add Keywords',
    selectTarget: 'Select Target',
    h4Camp: 'Campaigns:',
    selected: 'Selected',
    change: 'Change',
    h4Adgroups: 'Ad Groups:',
    maxCPTBid: 'Max CPT Bid:',
    keywordSuggestionsFor: 'Keyword Suggestions for',
    on: 'on',
    recommendedKeywords: 'Recommended Keywords',
    addedKeywords: 'Added Keywords',
    add: 'Add',
    total: 'Total',
    cancel: 'Cancel',
    apply: 'Apply',
    ok: 'Ok',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Add Keywords',
    selectTarget: 'Select Target',
    h4Camp: 'Campaigns:',
    selected: 'Selected',
    change: 'Change',
    h4Adgroups: 'Ad Groups:',
    maxCPTBid: 'Max CPT Bid:',
    keywordSuggestionsFor: 'Keyword Suggestions for',
    on: 'on',
    recommendedKeywords: 'Recommended Keywords',
    addedKeywords: 'Added Keywords',
    add: 'Add',
    total: 'Total',
    cancel: 'Cancel',
    apply: 'Apply',
    ok: 'Ok',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Add Negative Keywords',
    add: 'Add',
    total: 'Total',
    cancel: 'Cancel',
    save: 'Save',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Bid Amount',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Please keep only one Ad-Group selected to continue.',
    close: 'Close',
    chooseAdGroup: 'Choose ad group',
    cancel: 'Cancel',
    apply: 'Apply',
    campaigns : 'Campaigns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    adGroups : 'Ad Groups',
    adGroupSelected : 'AdGroup(s) selected',
    clearAll : 'Clear All'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Add Negative Keywords',
    selectTarget : '1. Select Target',
    addNegKeyword : 'Add Negative Keyword',
    paragraph1 : 'Negative keywords can be added to Campaigns Or Ad Groups. Please choose one of them from below box.',
    paragraph2 : 'Which Campaign do you want to upload keywords?',
    selectCampaign : 'Select Campaign',
    paragraph3 : 'Which Adgroup do you want to upload keywords?',
    selectAdgroup : 'Select Adgroup',
    cancel: 'Cancel',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Campaigns:',
    selected : 'Selected',
    change: 'Change',
    paragraph4 : ' Ad group negative keywords apply to this ad group only. If you want negative keywords to apply to all ad groups, set them at the campaign level.',
    paragraph5 : ' Consider giving campaigns time to run before analyzing ad performance to learn which keywords may not work for you. Adding too many negative keywords may limit your ad exposure.',
    addedKeywords : 'Added Keywords',
    add: 'Add',
    total: 'Total',
    save: 'Save',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Add Slack Channel',
    sno: 'S.No'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Cancel',
ok : 'Ok',
  },

  //HelloWorld
  helloWorld : {
welcome : 'Welcome to Vuetify',
para1 : 'For help and collaboration with other Vuetify developers,',
para2 : 'please join our online',
discord : 'Discord Community',
h2 : "What's next?",
impLinks : 'Important Links',
ecosystem : 'Ecosystem',
  },

  //InfoAlert
  infoAlert : {
ok : 'Ok',
  },

};
