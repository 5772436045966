//Korean

export const ko = {
  pages: {
    overview: '개요',
    adsManager: '광고 관리자',
    kai: '키워드 분석',
    keyOpt: '키워드 최적화',
    teamMgmt: '팀',
    integrations: '통합',
    automationRule: '오토메이션'
  },
  menu: {
    settings: '설정',
    signOut: '로그아웃'
  },
  buttons: {
    syncData: '최신 데이터 동기화',
    createCampaign: '캠페인 만들기',
    filter: '필터',
    reset: '초기화',
    apply: '대다'
  },
  labels: {
    charts: '차트',
    selectDs: '데이터 세트 선택',
    totalSummary: '총 요약',
    spend: '보내다'
  },
  dropdowns: {
    noData: '자료 없음',
    daily: '매일',
    weekly: '주간',
    monthly: '월간 간행물'
  },
  tabs: {
    accounts: '계정',
    apps: '앱',
    campaigns: '캠페인',
    adgroups: '광고그룹',
    keywords: '키워드',
    creativeSets: '크리에이티브 세트',
    searchTerms: '검색어',
    negKeywords: '제외 키워드'
  },
  summary: {
    taps: '탭',
    impressions: '노출수',
    ttr: 'TTR',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    installs: '설치',
  },
  accountHeaders: {
    account: '계정',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    ttr: 'TTR',
    conversionRate: '전환율',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드'
  },
  appsHeaders: {
    appName: '신청',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstall: '속성 설치',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    goalsRoas: '목표 ROAS',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    account: '계정',
    categoryRank: '카테고리 순위',
    appId: '앱 ID',
  },
  campaignHeaders: {
    campaign: '운동',
    campaignStatus: '캠페인 상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRoas: '목표 ROAS',
    storefronts: '상점',
    account: '계정',
    displayStatus: '디스플레이 상태',
    appName: '앱 이름',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    delete: '지우다'
  },
  campaignDraftHeaders: {
    campaignName: '캠페인 이름',
    countries: '국가',
    budgetAmount: '예산 금액',
    resume: '이력서',
  },
  adGroupHeaders: {
    adgroup: '광고 그룹',
    adgroupStatus: '광고 그룹 상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRoas: '목표 ROAS',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    displayStatus: '디스플레이 상태',
    account: '계정',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID'
  },
  keyHeaders: {
    keyword: '예어',
    keywordStatus: '키워드 상태',
    bidAmount: '입찰 금액',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    organicRank: '유기적 순위',
    appName: '앱 이름',
    difficultyScore: '난이도 점수',
    popularity: '인기',
    keywordId: '키워드 ID',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID',
    suggestedMinBid: 'ASA 권장 최소 입찰가',
    suggestedMaxBid: 'ASA 권장 최대 입찰가',
    bidStrength: '입찰 강도'
  },
  creativeHeaders: {
    creativeSet: '크리에이티브 세트',
    creativeSetStatus: '상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    account: '계정',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID'
  },
  searchTermsHeader: {
    searchTerm: '검색어 텍스트',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    ttr: 'TTR',
    conversionRate: '전환율',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    keyword: '예어',
    keywordId: '키워드 ID',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID'
  },
  negativeHeaders: {
    keyword: '예어',
    matchType: '일치 유형',
    status: '상태',
    adgroupId: '광고 그룹 ID',
    campaignId: '캠페인 ID',
    deleted: '삭제됨',
    keywordId: '키워드 ID',
    modificationTime: '수정 시간'
  },
  filter: {
    account: '계정 선택',
    app: '앱 선택',
    campaign: '캠페인 선택',
    adgroup: '광고그룹 선택',
  },
  subTabs: {
    live: '라이브',
    paused: '일시중지됨',
    deleted: '삭제됨',
    draft: '초안',
    campaigns: '캠페인',
    adgroups: '광고그룹',
    keywords: '키워드',
    creativeSet: '크리에이티브 세트',
    negKey: '제외 키워드'
  },
  popups: {
    add: '더하다',
    integration: '완성',
    adgroup: '광고 그룹',
    keywords: '키워드',
    creativeSet: '크리에이티브 세트',
    negKey: '제외 키워드'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: '사용자 관리',
    invite: '팀원 초대 및 관리',
    add: '회원 추가',
    first: '이름',
    last: '성',
    phone: '전화',
    email: '이메일',
    job: '직위',
    allMembers: '전원',
    processing: '처리 중...',
    ok: '확인',
    inviteStatus: '초대 상태',
    isDisabled: '비활성화됨',
    company: '회사 이름'
  },

  //Add Integration Page
  integration: {
    assPart: '관련 파트너',
    apple: '애플 검색 광고',
    slack: '느슨하게',
    itunes: '아이튠즈',
    tenjin: '텐진',
    kochava: '코 차바',
    adjust: '맞추다',
    appsFlyer: 'AppsFlyer',
    singular: '단수형'
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: '추가 된 키워드',
    total: '합계',
    cancel: '취소',
    runAds: '광고 실행',
    ok: '확인',
    totalPaidKey: '총 유료 키워드',
    popularity: '인기',
    numOfApps: '앱 수',
    chance: '기회',
    appsRunAds: '광고를 실행하는 앱',
    maxPop: '최대 인기도',
    keyword: '예어',
    clear: '맑은',
    clrAllFilter: '모든 필터 지우기',
    keywords: '키워드',
    organicRank: '유기적 순위',
    totalApps: '총 앱',
    app: '앱',
    catName: '카테고리 이름',
    searchByApp: '앱으로 검색',
    searchByKey: '키워드 검색',
    addAllKey: '모든 키워드 추가'
  },

  //Overview Page
  overview: {
    period: '기간',
    topKeywords: '상위 10개 키워드 기준',
    key: '예어',
    spend: '보내다',
    impressions: '노출수',
    taps: '탭',
    goToKey: '모든 키워드로 이동',
    topAdgroup: '상위 10 개 광고 그룹 기준',
    adgroup: 'AdGroup',
    goToAdgroup: '모든 광고그룹으로 이동',
    gender: '성별',
    device: 'Device',
    searchApp: '앱 검색',
    lastDay: '마지막 날',
    last7Days: '지난 7 일',
    last30Days: '지난 30일',
    ttr: '탭스루율(TTR)',
    installs: '설치',
    avgCPA: '평균 CPA',
    avgCPT: '평균 CPT',
    conversionRate: '전환율 (CR)',
    latOnInstalls: '설치 시 LAT',
    latOffInstalls: 'LAT 끄기 설치',
    reDownloads: '재다운로드',
    newDownloads: '새로운 다운로드',
    all: '모두',
    tooltip: '이전 기간에 따른 데이터 차이를 백분율로 표시합니다. 이전 기간은 이전에 선택한 기간입니다.'
  },

  //Keyword Optimization Page
 keyOpt: {
  keyBid: '키워드 입찰가 최적화',
  keyToolTip: 'ASA 권장 최소 입찰가 및 ASA 권장 최대 입찰가 범위를 벗어나는 키워드의 입찰가를 최적화할 수 있습니다.',
  optAll: '모두 최적화',
  opt: '최적화',
  optBidAmt: '입찰 금액 최적화',
  key: '예어',
  optBidForAll: '모든 키워드의 입찰가 최적화',
  campaign: '운동',
  adgroup: '광고그룹',
  bidAmt: '입찰 금액',
  asaMinBid: 'ASA 권장 최소 입찰가',
  asaMaxBid: 'ASA 권장 최대 입찰가',
  action: '동작'
  },
  
  //ARRules Page
  aRules: {
  createAR: '자동화 규칙 생성',
  view: '전망',
  edit: '편집하다',
  delete: '지우다',
  disable: '비활성화',
  enable: '활성화',
  name: '이름',
  createdAt: '만든 시간',
  status: '상태',
  completed: '완료 시간',
  actionFreq: '행동 빈도',
  rules: '규칙',
  logs: '로그',
  createdFor: '에 대해 생성됨',
  chckFreq: '주파수 확인',
  actions: '행위'
  },
 
  //Create Campaign
  //CampaignView
  createCampaign: {
  createCamp: '캠페인 만들기',
  h2: '캠페인 생성을 시작할 앱 선택',
  para: '캠페인에는 앱 홍보를 위한 공통 주제 또는 전략에 중점을 둔 예산과 광고그룹이 있습니다.',
  select: '광고가 게재될 위치 선택',
  searchResults: '검색 결과',
  srData: '캠페인에는 앱 홍보를 위한 공통 주제 또는 전략에 중점을 둔 예산과 광고그룹이 있습니다.',
  searchTab: '검색 탭',
  stData: '사용자가 검색 탭을 방문할 때 추천 앱 목록 상단에서 앱을 홍보하세요.',
  cancel: '취소',
  continue: '계속하다',
  
  //Search Results and Search Tab Campaign
  countAndReg: '국가 및 지역',
  adgroupSettings: '광고그룹 설정',
  searchMatch: '검색 일치',
  key: '예어',
  audience: '청중',
  crSets: '크리에이티브 세트',
  chooseCampGroup: '캠페인 그룹 선택',
  chooseApp: '앱 선택',
  next: '다음',
  countPara: '목록에서 선택한 적격 국가 및 지역의 App Store에서 앱이 홍보됩니다.',
  chooseCount: '하나 이상의 국가 또는 지역을 선택하십시오.',
  campName: '캠페인 이름',
  budget: '예산',
  dailyCap: '일일 한도(선택 사항)',
  adgroupPara: '광고 그룹에는 광고를 게재할 대상을 결정하는 가격 책정 목표 및 설정이 포함되어 있습니다.',
  adgroupName: '광고그룹 이름',
  devices: '장치',
  startTime: '시작 시간',
  adScheduling: '광고 예약',
  endTime: '종료 시간',
  dayParting: '시간대(선택 사항)',
  ok: '확인',
  defaultMax: '기본 최대 CPT 입찰가',
  cpaGoal: 'CPA 목표(선택사항)',
  saveDraft: '임시 보관함에 저장',
  appleSearchMatch: '애플 검색 매치',
  searchMatchPara: 'Apple Search Match는 광고를 시작하고 실행하는 가장 쉬운 방법입니다. Search Ads는 귀하와 유사한 앱을 검색하는 사용자에게 귀하의 광고를 자동으로 일치시킵니다.',
  searchMatchPara1: '내 광고를 관련 검색어에 자동으로 일치시킵니다.',
  keywords: '키워드',
  keyPara: '키워드는 고객이 귀하의 앱과 유사한 앱을 검색할 때 사용할 수 있는 관련 단어 또는 용어입니다. 키워드를 사용하면 검색자가 앱을 빠르게 찾을 수 있어 앱 설치를 유도할 수 있습니다.',
  upload: '업로드',
  suppFormat: '지원 형식: Excel, CSV',
  keyManually: '또는 수동으로 키워드 추가',
  targKey: '타겟팅 키워드',
  clear: '맑은',
  adgroupNegKey: '광고그룹 제외어 키워드',
  optional: '선택 과목',
  negKey: '제외 키워드',
  audPara: ' 고급 기능(선택 사항)을 사용하여 광고그룹의 잠재고객을 세분화합니다.',
  custType: '고객 유형',
  demo: '인구통계',
  gender: '성별',
  location: '위치',
  crSetPara: '광고는 App Store 제품 페이지에서 제공되는 메타데이터, 스크린샷 및 앱 미리보기를 사용하여 자동으로 생성됩니다. 이는 App Store의 자연 검색 결과에도 나타납니다. 여기에 표시된 광고는 기본 광고 예입니다.',
  crOptional: '크리에이티브 세트(선택 사항)',
  noCrSet: '앱이 최소 Creative Set의 요구 사항을 충족하지 않습니다.',
  campCreated: '캠페인 생성됨',
  campCreatedPara: '이제 Search Ads 대시보드에서 캠페인을 볼 수 있습니다.',
  close: '닫기',
  matchType: '일치 유형',
  amount: '양',
  currency: '통화',
  allUsers: '모든 사용자들',
  newUsers: '신규 사용자',
  appUsers: '내 다른 앱의 사용자',
  existingUsers: '기존 사용자',
  all: '모두',
  male: '남성',
  female: '여자'
  },

   //Change Password
   changePass: {
    backSett: '설정으로 돌아가기',
    chngPass: '비밀번호 변경',
    newPass: '새 비밀번호',
    confirmPass: '비밀번호 확인',
    submit: '제출',
    h4: 'Kochava 마케터 운영 체제™',
    para:
      'm/OS(Marketers Operating System)는 광고주와 퍼블리셔를 위한 옴니채널 마케팅 솔루션을 하나의 운영 플랫폼에 완벽하게 통합합니다.',
    ok: '확인',
  },

  //Forgot Password
  forgotPass: {
    forPass: '비밀번호를 잊으 셨나요',
    typeEmail: '이메일 주소 입력',
    emailAdd: '이메일 주소',
    submit: '제출',
    chckEmail: '이메일을 확인하세요',
    para: '새 비밀번호를 설정하려면 이메일을 확인하세요',
    close: '닫기',
    alreadyAcc: '이미 계정이 있습니까?',
    signIn: '로그인',
  },

  //Onboard
  onboard: {
    welcome: '코차바에 오신 것을 환영합니다',
    personal: '개인적인',
    start: '스타트',
    integration: '완성',
    next: '다음',
    trial: '평가판 시작',
    scheduleDemo: '또는 우리와 함께 데모를 예약하고 함께 발견하십시오.',
    addInt: '통합을 추가합니다.',
    signInApple:
      'Apple에 로그인하여 Apple Search Ads 통합을 추가하십시오. 참고:',
    step1:
      '1. Apple Search Ads 계정에 대한 접근을 허용하기 위해서는 관리자 권한이 필요합니다.',
    step2:
      '2. SearchAdsNinja에서 캠페인을 관리하려면 액세스 권한을 부여하기 전에 읽기 및 쓰기 옵션을 선택하십시오.',
    skip: '건너 뛰기',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: '광고그룹 추가',
    para:
      '아직 캠페인을 선택하지 않았습니다. 아래 상자에서 선택할 수 있습니다.',
    camGroup: '캠페인 그룹',
    campaign: '운동',
    adgroupSett: '광고그룹 설정',
    para1:
      '광고 그룹에는 광고를 게재할 대상을 결정하는 가격 책정 목표 및 설정이 포함되어 있습니다.',
    adgroupName: '광고그룹 이름',
    devices: '기기',
    adScheduling: '광고 예약',
    startTime: '시작 시간',
    endTime: '종료 시간',
    dayparting: '시간대(선택 사항)',
    defaultMax: '기본 최대 CPT 입찰가',
    cptTool:
      '추가 탭에 대해 지불할 의사가 있는 최대 금액입니다. 개별 키워드 입찰가를 적용하지 않는 한 이 입찰가는 이 광고그룹의 모든 키워드에 적용됩니다.',
    cpaGoal: 'CPA 목표(선택사항)',
    cpaTool:
      '각 다운로드에 대해 지불한 평균입니다. 총 지출액을 다운로드 횟수로 나누어 계산합니다.',
    searchMatch: '검색 일치',
    searchTool:
      'Search Match는 광고를 게재하고 실행하는 가장 쉬운 방법입니다. Search Ads는 귀하와 유사한 앱을 검색하는 사용자에게 귀하의 광고를 자동으로 일치시킵니다.',
    searchPara: '내 광고를 관련 검색어에 자동으로 일치시킵니다.',
    key: '키워드',
    keyPara:
      '키워드는 고객이 귀하의 앱과 유사한 앱을 검색할 때 사용할 수 있는 관련 단어 또는 용어입니다. 키워드를 사용하면 검색자가 앱을 빠르게 찾을 수 있어 앱 설치를 유도할 수 있습니다.',
    inputCpt: '키워드 섹션을 활성화하려면 기본 최대 CPT 입찰가를 입력하세요.',
    addKeyPara:
      '앱 및 장르와 관련된 키워드를 추가하세요. 추가 권장 사항을 공유하여 도움을 드리겠습니다.',
    upload: '업로드',
    addKeyManually: '또는 수동으로 키워드 추가',
    negKey: '광고그룹 제외어 키워드(선택사항)',
    supp: '지원 형식: Excel, CSV',
    demAge: '인구통계 연령, 성별',
    demPara: "사용자의 연령 또는 성별에 따라 광고 타겟팅",
    ageRange: '연령대',
    cancel: '취소',
    apply: '대다',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: '광고그룹 수정',
    adgroup: '광고그룹',
    advance: '고급 설정',
    viewEdit: '보기 및 편집',
    general: '일반',
    settings: '설정',
    schedule: '시간표',
    custPara: '도달하려는 앱 고객을 선택하세요.',
    selectAge: '연령대 선택',
    save: '저장',
  },

  //Create AR
  createAR: {
    createAutoRule: '자동화 규칙 생성',
    editAutoRule: '자동화 규칙 편집',
    choose: '고르다',
    chooseAutoRule: '자동화 수준과 항목을 선택하십시오.',
    conditions: '정황',
    actions: '행위',
    settings: '설정',
    condition: '질환',
    addCondition: '조건 추가',
    andCondition:
      '위의 모든 조건이 일치해야 하는지 아니면 하나만 일치해야 하는지 선택하십시오.',
    checkCond: '조건 확인',
    action: '동작',
    selectAll: '모두 선택',
    enterEmail: '올바른 이메일 ID를 입력하세요.',
    addTo: '추가',
    selectAdgroups: '광고그룹 선택',
    selectCampaigns: '캠페인 선택',
    cptBid: 'CPT 입찰',
    customBid: '맞춤 입찰가',
    matchType: '일치 유형',
    addAction: '작업 추가',
    chckFreq: '주파수 확인',
    ruleName: '규칙 이름',
    cancel: '취소',
    step2: '2 단계',
    step3: '3단계',
    andOrTypesText1: '모두 일치해야 합니다.',
    andOrTypesText2: '하나만 일치해야 합니다.',
    addToModelListNegText1: '선택한 모든 광고그룹',
    addToModelListNegText2: '선택한 캠페인의 모든 광고 그룹)',
    addToModelListNegText3: '선택한 모든 캠페인',
    matchTypesText1: '확장검색',
    matchTypesText2: '정확히 일치',
    cptBidItemsText1: '사용자 지정으로 설정',
    cptBidItemsText2: '광고그룹 기본 입찰가',
    actionFreqsText1: '매시간',
    actionFreqsText2: '6시간마다',
    actionFreqsText3: '12시간마다',
    actionFreqsText4: '매일',
    actionFreqsText5: '사용자 지정 시간',
    limitActionFreqsText1: '2시간마다',
    limitActionFreqsText2: '3시간마다',
    limitActionFreqsText3: '2일마다',
    limitActionFreqsText4: '3일마다',
    limitActionFreqsText5: '매주',
    mon: '월요일',
    tue: '화요일',
    wed: '수요일',
    thu: '목요일',
    fri: '금요일',
    sat: '토요일',
    sun: '일요일',
    ciTimeRangesText1: '지난 1시간',
    ciTimeRangesText2: '지난 1일',
    ciTimeRangesText3: '지난 7일',
    ciTimeRangesText4: '지난 30일',
    ciTimeRangesText5: '지난 달',
    ciTimeRangesText6: '사용자 지정 시간',
    allConOperationText1: '보다 큰',
    allConOperationText2: '미만',
    allConOperationText3: '범위',
    allConOperationText4: '범위에 들지 않다',
    amount: '$ 금액',
    budget: '예산 비율 %',
    incBy: '감소',
    decBy: '증가',
    setToCPA: '평균 CPA로 설정',
    setToCPT: '평균 CPT로 설정',
    value: '$ 가치',
    change: '% 변경',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: '계정 선택',
    cancel: '취소',
    apply: '대다',
    clearAll: '모두 지우기',
    accounts: '계정',
    accountSelected: '선택한 계정',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: '앱 선택',
    cancel: '취소',
    apply: '대다',
    apps: '앱',
    appSelected: '선택한 앱',
    clearAll: '모두 지우기',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: '계속하려면 하나의 광고 그룹만 선택하십시오.',
    close: '닫기',
    chooseKeywords: '키워드 선택',
    cancel: '취소',
    apply: '대다',
    campaigns: '캠페인',
    expandAll: '모두 확장',
    collapseAll: '모든 축소',
    adGroups: '광고그룹',
    keywords: '키워드',
    keywordSelected: '선택한 키워드',
    clearAll: '모두 지우기',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: '크리에이티브 세트 추가',
    setup: '설정',
    para:
      'Creative Set의 테마를 설명하는 이름을 선택합니다. App Store 제품 페이지에서 포함하려는 스크린샷 및 미리보기의 언어를 선택하십시오.',
    createSetName: '크리에이티브 세트 이름',
    localLang: '현지화 언어',
    assetSelection: '자산 선택',
    assetSelectionPararaph1:
      'Creative Sets는 App Store 제품 페이지에 표시되는 스크린샷과 앱 미리보기로 구성됩니다.',
    assetSelectionPararaph2: '각 iPhone 디스플레이 크기에 대해',
    assetSelectionPararaph3:
      '하나 이상의 가로 또는 세 개의 세로 자산을 선택합니다.',
    assetSelectionPararaph4: '각 iPad 디스플레이 크기에 대해',
    assetSelectionPararaph5:
      '하나 이상의 가로 또는 두 개의 세로 자산을 선택합니다.',
    title1: 'iPhone/iPod',
    screenshotsPara1: '적어도 선택하십시오',
    screenshotsPara2: '하나의 풍경',
    screenshotsPara3: '또는',
    screenshotsPara4: '두 초상화',
    screenshotsPara5: '스크린샷!',
    screenshotsPara6: '적어도 선택하십시오',
    screenshotsPara7: '하나의 풍경',
    screenshotsPara8: '또는',
    screenshotsPara9: '세 초상화',
    screenshotsPara10: '스크린샷!',
    cancel: '취소',
    save: '저장',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: '캠페인 삭제',
    para: '캠페인을 삭제하시겠습니까?',
    cancel: '취소',
    delete: '삭제',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: '캠페인 편집 마법사',
    campName: '캠페인 이름',
    app: '앱',
    countriesOrRegionsPara: '하나 이상의 국가 또는 지역을 선택하십시오.',
    budget: '예산',
    dailyCap: '일일 한도',
    cancel: '취소',
    save: '저장',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: '캠페인 선택',
    noSelectedCamp: '선택한 캠페인이 없습니다.',
    campaigns: '캠페인',
    expandAll: '모두 확장',
    collapseAll: '모든 축소',
    selectedCampaigns: '선택한 캠페인',
    clearAll: '모두 지우기',
    cancel: '취소',
    apply: '대다',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: '신용카드 수정',
    cardNumber: '카드 번호',
    expiryDate: '만료일',
    cvv: 'CVV',
    saveCard: '저장 카드',
    updateExpiryPara: '* 만료 날짜만 업데이트할 수 있습니다.',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: '크리에이티브 세트 수정',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: '설정',
    editCreativePara1:
      'Creative Set의 테마를 설명하는 이름을 선택합니다. App Store 제품 페이지에서 포함하려는 스크린샷 및 미리보기의 언어를 선택하십시오.',
    creativeSetName: '크리에이티브 세트 이름',
    localLang: '현지화 언어',
    assetSelection: '자산 선택',
    assetSelectionPararaph1:
      'Creative Sets는 App Store 제품 페이지에 표시되는 스크린샷과 앱 미리보기로 구성됩니다.',
    assetSelectionPararaph2: '각 iPhone 디스플레이 크기에 대해',
    assetSelectionPararaph3:
      '하나 이상의 가로 또는 세 개의 세로 자산을 선택합니다.',
    assetSelectionPararaph4: '각 iPad 디스플레이 크기에 대해',
    assetSelectionPararaph5:
      '하나 이상의 가로 또는 두 개의 세로 자산을 선택합니다.',
    viewDevice: '장치 디스플레이 크기 보기',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: '표시하다',
    default: '기본',
    cancel: '취소',
    save: '저장',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: '통합 삭제',
    cardText: '통합을 삭제하시겠습니까?',
    cancel: '취소',
    delete: '삭제',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: '키워드 추가',
    keywordSuggestionsFor: '키워드 제안',
    on: '~에',
    addedKeywords: '추가된 키워드',
    total: '총',
    add: '추가하다',
    cancel: '취소',
    apply: '대다',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: '키워드 추가',
    selectTarget: '대상 선택',
    h4Camp: '캠페인:',
    selected: '선택된',
    change: '변화',
    h4Adgroups: '광고그룹:',
    maxCPTBid: '최대 CPT 입찰가:',
    keywordSuggestionsFor: '키워드 제안',
    on: '~에',
    recommendedKeywords: '추천 키워드',
    addedKeywords: '추가된 키워드',
    add: '추가하다',
    total: '총',
    cancel: '취소',
    apply: '대다',
    ok: '확인',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: '키워드 추가',
    selectTarget: '대상 선택',
    h4Camp: '캠페인:',
    selected: '선택된',
    change: '변화',
    h4Adgroups: '광고그룹:',
    maxCPTBid: '최대 CPT 입찰가:',
    keywordSuggestionsFor: '키워드 제안',
    on: '~에',
    recommendedKeywords: '추천 키워드',
    addedKeywords: '추가된 키워드',
    add: '추가하다',
    total: '총',
    cancel: '취소',
    apply: '대다',
    ok: '확인',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: '제외 키워드 추가',
    add: '추가하다',
    total: '총',
    cancel: '취소',
    save: '저장',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: '입찰 금액',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: '계속하려면 하나의 광고 그룹만 선택하십시오.',
    close: '닫기',
    chooseAdGroup: '광고그룹 선택',
    cancel: '취소',
    apply: '대다',
    campaigns : '캠페인',
    expandAll: '모두 확장',
    collapseAll: '모든 축소',
    adGroups : '광고그룹',
    adGroupSelected : '선택한 광고그룹',
    clearAll : '모두 지우기'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : '제외 키워드 추가',
    selectTarget : '1. 대상 선택',
    addNegKeyword : '제외 키워드 추가',
    paragraph1 : '캠페인 또는 광고 그룹에 제외 키워드를 추가할 수 있습니다. 아래 상자에서 그 중 하나를 선택하십시오.',
    paragraph2 : '어떤 캠페인에 키워드를 업로드하시겠습니까?',
    selectCampaign : '캠페인 선택',
    paragraph3 : '어떤 광고그룹에 키워드를 업로드하시겠습니까?',
    selectAdgroup : '광고그룹 선택',
    cancel: '취소',
    easywebinar : 'EasyWebinar', 
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : '캠페인:',
    selected : '선택된',
    change: '변화',
    paragraph4 : ' 광고그룹 제외 키워드는 이 광고그룹에만 적용됩니다. 제외 키워드를 모든 광고그룹에 적용하려면 캠페인 수준에서 설정하세요.',
    paragraph5 : ' 광고 실적을 분석하기 전에 캠페인을 실행할 시간을 주어 어떤 키워드가 귀하에게 적합하지 않을 수 있는지 알아보십시오. 제외 키워드를 너무 많이 추가하면 광고 노출이 제한될 수 있습니다.',
    addedKeywords : '추가된 키워드', 
    add: '추가하다',
    total: '총',
    save: '저장', 
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Slack 채널 추가',
  },

  //DecisionAlert
   decisionAlert : {
cancel : '취소',
ok : '확인',
  },

  //HelloWorld
  helloWorld : {
welcome : 'Vuetify에 오신 것을 환영합니다',
para1 : '다른 Vuetify 개발자와의 도움 및 협업을 위해,',
para2 : '우리의 온라인에 가입하십시오',
discord : '디스코드 커뮤니티',
h2 : "무엇 향후 계획?",
impLinks : '중요 링크',
ecosystem : '생태계',
  },

  //InfoAlert
  infoAlert : {
ok : '확인',
  },
}

