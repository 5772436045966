import { IAdGroup, IAudience, ICampaign, ICreativeSet, INegativeKeywords, ITargetKeywords, IkaiQuery, IDataRequests } from "@shared/interfaces";

/**
* @class LocalStorageService
* @description
* A wrapper class for working with localstorage.
*/
class LocalStorageService {

  public static set token(token: string) {
    this.set('token', token);
  }

  public static get token(): string {
    return this.get<string>('token');
  }

  // public static set teamId(teamId: string) {
  //   this.set('teamId', teamId);
  // }

  // public static get teamId(): string {
  //   return this.get<string>('teamId');
  // }

  // public static set email(email: string) {
  //   this.set('email', email);
  // }

  // public static get email(): string {
  //   return this.get<string>('email');
  // }

  // public static set userId(userId: string) {
  //   this.set('userId', userId);
  // }

  // public static get userId(): string {
  //   return this.get<string>('userId');
  // }

  public static set sessId(sessId: string) {
    this.set('sessId', sessId);
  }

  public static get sessId(): string {
    return this.get<string>('sessId');
  }

  public static set orgId(orgId: string) {
    this.set('orgId', orgId);
  }

  public static get orgId(): string {
    return this.get<string>('orgId');
  }

  public static set adamId(adamId: string) {
    this.set('adamId', adamId);
  }

  public static get adamId(): string {
    return this.get<string>('adamId');
  }

  public static get audience(): IAudience {
    return this.get<IAudience>('audience');
  }

  public static set audience(audience: IAudience) {
    this.set<IAudience>('audience', audience);
  }

  public static get adGroup(): IAdGroup {
    return this.get<IAdGroup>('adGroup');
  }

  public static set adGroup(adGroup: IAdGroup) {
    this.set<IAdGroup>('adGroup', adGroup);
  }

  public static get targetKeywords(): ITargetKeywords[] {
    return this.get<ITargetKeywords[]>('targetKeywords');
  }

  public static set targetKeywords(targetKeywords: ITargetKeywords[]) {
    this.set<ITargetKeywords[]>('targetKeywords', targetKeywords);
  }

  public static get keysFromKai(): ITargetKeywords[] {
    return this.get<ITargetKeywords[]>('keysFromKai');
  }

  public static set keysFromKai(keysFromKai: ITargetKeywords[]) {
    this.set<ITargetKeywords[]>('keysFromKai', keysFromKai);
  }

  public static get negativeKeywords(): INegativeKeywords[] {
    return this.get<INegativeKeywords[]>('negativeKeywords');
  }

  public static set negativeKeywords(negativeKeywords: INegativeKeywords[]) {
    this.set<INegativeKeywords[]>('negativeKeywords', negativeKeywords);
  }

  public static get campaign(): ICampaign {
    return this.get<ICampaign>('campaign');
  }

  public static set campaign(campaign: ICampaign) {
    this.set<ICampaign>('campaign', campaign);
  }

  public static get creativeSet(): ICreativeSet {
    return this.get<ICreativeSet>('creativeSet');
  }

  public static set creativeSet(creativeSet: ICreativeSet) {
    this.set<ICreativeSet>('creativeSet', creativeSet);
  }

  // public static get userDetails(): IUserDetails {
  //   return this.get<IUserDetails>('userDetails');
  // }

  // public static set userDetails(userDetails: IUserDetails) {
  //   this.set<IUserDetails>('userDetails', userDetails);
  // }

  public static get dataRequests(): IDataRequests[] {
    return this.get<IDataRequests[]>('dataRequests');
  }

  public static set dataRequests(dataRequests: IDataRequests[]) {
    this.set<IDataRequests[]>('dataRequests', dataRequests);
  }

  public static get kaiQuery(): IkaiQuery {
    return this.get<IkaiQuery>('kaiQuery');
  }

  public static set kaiQuery(kaiQuery: IkaiQuery) {
    this.set<IkaiQuery>('kaiQuery', kaiQuery);
  }

  /**
  * @function set
  * @description set a key value pair in localstorage
  * @param  {string} key
  * @param  {T} value
  * @returns {void}
  */
  public static set<T = any>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
  * @function get
  * @description get a value by key from localstorage
  * @param  {string} key
  * @returns {T} value
  */
  public static get<T = any>(key: string): T {
    return JSON.parse(localStorage.getItem(key) as string);
  }

  /**
  * @function clear
  * @description clears all values from localstorage
  * @returns {void}
  */
  public static clear(): void {
    localStorage.clear();
  }

  /**
  * @function clearOther
  * @description clears all values from localstorage except user details
  * @returns {void}
  */
  public static clearOther(): void {
    const sessId = this.sessId;
    const token = this.token;
    const keysFromKai = this.keysFromKai;
    const dataRequests = this.dataRequests;
    const kaiQuery = this.kaiQuery;

    localStorage.clear();

    this.sessId = sessId;
    this.token = token;
    this.keysFromKai = keysFromKai;
    this.dataRequests = dataRequests;
    this.kaiQuery = kaiQuery;
  }
}

export {
  LocalStorageService as LSS
}
