const API_BASE_URL = process.env.VUE_APP_API_URL;
const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
const API_GRAPHQL = process.env.VUE_APP_API_GRAPHQL;
const API_ORY_URL = process.env.VUE_APP_ORY_URL;
const API_WS_URL = process.env.VUE_APP_WS_URL;
const SYNCSERVICE_URL = process.env.VUE_APP_SYNCSERVICE_URL;
export {
  API_BASE_URL,
  SENTRY_DSN,
  API_GRAPHQL,
  SYNCSERVICE_URL,
  API_ORY_URL,
  API_WS_URL
};
