/** Config related constant values */

export const ORG_HEADER_NAME = 'san-org';
export const USER_HEADER_NAME = 'san-user';
export const TEAM_HEADER_NAME = 'san-team';
export const TOKEN_HEADER_NAME = 'san-token';
export const SESSION_HEADER_NAME = 'san-sess';

/** constant keys */
export const GQL_FIELDS_KEY = 'gqlFields';
export const GQL_FILTER_ARG_KEY = 'filter';
export const GQL_CONVERTED_FILTER_ARG_KEY = 'filterConverted';

export const LS_DRAFT_KEY = 'draftId';

export const DEFAULT_GRANULARITY = 'DAILY';
