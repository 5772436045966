const API_BASE = '/api/';
const API_V = `${API_BASE}`;
const USERS = `${API_V}users`;
const AUTH = `${API_V}auth`;
const ATTRIBUTIONS = `${API_V}attributions`;
const CHARGEBEE = `${API_V}chargebee`;
const DASHBOARD = `${API_V}dashboard`;
const ONBOARD = `${API_V}onboard`;
const CAMPAIGNS = `${API_V}campaigns`;
const REPORTS = `${API_V}reports`;
const INTEGRATION = `${API_V}integration`;
const TEAM_MANAGEMENT = `${API_V}team-management`;
const CAMPAIGN_GENERATOR = `${API_V}campaign-generator`;
const BASE_ROUTE = API_BASE;
const BASE_ROUTE_V = API_V;
const USER_ROUTE = {
  base: USERS,
  role: `${USERS}/role`,
  getAll: 'getAll',
  getById: 'getById',
  getByEmail: 'getByEmail',
  changePassword: 'change-password',
  updateProfile: 'update-profile',
  attribute: `${API_BASE}attribute`,
};

const AUTH_ROUTES = {
  base: AUTH,
  signupOry: 'signup-ory',
  signinOry: 'signin-ory',
  getFlow: 'get-flow',
  vupdate: 'vupdate',
  vLink: 'vlink',
  isOnBoarded: 'is-onboarded',
  getUserById: 'user-details',
  isInvited: 'is-invited/:email'
};

const ATTRIBUTIONS_ROUTES = {
  base: ATTRIBUTIONS,
  kochava: 'kochava',
};

const CHARGEBEE_ROUTES = {
  base: CHARGEBEE,
  picWh: 'pic-wh',
};

const DASHBOARD_ROUTES = {
  base: DASHBOARD,
  profile: 'profile',
};

const ONBOARD_ROUTES = {
  base: ONBOARD,
  NoVbase: 'onboard',
  save: 'save/:securityPrincipleID',
  addIntegration: 'add-integration'
}

const CAMPAIGN_ROUTES = {
  base: CAMPAIGNS,
  acls: 'acls',
  searchApp: 'search/:orgId/:appName',
  noVbase: 'campaigns',
  create: 'create',
  createAdroup: 'create-adgroup',
  update: '/:campaignId',
  delete: '/:campaignId',
  locations: 'locations/:orgId',
  ownapps: 'ownapps',
  creativeappassets: 'creativeappassets/:orgId',
  campaignGroup: 'campaign-group'
}

const INTEGRATION_ROUTES = {
  base: INTEGRATION
}

const TEAM_MANAGEMENT_ROUTES = {
  base: TEAM_MANAGEMENT,
  teamInvite: 'team-invite',
  addTeam: 'add-team',
  getAll: 'getAll'
}

const CAMPAIGN_GENERATOR_ROUTES = {
  base: CAMPAIGN_GENERATOR,
  createKeywords: 'create-keywords',
  createAdgroups: 'create-adgroups',
  getKeywords: 'get-keywords'
}

export {
  BASE_ROUTE,
  BASE_ROUTE_V,
  USER_ROUTE,
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  ONBOARD_ROUTES,
  CAMPAIGN_ROUTES,
  INTEGRATION_ROUTES,
  CHARGEBEE_ROUTES,
  TEAM_MANAGEMENT_ROUTES,
  CAMPAIGN_GENERATOR_ROUTES,
  ATTRIBUTIONS_ROUTES,
};
