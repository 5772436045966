var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header"
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v("Your Data Requests")
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-4" },
            [
              _c(
                "v-row",
                { staticClass: "my-1", attrs: { align: "center" } },
                [
                  _c(
                    "strong",
                    { staticClass: "mx-4 primary--text text--darken-2" },
                    [_vm._v(" Remaining: " + _vm._s(_vm.remainingTasks) + " ")]
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "strong",
                    { staticClass: "mx-4 success--text text--darken-2" },
                    [_vm._v(" Completed: " + _vm._s(_vm.completedTasks) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-progress-circular",
                    {
                      staticClass: "mr-3",
                      attrs: { value: _vm.progress, color: "success" }
                    },
                    [_vm._v(_vm._s(parseInt(_vm.progress)))]
                  )
                ],
                1
              ),
              _c("v-divider", { staticClass: "mt-4" }),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _vm._l(_vm.tasks, function(task, i) {
                    return [
                      _c(
                        "v-list-item",
                        { key: i + "-" + task.text },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "vcard",
                              style: task.disabled
                                ? "background:#e0e0e094; padding: 5px;margin-bottom: 5px;"
                                : "background:#fff; padding: 5px;margin-bottom: 5px;"
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v(_vm._s(task.icon))])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  task.disabled
                                    ? _c("v-list-item-title", [
                                        _c("a", [
                                          _vm._v(
                                            _vm._s(task.preText) +
                                              _vm._s(task.text) +
                                              " (" +
                                              _vm._s(task.fetched) +
                                              " / " +
                                              _vm._s(task.total) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  !task.disabled
                                    ? _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.taskClicked(task)
                                            }
                                          }
                                        },
                                        [
                                          _c("a", [
                                            _vm._v(
                                              _vm._s(task.preText) +
                                                _vm._s(task.text) +
                                                " (" +
                                                _vm._s(task.fetched) +
                                                " / " +
                                                _vm._s(task.total) +
                                                ")"
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item-subtitle",
                                    [
                                      _c("v-progress-linear", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          height: "3",
                                          value: task.progress,
                                          color: task.done
                                            ? "success"
                                            : "primary",
                                          stream: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !task.done
                                ? _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-circle-slice-2 mdi-spin ")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              task.done
                                ? _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteTask(
                                                i,
                                                task.done
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" mdi-delete ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }