// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import VueRouter, { RouteConfig } from 'vue-router';

import { FlowService } from '@services/flow.service';
import { LSS } from '@core/services';
import Vue from 'vue';
import { decodeToken } from '@shared/utils/commom.util'

const AddIntegration = () => import('../views/AddIntegration.vue')
const CBPoc = () => import('../views/CBPoc.vue')
const CampaginView = () => import(/* webpackChunkName: "group-createcampaign" */ '../views/CampaignView.vue')
const ChangePassword = () => import('../views/ChangePassword.vue')
const CreateCampaign = () => import(/* webpackChunkName: "group-createcampaign" */ '../views/CreateCampaign.vue')
const Dashboard = () => import('../views/Dashboard.vue')
const ForgotPassword = () => import('../views/ForgotPassword.vue')
const KeywordAuctionInsights = () => import('../views/kai.vue')
const LoginProxy = () => import('../views/LoginProxy.vue')
const Logout = () => import('../views/Logout.vue')
const Onboard = () => import('../views/Onboard.vue')
const OnboardFork = () => import('../views/OnboardFork.vue')
const OrganicKeywordRanking = () => import('../views/OrganicKeywordRanking.vue')
const Overview = () => import('../views/Overview.vue')
const SAIntegration = () => import('../views/SAIntegration.vue')
const SearchTabCampaign = () => import(/* webpackChunkName: "group-createcampaign" */ '../views/SearchTabCampaign.vue')
const Settings = () => import('../views/Settings.vue')
const SignUp = () => import('../views/SignUp.vue')
const TeamManagement = () => import('../views/TeamManagement.vue')
const Verify = () => import('../views/Verify.vue')
const Signin = () => import('../views/Signin.vue');
const KeywordOptimization = () => import('../views/KeywordOptimization.vue');
const ARRules = () => import('../views/ARRules.vue');
const SlackIntegration = () => import('../views/SlackIntegration.vue');
const AppSettings = () => import('../views/AppSettings.vue')


Vue.use(VueRouter);

async function CheckSession(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    }
    else{
      if(decodeToken(LSS.token).FirstName == ''){
        next('/onboard');
      }
      next();
    }
  })
  .catch((err) => {
    //console.log(err)
    next('/login');
  });
}

async function CheckSession2(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    }
    next();
  })
  .catch((err) => {
    //console.log(err)
    next('/login');
  });
}

const routes: Array<RouteConfig> = [

  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginProxy
  },
  {
    path: '/onboard-fork',
    name: 'onboard-fork',
    component: OnboardFork
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/onboard',
    name: 'onboard',
    //beforeEnter: CheckSession2,
    component: Onboard
  },
  {
    path: '/ads-manager',
    name: 'dashboard',
    //beforeEnter: CheckSession,
    component: Dashboard
  },
  {
    path: '/create-campaign',
    name: 'create-campaign',
    //beforeEnter: CheckSession,
    component: CreateCampaign
  },
  {
    path: '/integrations',
    name: 'add-integration',
    //beforeEnter: CheckSession,
    component: AddIntegration
  },
  {
    path: '/cb-poc',
    name: 'cb-poc',
    //beforeEnter: CheckSession,
    component: CBPoc
  },
  {
    path: '/keyword-auction-insights',
    name: 'keyword-auction-insights',
    //beforeEnter: CheckSession,
    component: KeywordAuctionInsights
  },
  {
    path: '/organic-keyword-ranking',
    name: 'organic-keyword-ranking',
    //beforeEnter: CheckSession,
    component: OrganicKeywordRanking,
  },

  {
    path: '/settings',
    name: 'settings',
    //beforeEnter: CheckSession,
    component: Settings,
  },
  {
    path: '/overview',
    name: 'overview',
    //beforeEnter: CheckSession,
    component: Overview
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/team-management',
    name: 'team-management',
    //beforeEnter: CheckSession,
    component: TeamManagement
  },
  {
    path: '/searchtab-campaign',
    name: 'searchtab-campaign',
    //beforeEnter : CheckSession,
    component: SearchTabCampaign
  },
  {
    path: '/CampaginView',
    name: 'CampaginView',
    //beforeEnter : CheckSession,
    component: CampaginView
  },
  {
    path: '/sa-integration/auth/cb',
    name: 'sa-integration',
    //beforeEnter: CheckSession,
    component: SAIntegration
  },
  {
    path: '/arrules',
    name: 'arrules',
    //beforeEnter: CheckSession,
    component: ARRules
  },
  // {
  //   path: '/rules',
  //   name: 'rules',
  //   //beforeEnter: CheckSession,
  //   component: Rule
  // },
  {
    path: '/slack-integration/auth/cb',
    name: 'slack-integration',
    //beforeEnter: CheckSession,
    component: SlackIntegration
  },
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/keyword-optimization',
    name: 'KeywordOptimization',
    //beforeEnter: CheckSession,
    component: KeywordOptimization
  },
  {
    path: '/app-settings',
    name: 'AppSettings',
    //beforeEnter: CheckSession,
    component: AppSettings
  },
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const publicPages = ['/login', '/signup']
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = localStorage.getItem('user')
//   if (authRequired && !loggedIn) {
//     return next('/login')
//   }
//   next()
// })

export default router;
