//Spanish
export const es = {
    pages: {
        overview: 'Descripción general',
        adsManager: 'Administrador de anuncios',
        kai: 'Análisis de palabras clave',
        keyOpt: 'Optimización de palabras clave',
        teamMgmt: 'Equipo',
        integrations: 'Integraciones',
        automationRule: 'Automatización'
    },
    menu: {
        settings: 'Ajustes',
        signOut: 'Desconectar'
    },
    buttons: {
        syncData: 'Sincronizar los datos más recientes',
        createCampaign: 'Crear campaña',
        filter: 'Filtrar',
        reset: 'Reiniciar',
        apply: 'Solicitar'
    },
    labels: {
        charts: 'Gráficos',
        selectDs: 'Seleccionar conjuntos de datos',
        totalSummary: 'Resumen total',
        spend: 'Gastar'
    },
    dropdowns: {
        noData: 'Datos no disponibles',
        daily: 'DIARIA',
        weekly: 'SEMANAL',
        monthly: 'MENSUAL'
    },
    tabs: {
        accounts: 'Cuentas',
        apps: 'Aplicaciones',
        campaigns: 'Campañas',
        adgroups: 'Grupos de anuncios',
        keywords: 'Palabras clave',
        creativeSets: 'Conjuntos creativos',
        searchTerms: 'Términos de búsqueda',
        negKeywords: 'Palabras clave negativas'
    },
    summary: {
        taps: 'Grifos',
        impressions: 'Impresiones',
        ttr: 'Tasa de derivación',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        installs: 'Instala',
    },
    accountHeaders: {
        account: 'Cuenta',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas'
    },
    appsHeaders: {
        appName: 'Solicitud',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstall: 'Instalación de atributos',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRevenue: 'Ingresos de objetivos',
        goalsRoas: 'ROAS de objetivos',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        account: 'Cuenta',
        categoryRank: 'Rango de categoría',
        appId: 'ID de aplicación',
    },
    campaignHeaders: {
        campaign: 'Campaña',
        campaignStatus: 'Estado de la campaña',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRoas: 'ROAS de objetivos',
        storefronts: 'Escaparates',
        account: 'Cuenta',
        displayStatus: 'Estado de la pantalla',
        appName: 'Nombre de la aplicación',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        delete: 'Borrar'
    },
    campaignDraftHeaders: {
        campaignName: 'Nombre de campaña',
        countries: 'Países',
        budgetAmount: 'Cantidad de presupuesto',
        resume: 'Reanudar',
    },
    adGroupHeaders: {
        adgroup: 'Grupo de anuncios',
        adgroupStatus: 'Estado del grupo de anuncios',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRoas: 'ROAS de objetivos',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        displayStatus: 'Estado de la pantalla',
        account: 'Cuenta',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de grupo de anuncios'
    },
    keyHeaders: {
        keyword: 'Palabra clave',
        keywordStatus: 'Estado de la palabra clave',
        bidAmount: 'Importe de la oferta',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRevenue: 'Ingresos de objetivos',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        adgroupStatus: 'Estado del grupo de anuncios',
        organicRank: 'Rango orgánico',
        appName: 'Nombre de la aplicación',
        difficultyScore: 'Puntuación de dificultad',
        popularity: 'Popularidad',
        keywordId: 'ID de palabra clave',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de grupo de anuncios',
        suggestedMinBid: 'ASA Oferta mínima sugerida',
        suggestedMaxBid: 'ASA Oferta máxima sugerida',
        bidStrength: 'Fuerza de oferta'
    },
    creativeHeaders: {
        creativeSet: 'Conjunto creativo',
        creativeSetStatus: 'Estado',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRevenue: 'Ingresos de objetivos',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        adgroupStatus: 'Estado del grupo de anuncios',
        account: 'Cuenta',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de grupo de anuncios',
    },
    searchTermsHeader: {
        searchTerm: 'Texto del término de búsqueda',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        adgroupStatus: 'Estado del grupo de anuncios',
        keyword: 'Palabra clave',
        keywordId: 'ID de palabra clave',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de grupo de anuncios',
    },
    negativeHeaders: {
        keyword: 'Palabra clave',
        matchType: 'Tipo de concordancia',
        status: 'Estado',
        adgroupId: 'ID de grupo de anuncios',
        campaignId: 'ID de campaña',
        deleted: 'Eliminada',
        keywordId: 'ID de palabra clave',
        modificationTime: 'Hora de modificación'
    },
    filter: {
        account: 'Seleccionar cuenta',
        app: 'Seleccionar aplicación',
        campaign: 'Seleccionar campaña',
        adgroup: 'Seleccionar grupo de anuncios',
    },
    subTabs: {
        live: 'Vivir',
        paused: 'Pausada',
        deleted: 'Eliminada',
        draft: 'Sequía',
        campaigns: 'Campañas',
        adgroups: 'Grupos de anuncios',
        keywords: 'Palabras clave',
        creativeSet: 'Conjunto creativo',
        negKey: 'Palabras clave negativas'
    },
    popups: {
        add: 'Agregar',
        integration: 'Integración',
        adgroup: 'Grupo de anuncios',
        keywords: 'Palabras clave',
        creativeSet: 'Conjunto creativo',
        negKey: 'Palabras clave negativas'
    },

    //Team Management Page
    teamMgmt: {
        manageUsers: 'Administrar usuarias',
        invite: 'Invitar y administrar miembros del equipo',
        add: 'Añadir miembro',
        first: 'Primer nombre',
        last: 'Apellido',
        phone: 'Teléfono',
        email: 'Correo electrónico',
        job: 'Título profesional',
        allMembers: 'Todas las miembros',
        processing: 'Procesando...',
        ok: 'OK',
        inviteStatus: 'Estado de la invitación',
        isDisabled: 'Está desactivado',
        company: 'nombre de empresa'
    },

    //Add Integration Page
    integration: {
        assPart: 'Socios asociados',
        apple: 'Anuncios de búsqueda de Apple',
        slack: 'Slack',
        itunes: 'Itunes',
        tenjin: 'Tenjin',
        kochava: 'Kochava',
        adjust: 'Adjust',
        appsFlyer: 'AppsFlyer',
        singular: 'Singular'
    },

    //Keyword Auction Insights Page
    kai: {
        addedKeywords: 'Palabras clave agregadas',
        total: 'Total',
        cancel: 'Cancelar',
        runAds: 'Ejecutar anuncios',
        ok: 'OK',
        totalPaidKey: 'Palabras clave pagas totales',
        popularity: 'Popularidad',
        numOfApps: 'Números de aplicación',
        chance: 'Oportunidad',
        appsRunAds: 'Aplicaciones que ejecutan anuncios',
        maxPop: 'Máxima popularidad para el tuyo',
        keyword: 'Palabra clave',
        clear: 'Clara',
        clrAllFilter: 'Borrar todo el filtro',
        keywords: 'Palabras clave',
        organicRank: 'Rango orgánico',
        totalApps: 'Aplicaciones totales',
        app: 'Aplicación',
        catName: 'nombre de la categoría',
        searchByApp: 'Buscar por aplicación',
        searchByKey: 'buscar por palabra clave',
        addAllKey: 'Agregar todas las palabras clave'
    },

    //Overview Page
    overview: {
        period: 'período',
        topKeywords: '10 palabras clave principales por',
        key: 'Palabra clave',
        spend: 'Gastar',
        impressions: 'Impresiones',
        taps: 'Grifos',
        goToKey: 'Ir a Todas las palabras clave',
        topAdgroup: 'Los 10 grupos de anuncios principales por',
        adgroup: 'Grupo de anuncios',
        goToAdgroup: 'Ir a todos los grupos de anuncios',
        gender: 'Género',
        device: 'Dispositivo',
        searchApp: 'Aplicación de búsqueda',
        lastDay: 'Último día',
        last7Days: 'Los últimos 7 días',
        last30Days: 'Últimos 30 días',
        ttr: 'Tasa de derivación',
        installs: 'Instala',
        avgCPA: 'Promedio CPA',
        avgCPT: 'Promedio CPT',
        conversionRate: 'Tasa de conversión',
        latOnInstalls: 'LAT en instalaciones',
        latOffInstalls: 'LAT Off Instalaciones',
        reDownloads: 'Re-descargas',
        newDownloads: 'Nuevas descargas',
        all: 'Todas',
        tooltip: 'Muestra la diferencia de datos como porcentaje según el período anterior. El período anterior es el intervalo de fechas seleccionado anteriormente.'
    },

    //Keyword Optimization Page
 keyOpt: {
    keyBid: 'Optimización de ofertas de palabras clave',
    keyToolTip: 'Puede optimizar el monto de la oferta de las palabras clave que no están en el rango del monto de la oferta mínima sugerida por ASA y el monto de la oferta máxima sugerida por el ASA.',
    optAll: 'Optimizar todo',
    opt: 'Optimizar',
    optBidAmt: 'Optimizar el monto de la oferta',
    key: 'Palabra clave',
    optBidForAll: 'Optimizar la cantidad de oferta de todas las palabras clave',
    campaign: 'Campaña',
    adgroup: 'Grupo de anuncios',
    bidAmt: 'Importe de la oferta',
    asaMinBid: 'Oferta mínima sugerida por ASA',
    asaMaxBid: 'Oferta máxima sugerida de ASA',
    action: 'Acción'
    },
    
    //ARRules Page
    aRules: {
    createAR: 'Crear regla de automatización',
    view: 'Vista',
    edit: 'Editar',
    delete: 'Borrar',
    disable: 'Desactivar',
    enable: 'Permitir',
    name: 'Nombre',
    createdAt: 'Creado en',
    status: 'Estado',
    completed: 'Completado en',
    actionFreq: 'Frecuencia de acción',
    rules: 'Reglas',
    logs: 'Registros',
    createdFor: 'Creado para',
    chckFreq: 'Verificar frecuencia',
    actions: 'Comportamiento'
    },
   
    //Create Campaign
    //CampaignView
    createCampaign: {
    createCamp: 'Crear campaña',
    h2: 'Elija una aplicación para comenzar a crear una campaña',
    para: 'Las campañas tienen presupuestos y grupos de anuncios que se enfocan en un tema o estrategia común para promocionar su aplicación.',
    select: 'Seleccione dónde se publicarán los anuncios',
    searchResults: 'Resultados de la búsqueda',
    srData: 'Las campañas tienen presupuestos y grupos de anuncios que se enfocan en un tema o estrategia común para promocionar su aplicación.',
    searchTab: 'Pestaña de búsqueda',
    stData: 'Promocione su aplicación en la parte superior de la lista de aplicaciones sugeridas cuando los usuarios visiten la pestaña Buscar.',
    cancel: 'Cancelar',
    continue: 'Continuar',
    
    //Search Results and Search Tab Campaign
    countAndReg: 'Paises y Regiones',
    adgroupSettings: 'Configuración del grupo de anuncios',
    searchMatch: 'Coincidencia de búsqueda',
    key: 'Palabra clave',
    audience: 'Audiencia',
    crSets: 'Conjuntos creativos',
    chooseCampGroup: 'Elija un grupo de campaña',
    chooseApp: 'Elija una aplicación',
    next: 'Próximo',
    countPara: 'Su aplicación se promocionará en la App Store en los países y regiones elegibles que elija de la lista.',
    chooseCount: 'Elija uno o más países o regiones',
    campName: 'Nombre de campaña',
    budget: 'Presupuesto',
    dailyCap: 'Límite diario (opcional)',
    adgroupPara: 'Los grupos de anuncios contienen objetivos y configuraciones de precios que determinan a quién mostrar su anuncio.',
    adgroupName: 'Nombre del grupo de anuncios',
    devices: 'Dispositivos',
    startTime: 'Hora de inicio',
    adScheduling: 'Programación de anuncios',
    endTime: 'Hora de finalización',
    dayParting: 'Partición del día (opcional)',
    ok: 'OK',
    defaultMax: 'Oferta de CPT máx. Predeterminada',
    cpaGoal: 'Objetivo de CPA (opcional)',
    saveDraft: 'Guardar como borrador',
    appleSearchMatch: 'Coincidencia de búsqueda de Apple',
    searchMatchPara: 'Apple Search Match es la forma más sencilla de poner en marcha sus anuncios. Los anuncios de búsqueda harán coincidir automáticamente su anuncio con los usuarios que buscan aplicaciones como la suya.',
    searchMatchPara1: 'Hacer coincidir automáticamente mi anuncio con búsquedas relevantes',
    keywords: 'Palabras clave',
    keyPara: 'Las palabras clave son palabras o términos relevantes que los clientes pueden usar cuando buscan una aplicación como la suya. Las palabras clave permiten a los usuarios encontrar su aplicación rápidamente, lo que ayuda a impulsar las instalaciones de la aplicación.',
    upload: 'Subir',
    suppFormat: 'formatos compatibles: Excel, CSV',
    keyManually: 'O agregue palabras clave manualmente',
    targKey: 'Orientación por palabras clave',
    clear: 'Claro',
    adgroupNegKey: 'Palabras clave negativas del grupo de anuncios',
    optional: 'Opcional',
    negKey: 'Palabras clave negativas',
    audPara: ' Defina mejor la audiencia de su grupo de anuncios mediante funciones avanzadas opcionales.',
    custType: 'tipo de cliente',
    demo: 'Demografía',
    gender: 'Género',
    location: 'Localización',
    crSetPara: 'Los anuncios se crean automáticamente utilizando los metadatos, las capturas de pantalla y las vistas previas de la aplicación que se proporcionan en la página del producto de la App Store. Estos también aparecen en los resultados de búsqueda orgánicos en la App Store. Los anuncios que se muestran aquí son ejemplos de anuncios predeterminados.',
    crOptional: 'Conjunto creativo (opcional)',
    noCrSet: 'La aplicación no cumple con el requisito de Creative Set mínimo',
    campCreated: 'Campaña creada',
    campCreatedPara: 'Ahora, puede ver sus campañas en el panel de anuncios de búsqueda.',
    close: 'Cerca',
    matchType: 'Tipo de concordancia',
    amount: 'Monto',
    currency: 'Divisa',
    allUsers: 'Todos los usuarios',
    newUsers: 'Nuevos usuarios',
    appUsers: 'Usuarios de mis otras aplicaciones',
    existingUsers: 'Usuarios existentes',
    all: 'Todas',
    male: 'Masculino',
    female: 'Mujer'
    },

    
  //Change Password
  changePass: {
    backSett: 'Volver a la configuración',
    chngPass: 'Cambiar la contraseña',
    newPass: 'Nueva contraseña',
    confirmPass: 'confirmar Contraseña',
    submit: 'Entregar',
    h4: 'El sistema operativo Kochava Marketers ™',
    para:
      'Marketers Operating System (m / OS) integra a la perfección soluciones de marketing omnicanal para anunciantes y editores en una plataforma operativa.',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Has olvidado tu contraseñaHas olvidado tu contraseña',
    typeEmail: 'Escriba la dirección de correo electrónico',
    emailAdd: 'Dirección de correo electrónico',
    submit: 'Entregar',
    chckEmail: 'Por favor revise su correo electrónico',
    para: 'Para establecer una nueva contraseña, consulte su correo electrónico',
    close: 'Cerrar',
    alreadyAcc: '¿Ya tienes una cuenta?',
    signIn: 'Registrarse',
  },

  //Onboard
  onboard: {
    welcome: 'Bienvenido a kochava',
    personal: 'Personal',
    start: 'Comienzo',
    integration: 'Integración',
    next: 'Próximo',
    trial: 'Comience su prueba',
    scheduleDemo: 'O programe una demostración con nosotros y descubra juntos',
    addInt: 'Agrega una integración.',
    signInApple:
      'Inicie sesión con Apple para agregar una integración de Apple Search Ads. Tenga en cuenta:',
    step1:
      '1. Es necesario tener derechos de administrador para otorgar acceso a la cuenta de Apple Search Ads.',
    step2:
      '2. Para administrar sus campañas desde SearchAdsNinja, seleccione la opción Leer y escribir antes de otorgar acceso.',
    skip: 'Saltar',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Agregar grupo de anuncios',
    para:
      'Aún no ha seleccionado su campaña. Puede elegirlo en el cuadro de abajo.',
    camGroup: 'Grupo de campaña',
    campaign: 'Campaña',
    adgroupSett: 'Configuración del grupo de anunciosConfiguración del grupo de anuncios',
    para1:
      'Los grupos de anuncios contienen objetivos y configuraciones de precios que determinan a quién mostrar su anuncio.',
    adgroupName: 'Nombre del grupo de anuncios',
    devices: 'Dispositivos',
    adScheduling: 'Programación de anuncios',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    dayparting: 'Partición del día (opcional)',
    defaultMax: 'Oferta de CPT máx. Predeterminada',
    cptTool:
      'Esta es la cantidad máxima que está dispuesto a pagar por un toque en su anuncio. Esta oferta se aplicará a todas las palabras clave de este grupo de anuncios, a menos que aplique una oferta de palabra clave individual.',
    cpaGoal: 'Objetivo de CPA (opcional)',
    cpaTool:
      'El promedio que ha pagado por cada descarga. Esto se calcula dividiendo el total gastado por el número de descargas.',
    searchMatch: 'Coincidencia de búsqueda',
    searchTool:
      'Search Match es la forma más sencilla de poner en marcha sus anuncios. Los anuncios de búsqueda harán coincidir automáticamente su anuncio con los usuarios que buscan aplicaciones como la suya.',
    searchPara: 'Hacer coincidir automáticamente mi anuncio con búsquedas relevantes',
    key: 'Palabras clave',
    keyPara:
      'Las palabras clave son palabras o términos relevantes que los clientes pueden usar cuando buscan una aplicación como la suya. Las palabras clave permiten a los usuarios encontrar su aplicación rápidamente, lo que ayuda a impulsar las instalaciones de la aplicación.',
    inputCpt: 'Ingrese la oferta de CPT máxima predeterminada para activar la sección de palabras clave',
    addKeyPara:
      'Agregue palabras clave relevantes para su aplicación y género. Te ayudaremos compartiendo recomendaciones adicionales.',
    upload: 'Subir',
    addKeyManually: 'O agregue palabras clave manualmente',
    negKey: 'Palabras clave negativas del grupo de anuncios (opcional)',
    supp: 'formatos compatibles: Excel, CSV',
    demAge: 'Edad demográfica, género',
    demPara: "Oriente su anuncio según la edad o el sexo de su usuario",
    ageRange: 'Rango de edad',
    cancel: 'Cancelar',
    apply: 'Solicitar',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Editar grupo de anuncios',
    adgroup: 'Grupo de anuncios',
    advance: 'Configuraciones avanzadas',
    viewEdit: 'Ver y editar',
    general: 'General',
    settings: 'Ajustes',
    schedule: 'Calendario',
    custPara: 'Seleccione los clientes de la aplicación a los que desea llegar',
    selectAge: 'Seleccionar rango de edad',
    save: 'Salvar',
  },

  //Create AR
  createAR: {
    createAutoRule: 'Crear regla de automatización',
    editAutoRule: 'Editar regla de automatización',
    choose: 'Escoger',
    chooseAutoRule: 'Elija el nivel de automatización y los elementos.',
    conditions: 'Condiciones',
    actions: 'Comportamiento',
    settings: 'Ajustes',
    condition: 'Condición',
    addCondition: 'Agregar condición',
    andCondition:
      'Elija si todas las condiciones anteriores deben coincidir o solo una de ellas debe coincidir',
    checkCond: 'Consultar condiciones para',
    action: 'Acción',
    selectAll: 'Seleccionar todo',
    enterEmail: 'Ingrese los ID de correo electrónico adecuados',
    addTo: 'Añadir',
    selectAdgroups: 'Seleccionar grupos de anuncios',
    selectCampaigns: 'Seleccionar campañas',
    cptBid: 'Oferta de CPT',
    customBid: 'Oferta personalizada',
    matchType: 'Tipo de concordancia',
    addAction: 'Agregar acción',
    chckFreq: 'Verificar frecuencia',
    ruleName: 'Nombre de la regla',
    cancel: 'Cancelar',
    step2: 'Paso 2',
    step3: 'Paso 3',
    andOrTypesText1: 'Todo debe coincidir',
    andOrTypesText2: 'Solo uno debe coincidir',
    addToModelListNegText1: 'Todos los grupos de anuncios seleccionados',
    addToModelListNegText2: 'Todos los grupos de anuncios de las campañas seleccionadas)',
    addToModelListNegText3: 'Todas las campañas seleccionadas',
    matchTypesText1: 'Coincidencia amplia',
    matchTypesText2: 'Coincidencia exacta',
    cptBidItemsText1: 'Establecer como personalizado',
    cptBidItemsText2: 'Oferta predeterminada del grupo de anuncios',
    actionFreqsText1: 'Cada hora',
    actionFreqsText2: 'Cada 6 horas',
    actionFreqsText3: 'Cada 12 horas',
    actionFreqsText4: 'Todos los días',
    actionFreqsText5: 'Horas personalizadas',
    limitActionFreqsText1: 'Cada 2 horas',
    limitActionFreqsText2: 'Cada 3 horas',
    limitActionFreqsText3: 'Cada 2 dias',
    limitActionFreqsText4: 'Cada 3 dias',
    limitActionFreqsText5: 'Cada semana',
    mon: 'lunes',
    tue: 'martes',
    wed: 'miércoles',
    thu: 'jueves',
    fri: 'viernes',
    sat: 'sábado',
    sun: 'domingo',
    ciTimeRangesText1: 'Última 1 hora',
    ciTimeRangesText2: 'Último 1 día',
    ciTimeRangesText3: 'Los últimos 7 días',
    ciTimeRangesText4: 'Últimos 30 días',
    ciTimeRangesText5: 'El mes pasado',
    ciTimeRangesText6: 'Hora personalizada',
    allConOperationText1: 'Mas grande que',
    allConOperationText2: 'Menos que',
    allConOperationText3: 'Abarcar',
    allConOperationText4: 'Fuera de rango',
    amount: '$ Cantidad',
    budget: '% Porcentaje del presupuesto',
    incBy: 'Disminuir en',
    decBy: 'Aumentado por',
    setToCPA: 'Establecer en CPA medio',
    setToCPT: 'Establecer en CPT promedio',
    value: '$ Valor',
    change: '% Cambiar',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Elegir cuenta',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    clearAll: 'Limpiar todo',
    accounts: 'Cuentas',
    accountSelected: 'Cuenta (s) seleccionada (s)',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'Elija la aplicación',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    apps: 'Aplicaciones',
    appSelected: 'Aplicaciones seleccionadas',
    clearAll: 'Limpiar todo',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: 'Please keep only one Ad-Group selected to continueAplicaciones seleccionadas.',
    close: 'Cerrar',
    chooseKeywords: 'Elija palabras clave',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    campaigns: 'Campañas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    adGroups: 'Grupos de anuncios',
    keywords: 'Palabras clave',
    keywordSelected: 'palabras clave seleccionadas',
    clearAll: 'Limpiar todo',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Agregar conjunto creativo',
    setup: 'Configuración',
    para:
      'Elija un nombre que describa el tema de su conjunto creativo. Seleccione el idioma de las capturas de pantalla y las vistas previas que le gustaría incluir en la página de producto de su App Store.',
    createSetName: 'Nombre del conjunto creativo ',
    localLang: 'Idioma de localización',
    assetSelection: 'Selección de activos',
    assetSelectionPararaph1:
      'Los conjuntos creativos consisten en capturas de pantalla y vistas previas de aplicaciones que aparecen en la página del producto de la App Store.',
    assetSelectionPararaph2: 'Para cada tamaño de pantalla de iPhone,',
    assetSelectionPararaph3:
      'seleccione al menos un paisaje o tres recursos de retrato.',
    assetSelectionPararaph4: 'Para cada tamaño de pantalla de iPad,',
    assetSelectionPararaph5:
      'seleccione al menos un paisaje o dos recursos de retrato.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Por favor seleccione al menos',
    screenshotsPara2: 'un paisaje',
    screenshotsPara3: 'o',
    screenshotsPara4: 'dos retratos',
    screenshotsPara5: 'capturas de pantalla!',
    screenshotsPara6: 'Por favor seleccione al menos',
    screenshotsPara7: 'un paisaje',
    screenshotsPara8: 'o',
    screenshotsPara9: 'tres retratos',
    screenshotsPara10: 'capturas de pantalla!',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Eliminar campaña',
    para: '¿Estás seguro de que quieres eliminar la campaña?',
    cancel: 'Cancelar',
    delete: 'Borrar',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Asistente para editar campañas',
    campName: 'Nombre de campaña',
    app: 'Aplicación',
    countriesOrRegionsPara: 'Elija uno o más países o regiones',
    budget: 'Presupuesto',
    dailyCap: 'Límite diario',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Elegir campaña',
    noSelectedCamp: 'Ninguna campaña seleccionada',
    campaigns: 'Campañas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    selectedCampaigns: 'Campañas seleccionadas',
    clearAll: 'limpiar todo',
    cancel: 'Cancelar',
    apply: 'Solicitar',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Editar tarjeta de crédito',
    cardNumber: 'Número de tarjeta',
    expiryDate: 'Fecha de caducidad',
    cvv: 'CVV',
    saveCard: 'Tarjeta Salvar',
    updateExpiryPara: '* Solo puede actualizar la fecha de vencimiento',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Editar conjunto de creatividades',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Configuración',
    editCreativePara1:
      'Elija un nombre que describa el tema de su conjunto creativo. Seleccione el idioma de las capturas de pantalla y las vistas previas que le gustaría incluir en la página de producto de su App Store.',
    creativeSetName: 'Nombre del conjunto creativo',
    localLang: 'Idioma de localización',
    assetSelection: 'Selección de activos',
    assetSelectionPararaph1:
      'Los conjuntos creativos consisten en capturas de pantalla y vistas previas de aplicaciones que aparecen en la página del producto de la App Store.',
    assetSelectionPararaph2: 'Para cada tamaño de pantalla de iPhone,',
    assetSelectionPararaph3:
      'seleccione al menos un paisaje o tres recursos de retrato.',
    assetSelectionPararaph4: 'Para cada tamaño de pantalla de iPad,',
    assetSelectionPararaph5:
      'seleccione al menos un paisaje o dos recursos de retrato.',
    viewDevice: 'Ver tamaños de pantalla del dispositivo',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Mostrar',
    default: 'Defecto',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Eliminar integración',
    cardText: '¿Está seguro de que desea eliminar la integración?',
    cancel: 'Cancelar',
    delete: 'Borrar',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Agregar palabras clave',
    keywordSuggestionsFor: 'Sugerencias de palabras clave para',
    on: 'en',
    addedKeywords: 'Palabras clave agregadas',
    total: 'Total',
    add: 'Agregar',
    cancel: 'Cancelar',
    apply: 'Solicitar',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Agregar palabras clave',
    selectTarget: 'Seleccione un objetivo',
    h4Camp: 'Campañas:',
    selected: 'Seleccionado',
    change: 'Cambiar',
    h4Adgroups: 'Grupos de anuncios:',
    maxCPTBid: 'Oferta de CPT máx .:',
    keywordSuggestionsFor: 'Sugerencias de palabras clave para',
    on: 'en',
    recommendedKeywords: 'Palabras clave recomendadas',
    addedKeywords: 'Palabras clave agregadas',
    add: 'Agregar',
    total: 'Total',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    ok: 'OK',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Agregar palabras clave',
    selectTarget: 'Seleccione un objetivo',
    h4Camp: 'Campañas:',
    selected: 'Seleccionado',
    change: 'Cambiar',
    h4Adgroups: 'Grupos de anuncios:',
    maxCPTBid: 'Oferta de CPT máx .:',
    keywordSuggestionsFor: 'Sugerencias de palabras clave para',
    on: 'en',
    recommendedKeywords: 'Palabras clave recomendadas',
    addedKeywords: 'Palabras clave agregadas',
    add: 'Agregar',
    total: 'Total',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    ok: 'OK',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Agregar palabras clave negativas',
    add: 'Agregar',
    total: 'Total',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Importe de la oferta',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Mantenga solo un grupo de anuncios seleccionado para continuar.',
    close: 'Cerrar',
    chooseAdGroup: 'Elija el grupo de anuncios',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    campaigns : 'Campañas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    adGroups : 'Grupos de anuncios',
    adGroupSelected : 'Grupos de anuncios seleccionados',
    clearAll : 'Limpiar todo'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Agregar palabras clave negativas',
    selectTarget : '1. Seleccione un objetivo',
    addNegKeyword : 'Agregar palabra clave negativa',
    paragraph1 : 'Se pueden agregar palabras clave negativas a campañas o grupos de anuncios. Elija uno de ellos del cuadro de abajo.',
    paragraph2 : '¿Qué campaña desea cargar palabras clave?',
    selectCampaign : 'Seleccionar campaña',
    paragraph3 : '¿Qué grupo de anuncios desea cargar palabras clave?',
    selectAdgroup : 'Seleccionar grupo de anuncios',
    cancel: 'Cancelar',
    easywebinar : 'EasyWebinar', 
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Campañas:',
    selected : 'Seleccionado',
    change: 'Cambiar',
    paragraph4 : ' Las palabras clave negativas del grupo de anuncios se aplican solo a este grupo de anuncios. Si desea que las palabras clave negativas se apliquen a todos los grupos de anuncios, configúrelas en el nivel de la campaña.',
    paragraph5 : ' Considere dar tiempo a las campañas para que se publiquen antes de analizar el rendimiento de los anuncios para saber qué palabras clave pueden no funcionar para usted. Agregar demasiadas palabras clave negativas puede limitar la exposición de su anuncio.',
    addedKeywords : 'Palabras clave agregadas', 
    add: 'Agregar',
    total: 'Total',
    save: 'Salvar', 
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Agregar canal de Slack',
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Cancelar',
ok : 'Ok',
  },

  //HelloWorld
  helloWorld : {
welcome : 'Bienvenido a vuetify',
para1 : 'Para obtener ayuda y colaboración con otros desarrolladores de Vuetify,',
para2 : 'por favor únase a nuestro en línea',
discord : 'Comunidad Discord',
h2 : "¿Que sigue?",
impLinks : 'Links importantes',
ecosystem : 'Ecosistema',
  },

  //InfoAlert
  infoAlert : {
ok : 'OK',
  },
}
